import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from 'app/entities/cittadino/cittadino.reducer';
import { ICittadino } from 'app/shared/model/cittadino.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ICittadinoDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class CittadinoDetail extends React.Component<ICittadinoDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { cittadinoEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            Cittadino [<b>{cittadinoEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="codiceFiscale">Codice Fiscale</span>
            </dt>
            <dd>{cittadinoEntity.codiceFiscale}</dd>
            <dt>
              <span id="telefono">Telefono</span>
            </dt>
            <dd>{cittadinoEntity.telefono}</dd>
            <dt>
              <span id="cellulare">Cellulare</span>
            </dt>
            <dd>{cittadinoEntity.cellulare}</dd>
            <dt>
              <span id="indirizzo">Indirizzo</span>
            </dt>
            <dd>{cittadinoEntity.indirizzo}</dd>
            <dt>
              <span id="codicePostale">Codice Postale</span>
            </dt>
            <dd>{cittadinoEntity.codicePostale}</dd>
            <dt>
              <span id="componentiNucleoFamiliare">Componenti Nucleo Familiare</span>
            </dt>
            <dd>{cittadinoEntity.componentiNucleoFamiliare}</dd>
            <dt>
              <span id="disabiliNucleoFamiliare">Disabili Nucleo Familiare</span>
            </dt>
            <dd>{cittadinoEntity.disabiliNucleoFamiliare}</dd>
            <dt>
              <span id="anzianiNucleoFamiliare">Anziani Nucleo Familiare</span>
            </dt>
            <dd>{cittadinoEntity.anzianiNucleoFamiliare}</dd>
            <dt>
              <span id="minoriNucleoFamiliare">Minori Nucleo Familiare</span>
            </dt>
            <dd>{cittadinoEntity.minoriNucleoFamiliare}</dd>
            <dt>
              <span id="quarantenaNucleoFamiliare">Quarantena Nucleo Familiare</span>
            </dt>
            <dd>{cittadinoEntity.quarantenaNucleoFamiliare}</dd>
            <dt>
              <span id="saldoDisponibile">Saldo Disponibile</span>
            </dt>
            <dd>{cittadinoEntity.saldoDisponibile}</dd>
            <dt>
              <span id="pin">Pin</span>
            </dt>
            <dd>{cittadinoEntity.pin}</dd>
            <dt>
              <span id="tessera">DocumentoIdentità</span>
            </dt>
            <dd>
              {cittadinoEntity.tessera ? (
                <div>
                  <a onClick={openFile(cittadinoEntity.tesseraContentType, cittadinoEntity.tessera)}>Open&nbsp;</a>
                  <span>
                    {cittadinoEntity.tesseraContentType}, {byteSize(cittadinoEntity.tessera)}
                  </span>
                </div>
              ) : null}
            </dd>
            <dt>
              <span id="foto">Domanda</span>
            </dt>
            <dd>
              {cittadinoEntity.richiesta ? (
                <div>
                  <a onClick={openFile(cittadinoEntity.richiestaContentType, cittadinoEntity.richiesta)}>Open&nbsp;</a>
                  <span>
                    {cittadinoEntity.richiestaContentType}, {byteSize(cittadinoEntity.richiesta)}
                  </span>
                </div>
              ) : null}
            </dd>
            <dt>
              <span id="foto">ISE</span>
            </dt>
            <dd>
              {cittadinoEntity.ise ? (
                <div>
                  <a onClick={openFile(cittadinoEntity.iseContentType, cittadinoEntity.ise)}>Open&nbsp;</a>
                  <span>
                    {cittadinoEntity.iseContentType}, {byteSize(cittadinoEntity.ise)}
                  </span>
                </div>
              ) : null}
            </dd>
            <dt>User</dt>
            <dd>{cittadinoEntity.user ? cittadinoEntity.user.id : ''}</dd>
            <dt>Comune Riferimento</dt>
            <dd>{cittadinoEntity.comuneRiferimento ? cittadinoEntity.comuneRiferimento.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/cittadino" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/cittadino/${cittadinoEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ cittadino }: IRootState) => ({
  cittadinoEntity: cittadino.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CittadinoDetail);
