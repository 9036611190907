import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import { Button,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  Alert,
  Container,
  Badge,
  Spinner,
  Progress
} from 'reactstrap';
import {  AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { setFileData, openFile } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getPublicEntityForCittadino as getAmministrazioneComunale } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';

import {
  createPublicEntityForCittadino as createEntity,
  setBlob,
  reset,
  setEntityProperty, setEmailToNullIdEmpty
} from 'app/entities/cittadino/cittadino.reducer';
import {checkCittadinoCodicefiscale, checkUserEmail } from "app/shared/util/entity-utils";
import { download } from 'app/entities/document.reducer';
import fileDownload from "js-file-download";
import {humanFileSize} from "app/shared/util/misc-utils";

export interface ICittadinoUpdateTemplate1Props extends StateProps, DispatchProps, RouteComponentProps<{ comuneStringId: string }> {}

export interface ICittadinoUpdateTemplate1State {
  tesseraLoading: boolean;
  tesseraLoadingPerc: number;
  richiestaLoading: boolean;
  richiestaLoadingPerc: number;
  iseLoading: boolean;
  iseLoadingPerc: number;
  tesseraUploadStatus: string;
  richiestaUploadStatus: string;
  iseUploadStatus: string;
}

export class CittadinoUpdateTemplate1 extends React.Component<ICittadinoUpdateTemplate1Props, ICittadinoUpdateTemplate1State> {

  tessera = null;
  richiesta = null;
  ise = null;
  stepCaricamento = 0;

  defaultCittadino = {
    componentiNucleoFamiliare: 1,
    disabiliNucleoFamiliare: 0,
    anzianiNucleoFamiliare: 0,
    minoriNucleoFamiliare: 0,
    quarantenaNucleoFamiliare: 0,
    codicePostale: ''
  }

  constructor(props) {
    super(props);

    this.state = {
      tesseraLoading: false,
      richiestaLoading: false,
      iseLoading: false,
      tesseraLoadingPerc: 0,
      richiestaLoadingPerc: 0,
      iseLoadingPerc: 0,
      tesseraUploadStatus: null,
      richiestaUploadStatus: null,
      iseUploadStatus: null
    }

    this.saveEntity = this.saveEntity.bind(this);
    this.caricaTessera = this.caricaTessera.bind(this);
    this.caricaRichiesta = this.caricaRichiesta.bind(this);
    this.caricaIse = this.caricaIse.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.amministrazioneComunaleLoading && !nextProps.amministrazioneComunaleLoading) {
      if (nextProps.amministrazioneComunale && nextProps.amministrazioneComunale.codicePostale) {
        this.defaultCittadino.codicePostale = nextProps.amministrazioneComunale.codicePostale
      }
    }
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      //this.handleClose();
    }
  }

  componentDidMount() {
  }

  onBlobChange = (isAnImage, name) => event => {
    const filename = event.target.files[0].name;
    const size = event.target.files[0].size;
    const fileDataCallBackfunction = (contentType, data) => {
      this.props.setBlob(name, data, contentType, filename, size )
    };

    setFileData(event, fileDataCallBackfunction, isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined, undefined, undefined);
  };

  setEntityProperty = (propertyName, value)  => event => {
    this.props.setEntityProperty(propertyName, value);
  }

  saveEntity(event, errors, values) {
    var _that = this;
    const { cittadinoEntity } = this.props;
    if (errors.length === 0) {


      const entity = {
        ...cittadinoEntity,
        ...values
      };

      entity.disabiliNucleoFamiliare = 0;
      entity.anzianiNucleoFamiliare = 0;
      entity.minoriNucleoFamiliare = 0;
      entity.quarantenaNucleoFamiliare = 0;

      if (entity.tessera === '' || !entity.tessera) {
        delete entity.tessera;
      } else {
        if (entity.tesseraSize < 4000000) {
          this.tessera = {
            file: entity.tessera,
            contentType: entity.tesseraContentType
          }
          delete entity.tessera;
        } else {
          return;
        }

        this.stepCaricamento++;
      }

      if (entity.richiesta === '' || !entity.richiesta) {
        delete entity.richiesta;
      } else {
        if (entity.richiestaSize < 4000000) {
          this.richiesta = {
            file: entity.richiesta,
            contentType: entity.richiestaContentType
          }
          delete entity.richiesta
        } else {
          return;
        }
        this.stepCaricamento++;
      }

      if (entity.ise === '' || !entity.ise) {
        delete entity.ise;
      } else {
        if (entity.iseSize < 4000000) {
          this.ise = {
            file: entity.ise,
            contentType: entity.iseContentType
          }
          delete entity.ise;
        } else {
          return;
        }
        this.stepCaricamento++;
      }

      this.props.createEntity(setEmailToNullIdEmpty(entity), this.props.match.params.comuneStringId).then(async (response) => {
        if (_that.tessera) {
          await _that.caricaTessera();
        }
        if (_that.richiesta) {
          await _that.caricaRichiesta();
        }
        if (_that.ise) {
          await _that.caricaIse();
        }
      });
    } else {
      this.forceUpdate();
    }
  };

  async caricaTessera() {
    var _that = this;

    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        _that.setState({
          tesseraLoadingPerc: percentCompleted
        })
      }
    }

    _that.setState({
      tesseraUploadStatus: null,
      tesseraLoading: true
    });
    try {
      await axios.post('api/upload/cittadino/' + this.props.cittadinoEntity.id + '/tessera', this.tessera, config);
      _that.tessera = null;
      _that.stepCaricamento--;
      _that.setState({
        tesseraUploadStatus: 'ok',
        tesseraLoading: false
      });
    } catch(e){
      _that.setState({
        tesseraUploadStatus: 'ko',
        tesseraLoading: false
      });
    }
  }

  async caricaRichiesta() {
    var _that = this;

    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        _that.setState({
          richiestaLoadingPerc: percentCompleted
        })
      }
    }


    _that.setState({
      richiestaUploadStatus: null,
      richiestaLoading: true
    });
    try {
      await axios.post('api/upload/cittadino/' + this.props.cittadinoEntity.id + '/richiesta', this.richiesta, config);
      _that.richiesta = null;
      _that.stepCaricamento--;
      _that.setState({
        richiestaUploadStatus: 'ok',
        richiestaLoading: false
      });
    } catch(e) {
      _that.setState({
        richiestaUploadStatus: 'ko',
        richiestaLoading: false
      });
    }
  }

  async caricaIse() {
    var _that = this;

    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        _that.setState({
          iseLoadingPerc: percentCompleted
        })
      }
    }


    _that.setState({
      iseUploadStatus: null,
      iseLoading: true
    });
    try {
      await axios.post('api/upload/cittadino/' + this.props.cittadinoEntity.id + '/ise', this.ise, config);
      _that.ise = null;
      _that.stepCaricamento--;
      _that.setState({
        iseUploadStatus: 'ok',
        iseLoading: false
      });
    } catch(e) {
      _that.setState({
        iseUploadStatus: 'ko',
        iseLoading: false
      });
    }
  }

  handleClose = () => {
    this.props.history.push('/amministrazione/cittadino');
  };

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  render() {
    const { cittadinoEntity,
      updating,
      errorMessage,
      updateSuccess,
      amministrazioneComunale,
      amministrazioneComunaleLoading,
      amministrazioneComunaleErrorMessage
    } = this.props;
    const { tesseraLoading,
      richiestaLoading,
      iseLoading,
      tesseraLoadingPerc,
      richiestaLoadingPerc,
      iseLoadingPerc,
      tesseraUploadStatus,
      richiestaUploadStatus,
      iseUploadStatus } = this.state;
    const { tessera, tesseraContentType, tesseraUrl, tesseraFileName, tesseraSize } = cittadinoEntity;
    const { richiesta, richiestaContentType, richiestaUrl, richiestaFileName, richiestaSize } = cittadinoEntity;
    const { ise, iseContentType, iseUrl, iseFileName, iseSize } = cittadinoEntity;

    if ((amministrazioneComunaleLoading || updating)) {
      return (
        <>
        <div>test</div>
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <Spinner animation="border" />
              </Col>
            </Row>
          </Container>
        </>
      );
    } else if (amministrazioneComunaleErrorMessage) {
      return (
        <>
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <Alert color="danger">
                  <h3>Ci dispiace, si è verificato un errore!</h3>
                  <hr />
                  {amministrazioneComunaleErrorMessage.response.status === 404 ?
                    <h5>Controlla che il link inserito sia corretto</h5>
                    :
                    <h5>{amministrazioneComunaleErrorMessage.message}</h5>
                  }
                </Alert>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else if (errorMessage) {
      return (
        <>
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <Alert color="danger">
                  <h3>Ci dispiace, si è verificato un errore durante la registrazione!</h3>
                  <hr />
                  {errorMessage.response.status === 404 ?
                    <h5>Controlla che il link inserito sia corretto</h5>
                    :
                    <h5>{errorMessage.message}</h5>
                  }
                </Alert>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else if (updateSuccess) {
      if ( this.tessera || this.richiesta || this.ise || tesseraUploadStatus || richiestaUploadStatus || iseUploadStatus) {
        return (
          <Container>
            <Row>
              <Col md="12" className="text-center">
                {(this.tessera || tesseraUploadStatus) &&
                  <Alert color={tesseraUploadStatus == null ? 'warning' : tesseraUploadStatus === 'ok' ? 'success' : 'danger'}>
                    <>
                      {!tesseraUploadStatus &&
                        <h3>{tesseraLoading && <FontAwesomeIcon icon="spinner" spin />} Caricamento tessera sanitaria in corso...</h3>
                      }
                      {tesseraUploadStatus &&
                        <>
                          {tesseraUploadStatus === 'ok' &&
                            <h3><FontAwesomeIcon icon={'check-circle'}  /> Caricamento tessera sanitaria completato</h3>
                          }
                          {tesseraUploadStatus === 'ko' &&
                            <>
                              <h3><FontAwesomeIcon color="danger" icon="times-circle"  /> Caricamento tessera sanitaria fallito</h3>
                              <h6>Siamo spiacenti. Si è verificato un problema durante il caricamento del file.</h6>
                              <Button color="danger" onClick={this.caricaTessera}>
                                <FontAwesomeIcon icon="upload"/> Riprova
                              </Button>
                            </>
                          }
                        </>
                      }
                    </>
                    <Progress striped animated={tesseraLoadingPerc !== 100 ? true : false} value={tesseraLoadingPerc} />
                  </Alert>
                }
                {(this.richiesta || richiestaUploadStatus) &&
                  <Alert color={richiestaUploadStatus === null ? 'warning' : richiestaUploadStatus === 'ok' ? 'success' : 'danger'}>
                    <>
                      {!richiestaUploadStatus &&
                        <h3>{richiestaLoading && <FontAwesomeIcon icon="spinner" spin />} Caricamento domanda in corso...</h3>
                      }
                      {richiestaUploadStatus &&
                        <>
                          {richiestaUploadStatus === 'ok' &&
                            <h3><FontAwesomeIcon icon={'check-circle'}  /> Caricamento domanda completato</h3>
                          }
                          {richiestaUploadStatus === 'ko' &&
                            <>
                              <h3><FontAwesomeIcon color="danger" icon="times-circle"  /> Caricamento domanda fallito</h3>
                              <h6>Siamo spiacenti. Si è verificato un problema durante il caricamento del file.</h6>
                              <Button color="danger" onClick={this.caricaRichiesta}>
                                <FontAwesomeIcon icon="upload"/> Riprova
                              </Button>
                            </>
                          }
                        </>
                      }
                    </>
                    <Progress striped animated={richiestaLoadingPerc !== 100 ? true : false} value={richiestaLoadingPerc} />
                  </Alert>
                }
                {(this.ise || iseUploadStatus) &&
                  <Alert color={iseUploadStatus === null ? 'warning' : iseUploadStatus === 'ok' ? 'success' : 'danger'}>
                    <>
                      {!iseUploadStatus &&
                        <h3>{iseLoading && <FontAwesomeIcon icon="spinner" spin />} Caricamento ISE in corso...</h3>
                      }
                      {iseUploadStatus &&
                        <>
                          {iseUploadStatus === 'ok' &&
                            <h3><FontAwesomeIcon icon={'check-circle'}  /> Caricamento ISE completato</h3>
                          }
                          {iseUploadStatus === 'ko' &&
                            <>
                              <h3><FontAwesomeIcon color="danger" icon="times-circle"  /> Caricamento ISE fallito</h3>
                              <h6>Siamo spiacenti. Si è verificato un problema durante il caricamento del file.</h6>
                              <Button color="danger" onClick={this.caricaIse}>
                                <FontAwesomeIcon icon="upload"/> Riprova
                              </Button>
                            </>
                          }
                        </>
                      }
                    </>
                    <Progress striped animated={iseLoadingPerc !== 100 ? true : false} value={iseLoadingPerc} />
                  </Alert>
                }
                {this.stepCaricamento === 0 &&
                  <Alert color="success">
                    <h3>Registrazione avvenuta con successo!</h3>
                    <hr />
                    <h6>La presentazione della sua domanda è stata correttamente presa in carico dai nostri sistemi.</h6>
                    <br/>
                    {!cittadinoEntity.email ?
                      <span>
                        Riceverà presto una chiamata al numero <b>{cittadinoEntity.cellulare}</b> che le comunicherà l'esito della valutazione della sua richiesta.
                      </span>
                      :
                      <span>
                        Le è stata appena inviata un email all'indirizzo <b>{cittadinoEntity.email}</b> con le informazioni relative alla sua richiesta.<br />
                        Riceverà presto un'altra email allo stesso indirizzo che le comunicherà l'esito della valutazione della richiesta.
                      </span>
                    }
                    <br />
                    <em>Cordiali Saluti.</em>
                  </Alert>
                }
              </Col>
            </Row>
          </Container>
        )
      }
      return (
        <>
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <Alert color="success">
                  <h3>Registrazione avvenuta con successo!</h3>
                  <hr />
                  <h6>La presentazione della sua domanda è stata correttamente presa in carico dai nostri sistemi.</h6>
                  <br/>
                  {!cittadinoEntity.email ?
                    <span>
                      Riceverà presto una chiamata al numero <b>{cittadinoEntity.cellulare}</b> che le comunicherà l'esito della valutazione della sua richiesta.
                    </span>
                    :
                    <span>
                      Le è stata appena inviata un email all'indirizzo <b>{cittadinoEntity.email}</b> con le informazioni relative alla sua richiesta.<br />
                      Riceverà presto un'altra email allo stesso indirizzo che le comunicherà l'esito della valutazione della richiesta.
                    </span>
                  }
                  <br />
                  <em>Cordiali Saluti.</em>
                </Alert>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return (
        <>
          <Row className="justify-content-center">
            <Col md="12">
              <h2 id="buoniscontoApp.cittadino.home.createOrEditLabel">Modulo richiesta buoni alimentari per
                il comune di {amministrazioneComunale.comune}</h2>
            </Col>
          </Row>
          <hr/>
          <Row className="justify-content-center">
            <Col md="12">
              <AvForm model={this.defaultCittadino} onSubmit={this.saveEntity} ref={c => (this.form = c)}>
                <Row>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni cittadino
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" for="cittadino-firstName">Nome</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="firstName"
                                id="cittadino-firstName"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Nome obbligatorio'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Lunghezza massima 50 caratteri'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" for="cittadino-lastName">Cognome</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="lastName"
                                id="cittadino-lastName"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Cognome obbligatorio'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Lunghezza massima 50 caratteri'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" id="codiceFiscaleLabel" for="cittadino-codiceFiscale">
                              Codice Fiscale
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="cittadino-codiceFiscale"
                                type="text"
                                name="codiceFiscale"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  pattern: {
                                    value: '^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9][A-Za-z0-9][0-9][A-Za-z]$',
                                    errorMessage: 'Non è un codice fiscale valido'
                                  },
                                  async: checkCittadinoCodicefiscale(cittadinoEntity.codiceFiscale)
                                }}
                                helpMessage="Il codice fiscale verrà utilizzato dal cittadino come username per accedere all'app"
                                style={{textTransform: 'uppercase'}}
                              />
                            </AvGroup>
                          </Col>
                        </Row>



                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="codiceFiscaleGenitore1Label" for="cittadino-codiceFiscaleGenitore1">
                              Codice Fiscale Genitore 1
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="cittadino-codiceFiscaleGenitore1"
                                type="text"
                                name="codiceFiscaleGenitore1"
                                validate={{
                                  pattern: {
                                    value: '^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9][A-Za-z0-9][0-9][A-Za-z]$',   
                                    errorMessage: 'Non è un codice fiscale valido'
                                  },
                                  async: checkCittadinoCodicefiscale(cittadinoEntity.codiceFiscaleGenitore1)
                                }}
                                elpMessage="Inserire il codice fiscale del genitore 1"
                                style={{textTransform: 'uppercase'}}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="codiceFiscaleGenitore2Label" for="cittadino-codiceFiscaleGenitore2">
                              Codice Fiscale Genitore 2
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="cittadino-codiceFiscaleGenitore2"
                                type="text"
                                name="codiceFiscaleGenitore2"
                                validate={{
                                  pattern: {
                                    value: '^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9][A-Za-z0-9][0-9][A-Za-z]$',   
                                    errorMessage: 'Non è un codice fiscale valido'
                                  },
                                  async: checkCittadinoCodicefiscale(cittadinoEntity.codiceFiscaleGenitore1)
                                }}
                                elpMessage="Inserire il codice fiscale del genitore 2"
                                style={{textTransform: 'uppercase'}}
                              />
                            </AvGroup>
                          </Col>
                        </Row>



                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" id="cellulareLabel" for="cittadino-cellulare">
                              Cellulare
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="cittadino-cellulare" type="text" name="cellulare"
                                       validate={{
                                         required: {value: true, errorMessage: 'Questo campo è obbligatorio.'}
                                       }}/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="cittadino-email">Email</Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              name="email"
                              id="cittadino-email"
                              type="email"
                              validate={{
                                required: {
                                  value: false,
                                  errorMessage: 'Email obbligatoria'
                                },
                                email: {
                                  errorMessage: 'Email non valida'
                                },
                                minLength: {
                                  value: 5,
                                  errorMessage: 'L\'email deve essere lunga almeno 5 caratteri'
                                },
                                maxLength: {
                                  value: 254,
                                  errorMessage: 'L\'email non deve essere più lunga di 5o caratteri'
                                },
                                async: checkUserEmail(cittadinoEntity.email)
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="telefonoLabel" for="cittadino-telefono">
                              Telefono
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="cittadino-telefono"
                                type="text"
                                name="telefono"
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="indirizzoLabel" for="cittadino-indirizzo">
                              Indirizzo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="cittadino-indirizzo" type="text" name="indirizzo"/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="codicePostaleLabel" for="cittadino-codicePostale">
                              Codice Postale
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="cittadino-codicePostale" type="text" name="codicePostale"/>
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="tesseraLabel" for="tessera" className="required">
                              <b>Documento d'Identità</b>
                            </Label>
                          </Col>
                          <Col md="9">
                            {tesseraUrl ?
                              <div>
                                <span>DocumentoIdentità.{tesseraUrl.split('.')[tesseraUrl.split('.').length - 1]}</span>
                                <a className="ml-1"
                                   onClick={this.onDownloadClick.bind(this,`/api/${tesseraUrl}`, 'tessera', tesseraContentType)}>(Download)</a>
                                <a className="ml-1"
                                   onClick={this.setEntityProperty('tesseraUrl', '')}>(Remove)</a>
                              </div>
                              :
                              <div>
                                {tessera ? (
                                    <div>
                                      <Row>
                                        <Col md="12">
                                          <span>
                                            {tesseraFileName}
                                          </span>
                                          <Badge color="danger" onClick={this.clearBlob('tessera')} className="ml-2" style={{cursor: "pointer"}}>
                                            <FontAwesomeIcon icon="times-circle"/>
                                          </Badge>
                                          <Badge className="ml-2">{humanFileSize(tesseraSize, true)}</Badge>
                                        </Col>
                                        {tesseraSize !== null && tesseraSize !== undefined && tesseraSize > 4000000 &&
                                          <div style={{color: 'red', fontSize: '14px'}}><b>Il file non può essere superiore di 4MB</b></div>
                                        }
                                      </Row>
                                    </div>
                                  )
                                  :
                                  <>
                                    <input id="file_tessera" type="file"
                                           onChange={this.onBlobChange(false, 'tessera')}/>
                                    {this.form && this.form.state && this.form.state.invalidInputs && this.form.state.invalidInputs.tessera &&
                                      <div style={{color: 'red', fontSize: '14px'}}><b>CAMPO OBBLIGATORIO</b></div>
                                    }
                                    <AvInput type="hidden" name="tessera" value={tessera} validate={{
                                      required: {value: true, errorMessage: 'Questo campo è obbligatorio.'}
                                    }}/>
                                  </>
                                }
                              </div>
                            }
                          </Col>
                        </Row>
                        {amministrazioneComunale.documentazioneIse &&
                        <>
                          <hr/>
                          <Row className="pt-2">
                            <Col md="3" className="text-right">
                              <Label id="iseLabel" for="ise" className="required">
                                <b>Carica ISE</b>
                              </Label>
                            </Col>
                            <Col md="9">
                              {iseUrl ?
                                <div>
                                  <span>ISE.{iseUrl.split('.')[iseUrl.split('.').length - 1]}</span>
                                  <a className="ml-1"
                                     onClick={this.onDownloadClick.bind(this,`/api/${iseUrl}`, 'ise', iseContentType)}>(Download)</a>
                                  <a className="ml-1"
                                     onClick={this.setEntityProperty('iseUrl', '')}>(Remove)</a>
                                </div>
                                :
                                <div>
                                  {ise ? (
                                      <div>
                                        <Row>
                                          <Col md="12">
                                              <span>
                                                {iseFileName}
                                              </span>
                                            <Badge color="danger" onClick={this.clearBlob('ise')} className="ml-2" style={{cursor: "pointer"}}>
                                              <FontAwesomeIcon icon="times-circle"/>
                                            </Badge>
                                            <Badge className="ml-2">{humanFileSize(iseSize, true)}</Badge>
                                          </Col>
                                          {iseSize !== null && iseSize !== undefined && iseSize > 4000000 &&
                                            <div style={{color: 'red', fontSize: '14px'}}><b>Il file non può essere superiore di 4MB</b></div>
                                          }
                                        </Row>
                                      </div>
                                    )
                                    :
                                    <>
                                      <input id="file_ise" type="file"
                                             onChange={this.onBlobChange(false, 'ise')}/>
                                      {this.form && this.form.state && this.form.state.invalidInputs && this.form.state.invalidInputs.ise &&
                                      <div style={{color: 'red', fontSize: '14px'}}><b>CAMPO OBBLIGATORIO</b></div>
                                      }
                                      <AvInput type="hidden" name="ise" value={ise} validate={{
                                        required: {value: true, errorMessage: 'Questo campo è obbligatorio.'}
                                      }}/>
                                    </>
                                  }
                                </div>
                              }
                            </Col>
                          </Row>
                        </>
                        }
                        {amministrazioneComunale.templateModuloRichiestaUrl &&
                          <>
                            <hr/>
                            <Row className="pt-2">
                              <Col md="3" className="text-right">
                                <Label id="domandaLabel" for="domanda" className="required">
                                  <b>Carica Domanda compilata</b>
                                </Label>
                              </Col>
                              <Col md="9">
                                {richiestaUrl ?
                                  <div>
                                    <span>Domanda.{richiestaUrl.split('.')[richiestaUrl.split('.').length - 1]}</span>
                                    <a className="ml-1"
                                       onClick={this.onDownloadClick.bind(this,`/api/${richiestaUrl}`, 'richiesta', richiestaContentType)}>(Download)</a>
                                    <a className="ml-1"
                                       onClick={this.setEntityProperty('richiestaUrl', '')}>(Remove)</a>
                                  </div>
                                  :
                                  <div>
                                    {richiesta ? (
                                        <div>
                                          <Row>
                                            <Col md="12">
                                              <span>
                                                {richiestaFileName}
                                              </span>
                                              <Badge color="danger" onClick={this.clearBlob('richiesta')} className="ml-2" style={{cursor: "pointer"}}>
                                                <FontAwesomeIcon icon="times-circle"/>
                                              </Badge>
                                              <Badge className="ml-2">{humanFileSize(richiestaSize, true)}</Badge>
                                            </Col>
                                            {richiestaSize !== null && richiestaSize !== undefined && richiestaSize > 4000000 &&
                                              <div style={{color: 'red', fontSize: '14px'}}><b>Il file non può essere superiore di 4MB</b></div>
                                            }
                                          </Row>
                                        </div>
                                      )
                                      :
                                      <>
                                        <input id="file_richiesta" type="file"
                                               onChange={this.onBlobChange(false, 'richiesta')}/>
                                        {this.form && this.form.state && this.form.state.invalidInputs && this.form.state.invalidInputs.richiesta &&
                                        <div style={{color: 'red', fontSize: '14px'}}><b>CAMPO OBBLIGATORIO</b></div>
                                        }
                                        <AvInput type="hidden" name="richiesta" value={richiesta} validate={{
                                          required: {value: true, errorMessage: 'Questo campo è obbligatorio.'}
                                        }}/>
                                      </>
                                    }
                                  </div>
                                }
                                <Alert color="warning" className="mt-2">
                                  <b>Compilare in tutte le sue parti il modulo della domanda scaricabile cliccando
                                    <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${amministrazioneComunale.templateModuloRichiestaUrl}`, 'richiesta', richiestaContentType)}>QUI.</a></b>
                                </Alert>
                              </Col>
                            </Row>
                          </>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni Nucleo Familiare del cittadino
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="7" className="text-right">
                            <Label id="componentiNucleoFamiliareLabel"
                                   for="cittadino-componentiNucleoFamiliare">
                              <div>N° componenti del nucleo familiare</div>
                              <div style={{marginTop: '-7px'}}><span
                                style={{fontSize: '10px'}}>(COMPRESO IL RICHIEDENTE)</span></div>
                            </Label>
                          </Col>
                          <Col md="5">
                            <AvField
                              id="cittadino-componentiNucleoFamiliare"
                              type="number"
                              className="form-control"
                              name="componentiNucleoFamiliare"
                              validate={{
                                required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                number: {value: true, errorMessage: 'Questo campo deve essere numerico.'},
                                min: {value: 1, errorMessage: 'Questo campo non può essere minore di 1.'}
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni d'accesso
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" for="password">Password</Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              name="password"
                              type="password"
                              placeholder="Inserisci password"
                              autoComplete="new-password"
                              validate={{
                                required: {value: true, errorMessage: 'Password obbligatoria'},
                                minLength: {
                                  value: 4,
                                  errorMessage: 'La password deve essere lunga almeno 4 caratteri'
                                },
                                maxLength: {
                                  value: 50,
                                  errorMessage: 'La password non deve essere più lunga di 50 caratteri'
                                }
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" for="confirmPassword">Conferma password</Label>
                          </Col>
                          <Col>
                            <AvField
                              name="confirmPassword"
                              placeholder="Inserisci conferma password"
                              type="password"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: 'Conferma password è obbligatorio'
                                },
                                minLength: {
                                  value: 4,
                                  errorMessage: 'Inserire almeno 4 caratteri'
                                },
                                maxLength: {
                                  value: 50,
                                  errorMessage: 'Lunghezza massima 50 caratteri'
                                },
                                match: {
                                  value: 'password',
                                  errorMessage: 'Conferma password non corretta!'
                                }
                              }}
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" id="pinLabel" for="cittadino-pin">
                              Pin
                            </Label>
                          </Col>
                          <Col md="5">
                            <AvField
                              id="cittadino-pin"
                              type="string"
                              className="form-control"
                              name="pin"
                              placeholder={'Inserisci PIN'}
                              validate={{
                                required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                number: {value: true, errorMessage: 'Questo campo deve contenere solo numeri.'},
                                pattern: {
                                  value: '^[0-9]*$',
                                  errorMessage: 'Questo campo deve contenere solo numeri.'
                                },
                                minLength: {
                                  value: 5,
                                  errorMessage: 'Il Pin deve contenere 5 cifre'
                                },
                                maxLength: {
                                  value: 5,
                                  errorMessage: 'Il Pin deve contenere 5 cifre'
                                }
                              }}
                              helpMessage="PIN segreto composto da 5 caratteri numerici"
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardBody>
                        <Alert color="danger" className="mt-2 text-center">
                          Con la presente, il richiedente<br/>
                          <b>CHIEDE</b><br/>
                          DI RICEVERE BUONI SPESA IN BASE AI CONTROLLI EFFETTUATI<br/><br/>
                          <p>
                            autorizzando al trattamento dei dati personali nel rispetto di quanto disposto dal D. Lgs. 196/2003 e s.m.i. (D.Lgs.101/2018- GDPR 2016/679) in materia di protezione dei dati personali, consapevole che, ai sensi dell’art.10 L.675/96, che i dati personali raccolti saranno trattati, anche con strumenti informatici, esclusivamente nell’ambito del procedimento per il quale la presente dichiarazione viene resa,
                            consapevole che il Comune di {amministrazioneComunale ? amministrazioneComunale.comune :  ''} si riserva la possibilità di effettuare dei controlli, anche a campione, sulla veridicità delle suddette dichiarazioni le quali potrebbero comportare eventuali responsabilità penali e sanzioni (artt.71,75 e 76 D.P.R.445/2000)
                          </p>
                        </Alert>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <hr/>
                <Row className="text-right">
                  <Col>
                    <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                      <FontAwesomeIcon icon="save"/>
                      &nbsp; Salva e invia richiesta
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>
        </>
      );
    }
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  cittadinoEntity: storeState.cittadino.entity,
  loading: storeState.cittadino.loading,
  amministrazioneComunale: storeState.amministrazioneComunale.entity,
  amministrazioneComunaleLoading: storeState.amministrazioneComunale.loading,
  amministrazioneComunaleErrorMessage: storeState.amministrazioneComunale.errorMessage,
  updating: storeState.cittadino.updating,
  updateSuccess: storeState.cittadino.updateSuccess,
  errorMessage: storeState.cittadino.errorMessage,
  document: storeState.document
});

const mapDispatchToProps = {
  setEntityProperty,
  setBlob,
  createEntity,
  reset,
  getAmministrazioneComunale,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CittadinoUpdateTemplate1);
