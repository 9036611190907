import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from 'app/entities/esercente/esercente.reducer';
import { IEsercente } from 'app/shared/model/esercente.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IEsercenteDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class EsercenteDetail extends React.Component<IEsercenteDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { esercenteEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            Esercente [<b>{esercenteEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="partitaIva">Partita Iva</span>
            </dt>
            <dd>{esercenteEntity.partitaIva}</dd>
            <dt>
              <span id="ragioneSociale">Ragione Sociale</span>
            </dt>
            <dd>{esercenteEntity.ragioneSociale}</dd>
            <dt>
              <span id="codiceAtecoPrincipale">Codice Ateco Principale</span>
            </dt>
            <dd>{esercenteEntity.codiceAtecoPrincipale}</dd>
            <dt>
              <span id="referente">Referente</span>
            </dt>
            <dd>{esercenteEntity.referente}</dd>
            <dt>
              <span id="email">Email</span>
            </dt>
            <dd>{esercenteEntity.email}</dd>
            <dt>
              <span id="telefono">Telefono</span>
            </dt>
            <dd>{esercenteEntity.telefono}</dd>
            <dt>
              <span id="cellulare">Cellulare</span>
            </dt>
            <dd>{esercenteEntity.cellulare}</dd>
            <dt>
              <span id="indirizzo">Indirizzo</span>
            </dt>
            <dd>{esercenteEntity.indirizzo}</dd>
            <dt>
              <span id="codicePostale">Codice Postale</span>
            </dt>
            <dd>{esercenteEntity.codicePostale}</dd>
            <dt>
              <span id="comune">Comune</span>
            </dt>
            <dd>{esercenteEntity.comune}</dd>
            <dt>
              <span id="provincia">Provincia</span>
            </dt>
            <dd>{esercenteEntity.provincia}</dd>
            <dt>
              <span id="iban">Iban</span>
            </dt>
            <dd>{esercenteEntity.iban}</dd>
            <dt>
              <span id="banca">Banca</span>
            </dt>
            <dd>{esercenteEntity.banca}</dd>
            <dt>
              <span id="saldo">Saldo</span>
            </dt>
            <dd>{esercenteEntity.saldo}</dd>
            <dt>
              <span id="consegnaDomicilio">Consegna Domicilio</span>
            </dt>
            <dd>{esercenteEntity.consegnaDomicilio ? 'true' : 'false'}</dd>
            <dt>Comune Riferimento</dt>
            <dd>{esercenteEntity.comuneRiferimento ? esercenteEntity.comuneRiferimento.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/esercente" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/esercente/${esercenteEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ esercente }: IRootState) => ({
  esercenteEntity: esercente.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EsercenteDetail);
