import { Moment } from 'moment';
import { StatoRichiedente } from 'app/shared/model/enumerations/stato-richiedente.model';

export interface IRichiesta {
  id?: number;
  codiceFiscale?: string;
  telefono?: string;
  iban?: string;
  cellulare?: string;
  indirizzo?: string;
  codicePostale?: string;
  componentiNucleoFamiliare?: number;
  disabiliNucleoFamiliare?: number;
  anzianiNucleoFamiliare?: number;
  minoriNucleoFamiliare?: number;
  quarantenaNucleoFamiliare?: number;
  pin?: string;
  tesseraContentType?: string;
  tessera?: any;
  tesseraUrl?: string;
  tesseraFileName?: string;
  richiestaContentType?: string;
  tesseraSize: number;
  richiesta?: any;
  richiestaUrl?: string;
  richiestaFileName?: string;
  richiestaSize: number;
  iseContentType?: string;
  ise?: any;
  iseUrl?: string;
  iseFileName?: string;
  iseSize: number;
  stato?: StatoRichiedente;
  approvedDate?: Moment;
  firstName?: string;
  lastName?: string;
  email?: string;
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
}

export const defaultValue: Readonly<IRichiesta> = {};
