import React from 'react';
import {connect} from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledAlert
} from 'reactstrap';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from 'availity-reactstrap-validation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {IRootState} from 'app/shared/reducers';
import {getPublicEntities as getCategorieEsercenti} from 'app/entities/categoria-esercente/categoria-esercente.reducer';
import {getPublicEntity as getEntity} from 'app/entities/esercente/esercente.reducer';
import {creaAnticipoSpesa, creaLiquidazione} from "app/entities/transazione/transazione.reducer";
import {TipologiaTransazione} from "app/shared/model/enumerations/tipologia-transazione.model";

export interface IEsercenteDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEsercenteDetailState {
  comuneRiferimentoId: string;
  showModal: boolean;
  importo: number;
  tipologiaTransazione?: TipologiaTransazione;
}

export class EsercenteDetail extends React.Component<IEsercenteDetailProps, IEsercenteDetailState> {
  constructor(props) {
    super(props);
    this.state = {
      comuneRiferimentoId: '0',
      showModal: false,
      importo: null,
      tipologiaTransazione: null
    };

    this.creaTransazione = this.creaTransazione.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
    this.props.getCategorieEsercenti();
  }

  componentDidUpdate(propsPrecedenti) {
    if (this.props.transazioneUpdateSuccess !== propsPrecedenti.transazioneUpdateSuccess && this.props.transazioneUpdateSuccess === true) {
      this.handleCloseModal();
      this.setState({importo: null});
    }
  }

  creaTransazione = (tipologiaTransazione, event) => {
    if (this.state.importo && !isNaN(this.state.importo) && this.state.importo !== 0) {
      const transazioneEntity = {
        importo: this.state.importo,
        esercenteId: this.props.match.params.id
      }
      if (tipologiaTransazione === TipologiaTransazione.TT_04_LIQUIDAZIONE) {
        this.props.creaLiquidazione(transazioneEntity).then(respone=> {
          this.props.getEntity(this.props.match.params.id);
        });
      }
      if (tipologiaTransazione === TipologiaTransazione.TT_06_ANTICIPO_SPESA) {
        this.props.creaAnticipoSpesa(transazioneEntity).then(respone=> {
          this.props.getEntity(this.props.match.params.id);
        });
      }

    }
    // this.handleCloseModal();
  };

  handleChange = event => {
    this.setState({importo: event.target.value});
  };

  handleCloseModal() {
    this.setState({
      showModal: false,
      tipologiaTransazione: null
    });
  };

  handleOpenModal(tipologiaTransazione = null) {
    this.setState({
      showModal: true,
      tipologiaTransazione
    });
  };

  render() {
    const { account,
      categorieEsercenti,
      esercenteEntity,
      loading,
      transazioneUpdating,
      transazioneUpdateSuccess
    } = this.props;
    const { showModal, importo, tipologiaTransazione } = this.state;
    const valid = importo && !isNaN(importo) && importo !== 0;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="12">
            <h2>Dettaglio esercente</h2>
          </Col>
        </Row>
        <hr />
        <Row className="justify-content-center">
          <Col md="12">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={esercenteEntity}>
                {transazioneUpdateSuccess &&
                  <Row>
                    <Col>
                      <UncontrolledAlert color="success">
                        Transazione salvata con successo.
                      </UncontrolledAlert>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni esercente
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="ragioneSocialeLabel" for="esercente-ragioneSociale">
                              Ragione Sociale
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              id="esercente-ragioneSociale"
                              type="text"
                              name="ragioneSociale"
                              readOnly
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="telefonoLabel" for="esercente-telefono">
                              Telefono
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              id="esercente-telefono"
                              type="text"
                              name="telefono"
                              readOnly
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="indirizzoLabel" for="esercente-indirizzo">
                              Indirizzo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-indirizzo" type="text" name="indirizzo" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="codicePostaleLabel" for="esercente-codicePostale">
                              Codice Postale
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-codicePostale" type="text" name="codicePostale" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="comuneLabel" for="esercente-comune">
                              Comune
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-comune" type="text" name="comune" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="provinciaLabel" for="esercente-provincia">
                              Provincia
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-provincia" type="text" name="provincia" readOnly/>
                          </Col>
                        </Row>
                        {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                          <Row>
                            <Col md="3" className="text-right pt-1">
                              <Label id="categoriaEsercenteLabel" for="esercente-categoriaEsercente">
                                Categoria Esercente
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvInput id="esercente-categoriaEsercente" type="select" className="form-control" name="categoriaEsercente.id" readOnly
                                disabled>
                                {categorieEsercenti
                                  ? categorieEsercenti.map(otherEntity => (
                                      <option value={otherEntity.id} key={otherEntity.id}>
                                        {otherEntity.denominazione}
                                      </option>
                                    ))
                                  : null}
                              </AvInput>
                            </Col>
                          </Row>
                        }
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni referente
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="referenteLabel" for="esercente-referente">
                              Referente Nominativo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              id="esercente-referente"
                              type="text"
                              name="referente"
                              readOnly
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="emailLabel" for="esercente-email">
                              Referente Email
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-email" type="text" name="email" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="cellulareLabel" for="esercente-cellulare">
                              Referente Cellulare
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-cellulare" type="text" name="cellulare" readOnly/>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                  </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni Generali
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="codiceAtecoPrincipaleLabel" for="esercente-codiceAtecoPrincipale">
                              Codice Ateco Principale
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvField id="esercente-codiceAtecoPrincipale" type="text" name="codiceAtecoPrincipale" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="ibanLabel" for="esercente-iban">
                              IBAN
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvField id="esercente-iban" type="text" name="iban" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="bancaLabel" for="esercente-banca">
                              Banca
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvField id="esercente-banca" type="text" name="banca" readOnly/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="partitaIvaLabel" for="esercente-partitaIva">
                              Partita Iva
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvField
                              id="esercente-partitaIva"
                              type="text"
                              name="partitaIva"
                              readOnly
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="consegnaDomicilioLabel">
                              Consegna Domicilio
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvInput id="esercente-consegnaDomicilio" type="select" className="form-control" name="consegnaDomicilio" readOnly disabled
                                     value={esercenteEntity.consegnaDomicilio}>
                              <option value="true">SI</option>
                              <option value="false">NO</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni Accesso Utente
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label for="operatoreFirstName">Accesso Utente (Nome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="operatoreFirstName"
                                value={esercenteEntity.operatoris ? esercenteEntity.operatoris[0].firstName : ''}
                                readOnly
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label for="operatoreLastName">Accesso Utente (Cognome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="operatoreLastName"
                                value={esercenteEntity.operatoris ? esercenteEntity.operatoris[0].lastName : ''}
                                readOnly
                              />
                              <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label for="operatoreEmail">Accesso Utente (Email)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                name="operatoreEmail"
                                type="email"
                                readOnly
                                value={esercenteEntity.operatoris ? esercenteEntity.operatoris[0].email : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardBody>
                        <Row className="align-items-center">
                          <Col md="6" className="text-right d-inline">
                            <Label style={{verticalAlign: 'middle'}}>
                              Saldo Disponibile:
                            </Label>
                            <Label className="ml-2" style={{verticalAlign: 'middle'}}>
                              <strong className="h3">{esercenteEntity.saldo ? esercenteEntity.saldo.toLocaleString() : 0} €</strong>
                            </Label>
                          </Col>
                          <Col md="6">
                            <Button onClick={this.handleOpenModal.bind(this, TipologiaTransazione.TT_04_LIQUIDAZIONE )} color="success" block>
                              <FontAwesomeIcon icon="plus" /> liquida esercente
                            </Button>
                          </Col>
                        </Row>
                        {account && account.amministrazioneComunale && account.amministrazioneComunale.voucherAbilitati &&
                          <Row className="align-items-center pt-3">
                            <Col md="6" className="text-right d-inline">
                              <Label className="mb-0" style={{verticalAlign: 'middle'}}>
                                Saldo Voucher Disponibile:
                              </Label>
                              <Label className="ml-2 mb-0" style={{verticalAlign: 'middle'}}>
                                <strong
                                  className="h3">{esercenteEntity.saldoVouchers ? esercenteEntity.saldoVouchers.toLocaleString() : 0} €</strong>
                              </Label>
                            </Col>
                            <Col md="6">
                              <Button onClick={this.handleOpenModal.bind(this, TipologiaTransazione.TT_06_ANTICIPO_SPESA)}
                                      color="success" block>
                                <FontAwesomeIcon icon="plus"/> genera anticipo voucher
                              </Button>
                            </Col>
                          </Row>
                        }
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <hr />
                <Row className="align-items-center text-right">
                  <Col>
                    <Button tag={Link} id="cancel-save" to="/amministrazione/esercente" replace color="info">
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">Elenco esercenti</span>
                    </Button>
                    &nbsp;
                    <Button tag={Link} to={`/amministrazione/esercente/${esercenteEntity.id}/edit`} replace color="primary">
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Modifica</span>
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            )}
          </Col>
        </Row>
        <Modal isOpen={showModal} toggle={this.handleCloseModal} className="modal-lg">
          <ModalHeader toggle={this.handleCloseModal}>{tipologiaTransazione &&
          (tipologiaTransazione === TipologiaTransazione.TT_04_LIQUIDAZIONE ?
            'Aggiungi liquidazione'
              :
            'Genera vouchers'
          )}
          </ModalHeader>
          <ModalBody>
            {transazioneUpdating &&
              <div className="text-center">
                <h5>salvataggio transazione in corso...</h5>
                <Spinner color="dark" />
              </div>
            }
            {!transazioneUpdating &&
              <>
                {<p>Il saldo corrente è: <strong>€ {esercenteEntity && (esercenteEntity.saldo || esercenteEntity.saldoVouchers)
                      ? (tipologiaTransazione === TipologiaTransazione.TT_04_LIQUIDAZIONE) ?
                        esercenteEntity.saldo.toLocaleString()
                        :
                        esercenteEntity.saldoVouchers.toLocaleString()
                      :
                      0
                    }
                  </strong>.
                  </p>
                }
                <p>Indica di seguito l'importo da aggiungere al saldo corrente:</p>
                <Form>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>€</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        id="saldo"
                        type="number"
                        className="form-control"
                        onChange={this.handleChange}
                        value={importo}
                        valid={ valid }
                        invalid={ !valid }
                        name="saldo"
                        placeholder="indica l'importo da aggiungere"
                        validate={{
                          required: { value: true, errorMessage: 'Questo campo è obbligatorio.' },
                          number: { value: true, errorMessage: 'Questo campo deve essere numerico.' }
                        }}
                      />
                      <InputGroupAddon addonType="append">
                        <Button color="success" onClick={this.creaTransazione.bind(this, tipologiaTransazione)} disabled={!valid || transazioneUpdating}>aggiungi</Button>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormFeedback
                      valid={ valid }
                      invalid={ !valid }>Il campo importo è obbligatorio e deve essere un numerico</FormFeedback>
                  </FormGroup>
                </Form>
              </>
            }
            {/* <p className="pt-4">Riepilogo transazioni:</p>
              <Table responsive striped size="sm">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Importo inserito</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      12/03/2020
                    </td>
                    <td>€ { (2000).toLocaleString()}</td>
                  </tr>
                </tbody>
              </Table> */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleCloseModal}>Chiudi</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  categorieEsercenti: storeState.categoriaEsercente.entities,
  esercenteEntity: storeState.esercente.entity,
  loading: storeState.esercente.loading,
  transazioneUpdating: storeState.transazione.updating,
  transazioneUpdateSuccess: storeState.transazione.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getCategorieEsercenti,
  getEntity,
  creaLiquidazione,
  creaAnticipoSpesa
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EsercenteDetail);
