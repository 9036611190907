import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button,
  Row,
  Col,
  Alert,
  Container,
  Badge,
  Spinner
} from 'reactstrap';
import { CittadinoUpdateTemplate1 } from './template_1/cittadino-update';
import { CittadinoUpdateTemplate2 } from './template_2/cittadino-update';
import { IRootState } from 'app/shared/reducers';

import { getPublicEntityForCittadino as getAmministrazioneComunale } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';

import {
  createPublicEntityForCittadino as createEntity,
  setBlob,
  reset,
  setEntityProperty
} from 'app/entities/cittadino/cittadino.reducer';
import { download } from 'app/entities/document.reducer';

export interface ICittadinoUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ comuneStringId: string }> {}

export interface ICittadinoUpdateState {}

export class CittadinoUpdate extends React.Component<ICittadinoUpdateProps, ICittadinoUpdateState> {

  constructor(props) {
    super(props);

    this.props.getAmministrazioneComunale(this.props.match.params.comuneStringId);
  }

  componentWillUpdate(nextProps, nextState) {
  }

  componentDidMount() {
  }

  render() {
    const {
      amministrazioneComunale,
      amministrazioneComunaleLoading,
      amministrazioneComunaleErrorMessage
    } = this.props;

    if ((amministrazioneComunaleLoading)) {
      return (
        <>
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <Spinner animation="border" />
              </Col>
            </Row>
          </Container>
        </>
      );
    } else if (amministrazioneComunaleErrorMessage) {
      return (
        <>
          <Container>
            <Row>
              <Col md="12" className="text-center">
                <Alert color="danger">
                  <h3>Ci dispiace, si è verificato un errore!</h3>
                  <hr />
                  {amministrazioneComunaleErrorMessage.response.status === 404 ?
                    <h5>Controlla che il link inserito sia corretto</h5>
                    :
                    <h5>{amministrazioneComunaleErrorMessage.message}</h5>
                  }
                </Alert>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      switch (amministrazioneComunale.formRegistrazioneCittadinoTemplate) {
        case 'TEMPLATE_1':
          return (
            <CittadinoUpdateTemplate1 {...this.props} />
          );
          break;
        case 'TEMPLATE_2':
          return (
            <CittadinoUpdateTemplate2 {...this.props}/>
          );
          break;
        default:
          return (
            <CittadinoUpdateTemplate1 {...this.props}/>
          );
      }
    }
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  cittadinoEntity: storeState.cittadino.entity,
  loading: storeState.cittadino.loading,
  amministrazioneComunale: storeState.amministrazioneComunale.entity,
  amministrazioneComunaleLoading: storeState.amministrazioneComunale.loading,
  amministrazioneComunaleErrorMessage: storeState.amministrazioneComunale.errorMessage,
  updating: storeState.cittadino.updating,
  updateSuccess: storeState.cittadino.updateSuccess,
  errorMessage: storeState.cittadino.errorMessage,
  document: storeState.document
});

const mapDispatchToProps = {
  setEntityProperty,
  createEntity,
  setBlob,
  reset,
  getAmministrazioneComunale,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CittadinoUpdate);
