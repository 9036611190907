import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Row,
  Table,
  Col,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  Button,
  CardFooter,
  Badge
} from 'reactstrap';
import {  getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getPublicEsercenti } from 'app/entities/esercente/esercente.reducer';
import { getPublicEntityForGuest } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

import './guest.scss';

export interface IEsercentiPageProps extends StateProps, DispatchProps, RouteComponentProps<{ comuneStringId: string }> {}

export interface IEsercentiPageState extends IPaginationBaseState {
  categorie: any;
};

export class EsercentiPage extends React.Component<IEsercentiPageProps, IEsercentiPageState> {
  state: IEsercentiPageState = {
    ...getSortState(this.props.location, 1000),
    sort: 'ragioneSociale',
    order: 'asc',
    categorie: []
  };

  componentDidMount() {
    var _that = this;
    this.props.getPublicEntityForGuest(this.props.match.params.comuneStringId).then(respone=> {
      _that.getPublicEsercenti();
    });

    this.getPublicEsercenti = this.getPublicEsercenti.bind(this);
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getPublicEsercenti();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getPublicEsercenti() {
    var _that = this;
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getPublicEsercenti(this.props.match.params.comuneStringId, activePage - 1, itemsPerPage, `${sort},${order}`).then(res => {
      let categorieObj = {};

      if (_that.props.amministrazione &&
        _that.props.amministrazione.amministrazioneComunale &&
        this.props.amministrazione.amministrazioneComunale.categorieAbilitate) {

        let categorie = [];
        res.value.data.forEach( e => {
          if (categorie.filter(c => c === e.categoriaEsercente.denominazione).length === 0) {
            categorie.push(e.categoriaEsercente.denominazione);
          }
        });

        categorie.forEach( c => {
          let esercentiPerCategoria = res.value.data.filter(e => e.categoriaEsercente.denominazione === c);
          categorieObj[c] = esercentiPerCategoria.sort((a, b) => b.ragioneSociale - a.ragioneSociale)
        });

      } else {
        categorieObj = {
          'Esercizi' : res.value.data.sort((a, b) => b.ragioneSociale - a.ragioneSociale)
        };
      }

      this.setState({
        categorie: categorieObj
      })
    });;
  };

  render() {
    const { esercentiLoading} = this.props;
    const { categorie } = this.state;
    return (
      <div>
        <Row>
          <Col xs="12" className="text-center">
            <h2 id="esercente-heading">
              Elenco esercizi aderenti all'iniziativa
            </h2>
          </Col>
        </Row>
        <hr />
        {esercentiLoading &&
          <div className="text-center">
            <h5>caricamento in corso...</h5>
            <Spinner color="dark" />
          </div>
        }
        {!esercentiLoading &&
          <>
            {Object.keys(categorie).length > 0 ?
              <>
                {Object.keys(categorie).map(categoria => (
                  <Card className="mb-3">
                    <CardBody>
                      <h5><FontAwesomeIcon icon="tags" /> {categoria}</h5>
                      <Row>
                        {categorie[categoria].map((esercente, i) => {
                          return (
                            <Col md="3">
                              <Card style={{minHeight: '200px', margin: '5px'}}>
                                <CardBody>
                                  <CardTitle tag="h5">{esercente.ragioneSociale}</CardTitle>
                                  <CardText>
                                    <p className="mb-1">Referente - {esercente.referente}</p>
                                    {esercente.indirizzo &&
                                      <p>Indirizzo - {esercente.indirizzo}</p>
                                    }
                                    {esercente.telefono &&
                                      <Badge color="primary">
                                        <FontAwesomeIcon icon="phone" /> {esercente.telefono}
                                      </Badge>
                                    }
                                    {esercente.consegnaDomicilio &&
                                      <Badge color="success">
                                        <FontAwesomeIcon icon="check-circle" /> Consegna a domicilio
                                      </Badge>
                                    }
                                  </CardText>
                                </CardBody>
                              </Card>
                            </Col>
                          )
                        })}
                      </Row>
                    </CardBody>
                  </Card>
                ))}
              </>
            :
              <div className="alert alert-warning">Non è presente nessun esercente</div>
            }
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ esercente, amministrazioneComunale }: IRootState) => ({
  esercentiList: esercente.entities,
  totalItems: esercente.totalItems,
  esercentiLoading: esercente.loading,
  amministrazione: amministrazioneComunale.entity,
  amministrazioneLoading: amministrazioneComunale.loading
});

const mapDispatchToProps = {
  getPublicEsercenti,
  getPublicEntityForGuest
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EsercentiPage);
