import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const itaLocale = {
  "format": "DD/MM/YYYY",
  "separator": " - ",
  "applyLabel": "Imposta",
  "cancelLabel": "Annulla",
  "fromLabel": "Dal",
  "toLabel": "Al",
  "customRangeLabel": "Personalizzata",
  "weekLabel": "Sett.",
  "daysOfWeek": [
    "Dom",
    "Lun",
    "Mar",
    "Mer",
    "Gio",
    "Ven",
    "Sab"
  ],
  "monthNames": [
    "Gennaio",
    "Febbraio",
    "Marzo",
    "Aprile",
    "Maggio",
    "Giugno",
    "Luglio",
    "Agosto",
    "Settembre",
    "Ottobre",
    "Novembre",
    "Dicembre"
  ],
  "firstDay": 1
}
