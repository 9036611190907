import React from 'react';
import { connect } from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import { Row,
  Badge,
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Card,
  CardHeader,
  CardBody,
  Col,
  Label,
  Container,
  Spinner,
  Progress
} from 'reactstrap';
import {  AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {IRootState} from "app/shared/reducers";
import {
  getPublicEntityForCittadino as getAmministrazioneComunale
} from "app/entities/amministrazione-comunale/amministrazione-comunale.reducer";
import {setBlob, setEntityProperty, createPublicEntityForCittadino as createEntity, reset, sendEmail } from "app/entities/richiesta/richiesta.reducer";
import {setFileData} from "react-jhipster";
import fileDownload from "js-file-download";
import {download} from "app/entities/document.reducer";
import {
  checkIfExistCittadinoCodicefiscale,
} from "app/shared/util/entity-utils";
import axios from "axios";
import {humanFileSize} from "app/shared/util/misc-utils";
import {setEmailToNullIdEmpty} from "app/entities/cittadino/cittadino.reducer";

export interface INuovaRichiestaModalProps extends StateProps, DispatchProps, RouteComponentProps<{ comuneStringId: string }> {
  showModal: boolean;
  handleCloseModal: Function;
  amministrazioneComunale: any;
  comuneStringId: string;
}

export interface INuovaRichiestaModalState {
  tesseraLoading: boolean;
  tesseraLoadingPerc: number;
  richiestaLoading: boolean;
  richiestaLoadingPerc: number;
  iseLoading: boolean;
  iseLoadingPerc: number;
  tesseraUploadStatus: string;
  richiestaUploadStatus: string;
  iseUploadStatus: string;
  richiestaCreating: boolean;
};

export class NuovaRichiestaModal extends React.Component<INuovaRichiestaModalProps, INuovaRichiestaModalState> {
  tessera = null;
  richiesta = null;
  ise = null;
  stepCaricamento = 0;

  constructor(props) {
    super(props);

    this.state = {
      tesseraLoading: false,
      richiestaLoading: false,
      iseLoading: false,
      tesseraLoadingPerc: 0,
      richiestaLoadingPerc: 0,
      iseLoadingPerc: 0,
      tesseraUploadStatus: null,
      richiestaUploadStatus: null,
      iseUploadStatus: null,
      richiestaCreating: false
    }

    this.saveEntity = this.saveEntity.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.somethingLoading = this.somethingLoading.bind(this);
    this.resetComp = this.resetComp.bind(this);
    this.caricaTessera = this.caricaTessera.bind(this);
    this.caricaIse = this.caricaIse.bind(this);
    this.caricaRichiesta = this.caricaRichiesta.bind(this);
  }

  componentDidMount() {
  }

  componentWillUnmount() {
    this.props.reset();
    this.resetComp();
  }

  handleCloseModal = () => {
    if (!this.somethingLoading()) {
      this.props.handleCloseModal();
      this.props.reset();
      this.resetComp();
    }
  };

  resetComp() {
    this.tessera = null;
    this.richiesta = null;
    this.ise = null;
    this.stepCaricamento = 0;

    this.setState({
      tesseraLoading: false,
      richiestaLoading: false,
      iseLoading: false,
      tesseraLoadingPerc: 0,
      richiestaLoadingPerc: 0,
      iseLoadingPerc: 0,
      tesseraUploadStatus: null,
      richiestaUploadStatus: null,
      iseUploadStatus: null,
      richiestaCreating: false
    })
  }

  onBlobChange = (isAnImage, name) => event => {
    const filename = event.target.files[0].name;
    const size = event.target.files[0].size;
    const fileDataCallBackfunction = (contentType, data) => {
      this.props.setBlob(name, data, contentType, filename, size )
    };

    setFileData(event, fileDataCallBackfunction, isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined, undefined, undefined);
  };

  setEntityProperty = (propertyName, value)  => event => {
    this.props.setEntityProperty(propertyName, value);
  }

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  saveEntity(event, errors, values) {
    var _that = this;
    const { richiestaEntity } = this.props;
    if (errors.length === 0) {


      const entity = {
        ...richiestaEntity,
        ...values
      };

      entity.disabiliNucleoFamiliare = 0;
      entity.anzianiNucleoFamiliare = 0;
      entity.minoriNucleoFamiliare = 0;
      entity.quarantenaNucleoFamiliare = 0;

      if (entity.email === '' || !entity.email) {
        delete entity.email;
      }

      if (entity.tessera === '' || !entity.tessera) {
        delete entity.tessera;
      } else {
        if (entity.tesseraSize < 4000000) {
          this.tessera = {
            file: entity.tessera,
            contentType: entity.tesseraContentType
          }
          delete entity.tessera;
        } else {
          return;
        }

        this.stepCaricamento++;
      }

      if (entity.richiesta === '' || !entity.richiesta) {
        delete entity.richiesta;
      } else {
        if (entity.richiestaSize < 4000000) {
          this.richiesta = {
            file: entity.richiesta,
            contentType: entity.richiestaContentType
          }
          delete entity.richiesta
        } else {
          return;
        }
        this.stepCaricamento++;
      }

      if (entity.ise === '' || !entity.ise) {
        delete entity.ise;
      } else {
        if (entity.iseSize < 4000000) {
          this.ise = {
            file: entity.ise,
            contentType: entity.iseContentType
          }
          delete entity.ise;
        } else {
          return;
        }
        this.stepCaricamento++;
      }

      this.setState({
        richiestaCreating: true
      }, () => {
        this.props.createEntity(setEmailToNullIdEmpty(entity), this.props.comuneStringId).then(async (response) => {
          this.setState({
            richiestaCreating: false
          }, async () => {
            if (!_that.tessera && !_that.richiesta && !_that.ise) {
              _that.sendEmail();
            } else {
              if (_that.tessera) {
                await _that.caricaTessera();
              }
              if (_that.richiesta) {
                await _that.caricaRichiesta();
              }
              if (_that.ise) {
                await _that.caricaIse();
              }
            }
          });
        });
      });
    } else {
      this.forceUpdate();
    }
  };

  async caricaTessera() {
    var _that = this;

    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        _that.setState({
          tesseraLoadingPerc: percentCompleted
        })
      }
    }

    _that.setState({
      tesseraUploadStatus: null,
      tesseraLoading: true
    });
    try {
      await axios.post('api/upload/richiesta/' + this.props.richiestaEntity.id + '/tessera', this.tessera, config);
      _that.tessera = null;
      _that.stepCaricamento--;
      _that.setState({
        tesseraUploadStatus: 'ok',
        tesseraLoading: false
      });
      if (_that.stepCaricamento === 0) {
        _that.sendEmail();
      }
    } catch(e){
      _that.setState({
        tesseraUploadStatus: 'ko',
        tesseraLoading: false
      });
    }
  }

  async caricaRichiesta() {
    var _that = this;

    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        _that.setState({
          richiestaLoadingPerc: percentCompleted
        })
      }
    }


    _that.setState({
      richiestaUploadStatus: null,
      richiestaLoading: true
    });
    try {
      await axios.post('api/upload/richiesta/' + this.props.richiestaEntity.id + '/richiesta', this.richiesta, config);
      _that.richiesta = null;
      _that.stepCaricamento--;
      _that.setState({
        richiestaUploadStatus: 'ok',
        richiestaLoading: false
      });
      if (_that.stepCaricamento === 0) {
        _that.sendEmail();
      }
    } catch(e) {
      _that.setState({
        richiestaUploadStatus: 'ko',
        richiestaLoading: false
      });
    }
  }

  async caricaIse() {
    var _that = this;

    const config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        _that.setState({
          iseLoadingPerc: percentCompleted
        })
      }
    }


    _that.setState({
      iseUploadStatus: null,
      iseLoading: true
    });
    try {
      await axios.post('api/upload/richiesta/' + this.props.richiestaEntity.id + '/ise', this.ise, config);
      _that.ise = null;
      _that.stepCaricamento--;
      _that.setState({
        iseUploadStatus: 'ok',
        iseLoading: false
      });
      if (_that.stepCaricamento === 0) {
        _that.sendEmail();
      }
    } catch(e) {
      _that.setState({
        iseUploadStatus: 'ko',
        iseLoading: false
      });
    }
  }

  sendEmail() {
    this.props.sendEmail(this.props.richiestaEntity.id, this.props.comuneStringId);
  }

  somethingLoading() {
    const { updating, emailSending } = this.props;
    const { richiestaLoading, tesseraLoading, iseLoading, richiestaCreating } = this.state;
    const somethingLoading = (updating || richiestaCreating || richiestaLoading || tesseraLoading || iseLoading || emailSending);
    return somethingLoading;
  }

  render() {
    const { richiestaEntity,
      showModal,
      amministrazioneComunale,
      updating,
      errorMessage,
      errorEmailMessage,
      updateSuccess,
      emailSending,
      emailSent
    } = this.props;
    const { tesseraLoading,
      richiestaLoading,
      iseLoading,
      tesseraLoadingPerc,
      richiestaLoadingPerc,
      iseLoadingPerc,
      tesseraUploadStatus,
      richiestaUploadStatus,
      iseUploadStatus,
      richiestaCreating } = this.state;
    const { tessera, tesseraContentType, tesseraUrl, tesseraFileName, tesseraSize } = richiestaEntity;
    const { richiesta, richiestaContentType, richiestaUrl, richiestaFileName, richiestaSize } = richiestaEntity;
    const { ise, iseContentType, iseUrl, iseFileName, iseSize } = richiestaEntity;

    const setModalBodyContent = () => {

      const setSendEmailAlertContent = () => {
        if (!emailSent && errorEmailMessage === null) {
          return(
            <Alert color="warning">
              <h3>{emailSending && <FontAwesomeIcon icon="spinner" spin />} Invio comunicazione in corso...</h3>
            </Alert>
          );
        } else if (errorEmailMessage !== null) {
          return (
            <Alert color="danger">
              <h3><FontAwesomeIcon color="danger" icon="times-circle"  /> Invio comunicazione fallito</h3>
              <h6>Siamo spiacenti. Si è verificato un problema durante il salvataggio della richiesta.</h6>
              <Button color="danger" onClick={this.sendEmail} className="mb-2">
                <FontAwesomeIcon icon="upload"/> Riprova
              </Button>
            </Alert>
          );
        } else if (emailSent) {
          return (
            <Alert color="success">
              <h3><FontAwesomeIcon icon={'check-circle'}  /> Invio comunicazione completato</h3>
            </Alert>
          );
        }
      };

      if (updating || richiestaCreating) {
        return (
          <>
            <Container>
              <Row>
                <Col md="12" className="text-center">
                  <Spinner animation="border" />
                </Col>
              </Row>
            </Container>
          </>
        );
      } else if (errorMessage) {
        return (
          <>
            <Container>
              <Row>
                <Col md="12" className="text-center">
                  <Alert color="danger">
                    <h3>Ci dispiace, si è verificato un errore durante il salvataggio!</h3>
                    <hr />
                    {errorMessage.response.status === 404 ?
                      <h5>Controlla che il link inserito sia corretto</h5>
                      :
                      <h5>{errorMessage.message}</h5>
                    }
                  </Alert>
                </Col>
              </Row>
            </Container>
          </>
        );
      } else if (updateSuccess) {
        if ( this.tessera || this.richiesta || this.ise || tesseraUploadStatus || richiestaUploadStatus || iseUploadStatus) {
          return (
            <Container>
              <Row>
                <Col md="12" className="text-center">
                  {(this.tessera || tesseraUploadStatus) &&
                    <Alert color={tesseraUploadStatus == null ? 'warning' : tesseraUploadStatus === 'ok' ? 'success' : 'danger'}>
                      <>
                        {!tesseraUploadStatus &&
                        <h3>{tesseraLoading && <FontAwesomeIcon icon="spinner" spin />} Caricamento tessera sanitaria in corso...</h3>
                        }
                        {tesseraUploadStatus &&
                        <>
                          {tesseraUploadStatus === 'ok' &&
                          <h3><FontAwesomeIcon icon={'check-circle'}  /> Caricamento tessera sanitaria completato</h3>
                          }
                          {tesseraUploadStatus === 'ko' &&
                          <>
                            <h3><FontAwesomeIcon color="danger" icon="times-circle"  /> Caricamento tessera sanitaria fallito</h3>
                            <h6>Siamo spiacenti. Si è verificato un problema durante il caricamento del file.</h6>
                            <Button color="danger" onClick={this.caricaTessera} className="mb-2">
                              <FontAwesomeIcon icon="upload"/> Riprova
                            </Button>
                          </>
                          }
                        </>
                        }
                      </>
                      <Progress striped animated={tesseraLoadingPerc !== 100 ? true : false} value={tesseraLoadingPerc} />
                    </Alert>
                  }
                  {(this.richiesta || richiestaUploadStatus) &&
                    <Alert color={richiestaUploadStatus === null ? 'warning' : richiestaUploadStatus === 'ok' ? 'success' : 'danger'}>
                      <>
                        {!richiestaUploadStatus &&
                        <h3>{richiestaLoading && <FontAwesomeIcon icon="spinner" spin />} Caricamento domanda in corso...</h3>
                        }
                        {richiestaUploadStatus &&
                        <>
                          {richiestaUploadStatus === 'ok' &&
                          <h3><FontAwesomeIcon icon={'check-circle'}  /> Caricamento domanda completato</h3>
                          }
                          {richiestaUploadStatus === 'ko' &&
                          <>
                            <h3><FontAwesomeIcon color="danger" icon="times-circle"  /> Caricamento domanda fallito</h3>
                            <h6>Siamo spiacenti. Si è verificato un problema durante il caricamento del file.</h6>
                            <Button color="danger" onClick={this.caricaRichiesta} className="mb-2">
                              <FontAwesomeIcon icon="upload"/> Riprova
                            </Button>
                          </>
                          }
                        </>
                        }
                      </>
                      <Progress striped animated={richiestaLoadingPerc !== 100 ? true : false} value={richiestaLoadingPerc} />
                    </Alert>
                  }
                  {(this.ise || iseUploadStatus) &&
                    <Alert color={iseUploadStatus === null ? 'warning' : iseUploadStatus === 'ok' ? 'success' : 'danger'}>
                      <>
                        {!iseUploadStatus &&
                        <h3>{iseLoading && <FontAwesomeIcon icon="spinner" spin />} Caricamento ISE in corso...</h3>
                        }
                        {iseUploadStatus &&
                        <>
                          {iseUploadStatus === 'ok' &&
                          <h3><FontAwesomeIcon icon={'check-circle'}  /> Caricamento ISE completato</h3>
                          }
                          {iseUploadStatus === 'ko' &&
                          <>
                            <h3><FontAwesomeIcon color="danger" icon="times-circle"  /> Caricamento ISE fallito</h3>
                            <h6>Siamo spiacenti. Si è verificato un problema durante il caricamento del file.</h6>
                            <Button color="danger" onClick={this.caricaIse} className="mb-2">
                              <FontAwesomeIcon icon="upload"/> Riprova
                            </Button>
                          </>
                          }
                        </>
                        }
                      </>
                      <Progress striped animated={iseLoadingPerc !== 100 ? true : false} value={iseLoadingPerc} />
                    </Alert>
                  }
                  {setSendEmailAlertContent()}
                  {this.stepCaricamento === 0 && emailSent &&
                      <Alert color="success">
                      <h3>Registrazione avvenuta con successo!</h3>
                      <hr />
                      <h6>La presentazione della sua domanda è stata correttamente presa in carico dai nostri sistemi.</h6>
                      <br />
                      <em>Cordiali Saluti.</em>
                    </Alert>
                  }
                </Col>
              </Row>
            </Container>
          )
        }
        return (
          <>
            <Container>
              <Row>
                <Col md="12" className="text-center">
                  <Alert color="success">
                    <h3>Registrazione avvenuta con successo!</h3>
                    <hr />
                    <h6>La presentazione della sua domanda è stata correttamente presa in carico dai nostri sistemi.</h6>
                    <br />
                    <em>Cordiali Saluti.</em>
                  </Alert>
                </Col>
              </Row>
            </Container>
          </>
        );
      } else {
        return (
          <>
              <Alert color="warning" className="mt-2 text-center">
                <p>
                  <FontAwesomeIcon icon="info-circle"/> Se è la <b>prima volta</b> che richiedi di beneficiare dei buoni
                  spesa/ristoro erogati dal comune di {amministrazioneComunale.denominazione} allora effettua la <b>registrazione completa</b> cliccando
                  <Badge tag={Link} to={"/registrazione/cittadino/" + this.props.comuneStringId} color="primary"
                         className="ml-2">
                    QUI
                  </Badge>
                </p>
                <p>
                  Se invece sei già registrato ed hai già usufruito dei buoni messi a disposizione dal comune allora puoi
                  richiedere una <b>nuova riassegnazione</b> degli importi comipilando i seguenti dati
                </p>
              </Alert>
            <AvForm onSubmit={this.saveEntity} ref={c => (this.form = c)}>
              <Card>
                <CardHeader>
                  Informazioni cittadino
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="3" className="text-right">
                      <Label className="required" for="richiesta-firstName">Nome</Label>
                    </Col>
                    <Col md="9">
                      <AvGroup>
                        <AvField
                          type="text"
                          className="form-control"
                          name="firstName"
                          id="richiesta-firstName"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Nome obbligatorio'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage: 'Lunghezza massima 50 caratteri'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" className="text-right">
                      <Label className="required" for="richiesta-lastName">Cognome</Label>
                    </Col>
                    <Col md="9">
                      <AvGroup>
                        <AvField
                          type="text"
                          className="form-control"
                          name="lastName"
                          id="richiesta-lastName"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: 'Cognome obbligatorio'
                            },
                            maxLength: {
                              value: 50,
                              errorMessage: 'Lunghezza massima 50 caratteri'
                            }
                          }}
                        />
                      </AvGroup>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col md="3" className="text-right">
                      <Label className="required" id="codiceFiscaleLabel" for="richiesta-codiceFiscale">
                        Codice Fiscale
                      </Label>
                    </Col>
                    <Col md="9">
                      <AvGroup>
                        <AvField
                          id="richiesta-codiceFiscale"
                          type="text"
                          name="codiceFiscale"
                          validate={{
                            required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                            pattern: {
                              value: '^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9][A-Za-z0-9][0-9][A-Za-z]$',
                              errorMessage: 'Non è un codice fiscale valido'
                            },
                            async: checkIfExistCittadinoCodicefiscale(richiestaEntity.codiceFiscale)
                          }}
                          style={{textTransform: 'uppercase'}}
                        />
                      </AvGroup>
                      <Alert color="warning" className="mt-2">
                        <FontAwesomeIcon icon="info-circle"/> <b>ATTENZIONE.</b> Inserisci il codice fiscale che hai utilizzato durante la prima registrazione.
                      </Alert>
                      <Alert color="danger" className="mt-2">
                        <FontAwesomeIcon icon="info-circle"/> <b>ATTENZIONE.</b> Se è la <b>prima volta</b> che richiedi di beneficiare dei buoni devi prima
                        effettuare le <b>registrazione completa</b> cliccando
                        <Badge tag={Link} to={"/registrazione/cittadino/" + this.props.comuneStringId} color="primary"
                               className="ml-2">
                          QUI
                        </Badge>.
                      </Alert>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="mt-4">
                <CardHeader>
                  Documentazione
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="3" className="text-right">
                      <Label id="tesseraLabel" for="tessera" className="required">
                        <b>Documento d'Identità</b>
                      </Label>
                    </Col>
                    <Col md="9">
                      {tesseraUrl ?
                        <div>
                          <span>DocumentoIdentità.{tesseraUrl.split('.')[tesseraUrl.split('.').length - 1]}</span>
                          <a className="ml-1"
                             onClick={this.onDownloadClick.bind(this,`/api/${tesseraUrl}`, 'tessera', tesseraContentType)}>(Download)</a>
                          <a className="ml-1"
                             onClick={this.setEntityProperty('tesseraUrl', '')}>(Remove)</a>
                        </div>
                        :
                        <div>
                          {tessera ? (
                              <div>
                                <Row>
                                  <Col md="12">
                                              <span>
                                                {tesseraFileName}
                                              </span>
                                    <Badge color="danger" onClick={this.clearBlob('tessera')} className="ml-2" style={{cursor: "pointer"}}>
                                      <FontAwesomeIcon icon="times-circle"/>
                                    </Badge>
                                    <Badge className="ml-2">{humanFileSize(tesseraSize, true)}</Badge>
                                  </Col>
                                  {tesseraSize !== null && tesseraSize !== undefined && tesseraSize > 4000000 &&
                                    <div style={{color: 'red', fontSize: '14px'}}><b>Il file non può essere superiore di 4MB</b></div>
                                  }
                                </Row>
                              </div>
                            )
                            :
                            <>
                              <input id="file_tessera" type="file" onChange={this.onBlobChange(false, 'tessera')}/>
                              {this.form && this.form.state && this.form.state.invalidInputs && this.form.state.invalidInputs.tessera &&
                                <div style={{color: 'red', fontSize: '14px'}}><b>CAMPO OBBLIGATORIO</b></div>
                              }
                              <AvInput type="hidden" name="tessera" value={tessera} validate={{
                                required: {value: true, errorMessage: 'Questo campo è obbligatorio.'}
                              }}/>
                            </>
                          }
                        </div>
                      }
                    </Col>
                  </Row>
                  {amministrazioneComunale.documentazioneIse &&
                  <>
                    <hr/>
                    <Row className="pt-2">
                      <Col md="3" className="text-right">
                        <Label id="iseLabel" for="ise" className="required">
                          <b>Carica ISE</b>
                        </Label>
                      </Col>
                      <Col md="9">
                        {iseUrl ?
                          <div>
                            <span>ISE.{iseUrl.split('.')[iseUrl.split('.').length - 1]}</span>
                            <a className="ml-1"
                               onClick={this.onDownloadClick.bind(this,`/api/${iseUrl}`, 'ise', iseContentType)}>(Download)</a>
                            <a className="ml-1"
                               onClick={this.setEntityProperty('iseUrl', '')}>(Remove)</a>
                          </div>
                          :
                          <div>
                            {ise ? (
                                <div>
                                  <Row>
                                    <Col md="12">
                                                    <span>
                                                      {iseFileName}
                                                    </span>
                                      <Badge color="danger" onClick={this.clearBlob('ise')} className="ml-2" style={{cursor: "pointer"}}>
                                        <FontAwesomeIcon icon="times-circle"/>
                                      </Badge>
                                      <Badge className="ml-2">{humanFileSize(iseSize, true)}</Badge>
                                    </Col>
                                    {iseSize !== null && iseSize !== undefined && iseSize > 4000000 &&
                                      <div style={{color: 'red', fontSize: '14px'}}><b>Il file non può essere superiore di 4MB</b></div>
                                    }
                                  </Row>
                                </div>
                              )
                              :
                              <>
                                <input id="file_ise" type="file"
                                       onChange={this.onBlobChange(false, 'ise')}/>
                                {this.form && this.form.state && this.form.state.invalidInputs && this.form.state.invalidInputs.ise &&
                                <div style={{color: 'red', fontSize: '14px'}}><b>CAMPO OBBLIGATORIO</b></div>
                                }
                                <AvInput type="hidden" name="ise" value={ise} validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'}
                                }}/>
                              </>
                            }
                          </div>
                        }
                      </Col>
                    </Row>
                  </>
                  }
                  {amministrazioneComunale.templateModuloRichiestaUrl &&
                  <>
                    <hr/>
                    <Row className="pt-2">
                      <Col md="3" className="text-right">
                        <Label id="domandaLabel" for="domanda" className="required">
                          <b>Carica Domanda compilata</b>
                        </Label>
                      </Col>
                      <Col md="9">
                        {richiestaUrl ?
                          <div>
                            <span>Domanda.{richiestaUrl.split('.')[richiestaUrl.split('.').length - 1]}</span>
                            <a className="ml-1"
                               onClick={this.onDownloadClick.bind(this,`/api/${richiestaUrl}`, 'richiesta', richiestaContentType)}>(Download)</a>
                            <a className="ml-1"
                               onClick={this.setEntityProperty('richiestaUrl', '')}>(Remove)</a>
                          </div>
                          :
                          <div>
                            {richiesta ? (
                                <div>
                                  <Row>
                                    <Col md="12">
                                                    <span>
                                                      {richiestaFileName}
                                                    </span>
                                      <Badge color="danger" onClick={this.clearBlob('richiesta')} className="ml-2" style={{cursor: "pointer"}}>
                                        <FontAwesomeIcon icon="times-circle"/>
                                      </Badge>
                                      <Badge className="ml-2">{humanFileSize(richiestaSize, true)}</Badge>
                                    </Col>
                                    {richiestaSize !== null && richiestaSize !== undefined && richiestaSize > 4000000 &&
                                      <div style={{color: 'red', fontSize: '14px'}}><b>Il file non può essere superiore di 4MB</b></div>
                                    }
                                  </Row>
                                </div>
                              )
                              :
                              <>
                                <input id="file_richiesta" type="file"
                                       onChange={this.onBlobChange(false, 'richiesta')}/>
                                {this.form && this.form.state && this.form.state.invalidInputs && this.form.state.invalidInputs.richiesta &&
                                <div style={{color: 'red', fontSize: '14px'}}><b>CAMPO OBBLIGATORIO</b></div>
                                }
                                <AvInput type="hidden" name="richiesta" value={richiesta} validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'}
                                }}/>
                              </>
                            }
                          </div>
                        }
                        <Alert color="warning" className="mt-2">
                          <b>Compilare in tutte le sue parti il modulo della domanda scaricabile cliccando
                            <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${amministrazioneComunale.templateModuloRichiestaUrl}`, 'richiesta', richiestaContentType)}>QUI.</a></b>
                        </Alert>
                      </Col>
                    </Row>
                    <hr/>
                    <Row className="text-right">
                      <Col>
                        <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                          <FontAwesomeIcon icon="save"/>
                          &nbsp; Salva e invia richiesta
                        </Button>
                      </Col>
                    </Row>
                  </>
                  }
                </CardBody>
              </Card>
            </AvForm>
          </>
        );
      }
    }

    return(
      <Modal isOpen={showModal} modalTransition={{ timeout: 20 }} backdropTransition={{ timeout: 10 }} toggle={this.handleCloseModal} size="xl" backdrop={false} keyboard={false}>
        <ModalHeader toggle={this.handleCloseModal}>Compilazione richiesta di <b>RI-assegnazione</b></ModalHeader>
        <ModalBody>
          {setModalBodyContent()}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.handleCloseModal} disabled={this.somethingLoading()}>
            {this.somethingLoading() && <FontAwesomeIcon icon="spinner" spin />} Close
          </Button>
        </ModalFooter>
      </Modal>);
  }
}

const mapStateToProps = ( storeState: IRootState) => ({
  richiestaEntity: storeState.richiesta.entity,
  updating: storeState.richiesta.updating,
  updateSuccess: storeState.richiesta.updateSuccess,
  errorMessage: storeState.richiesta.errorMessage,
  errorEmailMessage: storeState.richiesta.errorEmailMessage,
  emailSending: storeState.richiesta.emailSending,
  emailSent: storeState.richiesta.emailSent
});

const mapDispatchToProps = {
  setEntityProperty,
  setBlob,
  createEntity,
  getAmministrazioneComunale,
  download,
  reset,
  sendEmail
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NuovaRichiestaModal);
