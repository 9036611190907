import axios from 'axios';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import fileDownload from 'js-file-download';

export const ACTION_TYPES = {
  DOWNLOAD_DOCUMENT: 'document/DOWNLOAD_DOCUMENT',
  FILE_DOWNLOAD: 'document/FILE_DOWNLOAD'
};

const initialState = {
  downloading: false,
  document: null,
  errorMessage: null
};

export type DocumentState = Readonly<typeof initialState>;

export default (state: DocumentState = initialState, action): DocumentState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.DOWNLOAD_DOCUMENT):
      return {
        ...state,
        errorMessage: null,
        downloading: true
      };
    case FAILURE(ACTION_TYPES.DOWNLOAD_DOCUMENT):
      return {
        ...state,
        downloading: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.DOWNLOAD_DOCUMENT):
      return {
        ...state,
        downloading: false,
        document: action.payload.data
      };
    default:
      return state;
  }
};

export const download: any = url => async dispatch => {
  const urlSplitted = url.split('.');
  const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
  const result = await dispatch({
    type: ACTION_TYPES.DOWNLOAD_DOCUMENT,
    payload: axios.get(url, { responseType: 'arraybuffer' })
  });

  return result;
};
