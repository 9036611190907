import './footer.scss';

import React from 'react';

import { Col, Row, Container } from 'reactstrap';
import {BrandIconWhite} from "app/shared/layout/header/header-components";

const Footer = props => (
  <div className="footer page-content">
    <div style={{marginLeft: "15px", marginRight: "15px"}}>
      <Row>
        <Col xs="6" style={{fontSize: '2.5rem', fontWeight: 'bold'}} className="text-left">
          <BrandIconWhite />
        </Col>
        <Col xs="6" className="text-right pt-3">
          <a href="https://www.iubenda.com/privacy-policy/40039993" className="iubenda-white iubenda-embed"
             title="Privacy Policy ">
            Privacy Policy
          </a>
        </Col>
        <Col xs="12">
          <a style={{color: "white", cursor: "pointer"}} href="http://www.weavesrl.com" target="_blank">www.weavesrl.com</a><br/>
          info@weavesrl.com<br/>
          weavesrl@messaggipec.it<br/>
        </Col>
      </Row>
    </div>
  </div>
);

export default Footer;
