import { Moment } from 'moment';
import { IUser } from 'app/shared/model/user.model';
import { ITransazione } from 'app/shared/model/transazione.model';
import { IAmministrazioneComunale } from 'app/shared/model/amministrazione-comunale.model';
import { IVoucher } from 'app/shared/model/voucher.model';
import { StatoRichiedente } from 'app/shared/model/enumerations/stato-richiedente.model';

export interface ICittadino {
  id?: number;
  codiceFiscale?: string;
  confermaCodiceFiscale?: string;
  telefono?: string;
  iban?: string;
  cellulare?: string;
  indirizzo?: string;
  codicePostale?: string;
  componentiNucleoFamiliare?: number;
  disabiliNucleoFamiliare?: number;
  anzianiNucleoFamiliare?: number;
  minoriNucleoFamiliare?: number;
  quarantenaNucleoFamiliare?: number;
  saldoDisponibile?: number;
  saldoDisponibileVouchers?: number;
  pin?: string;
  fotoContentType?: string;
  foto?: any;
  fotoUrl?: string;
  fotoFileName?: string;
  tesseraContentType?: string;
  tessera?: any;
  tesseraUrl?: string;
  tesseraFileName?: string;
  tesseraSize: number;
  richiestaContentType?: string;
  richiesta?: any;
  richiestaUrl?: string;
  richiestaFileName?: string;
  richiestaSize: number;
  iseContentType?: string;
  ise?: any;
  iseUrl?: string;
  iseFileName?: string;
  iseSize: number;
  stato?: StatoRichiedente;
  approvedDate?: Moment;
  user?: IUser;
  transaziones?: ITransazione[];
  comuneRiferimento?: IAmministrazioneComunale;
  vouchers?: IVoucher[];
  firstName?: string;
  lastName?: string;
  email?: string;
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
}

export const defaultValue: Readonly<ICittadino> = {};
