import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import Footer from "app/shared/layout/footer/footer";

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { account } = props;

  return (
    <>
      <Row style={{margin: "-1rem"}}>
        <Col xs="12" style={{height: "750px", paddingLeft: "0px", paddingRight: "0px"}}>
          <div id="first-section" className="home-section">
            <Row style={{height: "100%"}}>
              <Col md={{ size: 6}} sm={{ size: 12}} id="left">
                <div id="slogan-container">
                  <div id="slogan">
                    <div id="slogan-title">I buoni sono ancora più buoni dal principio</div>
                    <div id="slogan-subtitle">Sicuri, semplici, trasparenti...</div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
