import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Row, Col, Label, Card,
  CardHeader,
  CardBody,
  Collapse,
  Badge } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { setFileData, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import {
  getPublicEntity as getEntity,
  updatePublicEntity as updateEntity,
  setBlob,
  reset,
  setEntityProperty
} from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
import { download } from 'app/entities/document.reducer';
import {Editor} from "@tinymce/tinymce-react";
import fileDownload from "js-file-download";

export interface IAmministrazioneSettingsUpdateProps extends StateProps, DispatchProps {}

export interface IAmministrazioneSettingsUpdateState {
  changePassword: boolean;
  htmlContent: string;
}

export class AmministrazioneSettings extends React.Component<
  IAmministrazioneSettingsUpdateProps,
  IAmministrazioneSettingsUpdateState
  > {
  constructor(props) {
    super(props);
    this.state = {
      changePassword: false,
      htmlContent: ''
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
  }

  componentDidMount() {
     this.props.getEntity().then(response => {
       this.setState({
         htmlContent: response.value.data.htmlContent
       })
     });
  }

  onBlobChange = (isAnImage, name) => event => {
    const filename = event.target.files[0].name;
    const fileDataCallBackfunction = (contentType, data) => {
      this.props.setBlob(name, data, contentType, filename )
    };

    setFileData(event, fileDataCallBackfunction, isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined, undefined, undefined);
  };

  setEntityProperty = (propertyName, value)  => event => {
    this.props.setEntityProperty(propertyName, value);
  }

  handleEditorChange(htmlContent, editor) {
    this.setState({ htmlContent });
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { amministrazioneComunaleEntity } = this.props;
      const { htmlContent } = this.state;

      const responsabileServizio = {
        login: values.responsabileServizioEmail,
        firstName: values.responsabileServizioFirstName,
        lastName: values.responsabileServizioLastName,
        email: values.responsabileServizioEmail
      }

      if (values.responsabileServizioPassword) {
        responsabileServizio.password = values.responsabileServizioPassword;
      }
      if (values.responsabileServizioId) {
        responsabileServizio.id = values.responsabileServizioId;
      }

      const responsabiliServizios = [responsabileServizio];

      delete values.responsabileServizioFirstName;
      delete values.responsabileServizioLastName;
      delete values.responsabileServizioEmail;
      delete values.responsabileServizioPassword;
      delete values.responsabileServizioId;

      const entity = {
        ...amministrazioneComunaleEntity,
        ...values,
        htmlContent: htmlContent && htmlContent.length > 0 ? htmlContent : null,
        responsabiliServizios
      };

      if (!this.state.changePassword) {
        entity.responsabiliServizios[0].password = null
      }

      this.props.updateEntity(entity);
    }
  };

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  render() {
    const { amministrazioneComunaleEntity, loading, updating } = this.props;
    const { htmlContent } = this.state;

    const { logo, logoContentType, logoUrl, logoFileName } = amministrazioneComunaleEntity;
    const { templateModuloRichiesta, templateModuloRichiestaContentType, templateModuloRichiestaUrl, templateModuloRichiestaFileName } = amministrazioneComunaleEntity;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="12">
            <h2 id="buoniscontoApp.amministrazioneComunale.home.createOrEditLabel">Impostazioni</h2>
          </Col>
        </Row>
        <hr />
        <Row className="justify-content-center">
          <Col md="12">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={amministrazioneComunaleEntity} onSubmit={this.saveEntity}>
                <Row>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Coefficenti
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" id="coefficienteAnzianoLabel" for="amministrazione-comunale-coefficienteAnziano">
                              Coefficiente Anziano
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteAnziano"
                                type="number"
                                className="form-control"
                                name="coefficienteAnziano"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'},
                                  min: {value: 0, errorMessage: 'Questo campo non può essere minore di 0.'}
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" id="coefficienteDisabileLabel" for="amministrazione-comunale-coefficienteDisabile">
                              Coefficiente Disabile
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteDisabile"
                                type="number"
                                className="form-control"
                                name="coefficienteDisabile"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'},
                                  min: {value: 0, errorMessage: 'Questo campo non può essere minore di 0.'}
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" id="coefficienteMinoreLabel" for="amministrazione-comunale-coefficienteMinore">
                              Coefficiente Minore
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteMinore"
                                type="number"
                                className="form-control"
                                name="coefficienteMinore"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'},
                                  min: {value: 0, errorMessage: 'Questo campo non può essere minore di 0.'}
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="logoLabel" for="logo">
                              Logo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvGroup>
                                { logoUrl ?
                                  <div>
                                    <span>Logo.{logoUrl.split('.')[logoUrl.split('.').length - 1]}</span>
                                    <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${logoUrl}`, 'logo', logoContentType)}>(Download)</a>
                                    <a className="ml-1" onClick={this.setEntityProperty('logoUrl', '')}>(Remove)</a>
                                  </div>
                                  :
                                  <div>
                                    {logo ? (
                                      <div>
                                        <Row>
                                          <Col md="12">
                                          <span>
                                            {logoFileName}
                                          </span>
                                            <Badge color="danger" onClick={this.clearBlob('logo')} className="ml-2" style={{cursor: "pointer"}}>
                                              <FontAwesomeIcon icon="times-circle"/>
                                            </Badge>
                                          </Col>
                                        </Row>
                                      </div>
                                      )
                                      :
                                      <>
                                        <input id="file_logo" type="file" onChange={this.onBlobChange(false, 'logo')} />
                                        <AvInput type="hidden" name="logo" value={logo} />
                                      </>
                                    }
                                  </div>
                                }
                              </AvGroup>
                            </AvGroup>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="templateModuloRichiestaLabel" for="templateModuloRichiesta">
                              Modulo richiesta
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvGroup>
                                { templateModuloRichiestaUrl ?
                                  <div>
                                    <span>ModuloRichiesta.{templateModuloRichiestaUrl.split('.')[templateModuloRichiestaUrl.split('.').length - 1]}</span>
                                    <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${templateModuloRichiestaUrl}`, 'templateModuloRichiesta', templateModuloRichiestaContentType)}>(Download)</a>
                                    <a className="ml-1" onClick={this.setEntityProperty('templateModuloRichiestaUrl', '')}>(Remove)</a>
                                  </div>
                                  :
                                  <div>
                                    {templateModuloRichiesta ? (
                                        <div>
                                          <Row>
                                            <Col md="12">
                                                <span>
                                                  {templateModuloRichiestaFileName}
                                                </span>
                                              <Badge color="danger" onClick={this.clearBlob('templateModuloRichiesta')} className="ml-2" style={{cursor: "pointer"}}>
                                                <FontAwesomeIcon icon="times-circle"/>
                                              </Badge>
                                            </Col>
                                          </Row>
                                        </div>
                                      )
                                      :
                                      <>
                                        <input id="file_templateModuloRichiesta" type="file" onChange={this.onBlobChange(false, 'templateModuloRichiesta')} />
                                        <AvInput type="hidden" name="templateModuloRichiesta" value={templateModuloRichiesta} />
                                      </>
                                    }
                                  </div>
                                }
                              </AvGroup>
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni di contatto per i cittadini
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="contattoTelefonicoLabel" for="amministrazione-comunale-contatto-telefonico">
                              Contatto telefonico
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-contatto-telefonico" type="text" name="contattoTelefonico" />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioEmail">Contatto email</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                name="contattoEmail"
                                type="email"
                                id="contattoEmail"
                                validate={{
                                  email: {
                                    errorMessage: 'Email non valida'
                                  },
                                  minLength: {
                                    value: 5,
                                    errorMessage: 'L\'email deve essere lunga almeno 5 caratteri'
                                  },
                                  maxLength: {
                                    value: 254,
                                    errorMessage: 'L\'email non deve essere più lunga di 50 caratteri'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni Accesso Utente
                      </CardHeader>
                      <CardBody>
                          <AvInput type="text"
                                   className="form-control"
                                   name="responsabileServizioId"
                                   readOnly
                                   hidden
                                   value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].id : ''}/>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioFirstName">Accesso Utente (Nome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="responsabileServizioFirstName"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Nome obbligatorio'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Il nome non può essere più lungo di 50 caratteri'
                                  }
                                }}
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].firstName : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioLastName">Accesso Utente (Cognome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="responsabileServizioLastName"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Cognome obbligatorio'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Il cognome non può essere più lungo di 50 caratteri'
                                  }
                                }}
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].lastName : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioEmail">Accesso Utente (Email)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                name="responsabileServizioEmail"
                                type="email"
                                readOnly
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].email : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs="12">
                            <Button color="primary" size="sm" block onClick={() => {this.setState({changePassword: !this.state.changePassword})}} style={{marginBottom: '1rem'}}>
                              {this.state.changePassword ? "ANNULLA Cambia Password" : "Cambia Password"}
                            </Button>
                          </Col>
                        </Row>
                        <Collapse isOpen={this.state.changePassword}>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" for="responsabileServizioPassword">Password</Label>
                            </Col>
                            <Col md="9">
                              <AvField
                                name="responsabileServizioPassword"
                                type="password"
                                placeholder="Inserisci password"
                                autoComplete="new-password"
                                validate={{
                                  required: { value: this.state.changePassword, errorMessage: 'Password obbligatoria' },
                                  minLength: { value: 4, errorMessage: 'La password deve essere lunga almeno 4 caratteri' },
                                  maxLength: { value: 50, errorMessage: 'La password non deve essere più lunga di 50 caratteri' }
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" for="confirmPassword">Conferma password</Label>
                            </Col>
                            <Col md="9">
                              <AvField
                                name="confirmPassword"
                                placeholder="Inserisci conferma password"
                                type="password"
                                validate={{
                                  required: {
                                    value: this.state.changePassword,
                                    errorMessage: 'Conferma password è obbligatorio'
                                  },
                                  minLength: {
                                    value: 4,
                                    errorMessage: 'Inserire almeno 4 caratteri'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Lunghezza massima 50 caratteri'
                                  },
                                  match: {
                                    value: 'responsabileServizioPassword',
                                    errorMessage: 'Conferma password non corretta!'
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </Collapse>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="12">
                    <h5>
                      Contenuto visualizzato nella pagina principale del comune
                      <Button tag={Link} to={`/comune/`+amministrazioneComunaleEntity.stringId} size="sm" color="primary" className="ml-2">
                        Visualizza pagina principale del comune
                      </Button>
                    </h5>
                  </Col>
                  <Col xs="12">
                    <Editor
                      apiKey="lsad4v76pjd961rq403d3ubl1ynoqfdl2xpvx0uq8c9hsni5"
                      initialValue=""
                      init={{
                        height: 500,
                        menubar: true,
                        plugins: [
                          'advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                          'undo redo | formatselect | bold italic backcolor | \
                          alignleft aligncenter alignright alignjustify | \
                          bullist numlist outdent indent | removeformat | help'
                      }}
                      value={htmlContent}
                      onEditorChange={this.handleEditorChange}
                    />
                  </Col>
                </Row>
                <hr />
                <Row className="text-right">
                  <Col>
                    <Button tag={Link} id="cancel-save" to="/" replace color="info">
                      <FontAwesomeIcon icon="home" />
                      &nbsp;
                      <span className="d-none d-md-inline">Home</span>
                    </Button>
                    &nbsp;
                    <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                      <FontAwesomeIcon icon="save" />
                      &nbsp; Salva
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  amministrazioneComunaleEntity: storeState.amministrazioneComunale.entity,
  loading: storeState.amministrazioneComunale.loading,
  updating: storeState.amministrazioneComunale.updating,
  updateSuccess: storeState.amministrazioneComunale.updateSuccess,
  account: storeState.authentication.account,
  document: storeState.document
});

const mapDispatchToProps = {
  getEntity,
  setEntityProperty,
  updateEntity,
  setBlob,
  reset,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmministrazioneSettings);
