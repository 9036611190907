import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button,
  Spinner,
  Row,
  Table,
  Badge,
  Label} from 'reactstrap';
import {
  TextFormat,
  getSortState,
  IPaginationBaseState,
  JhiPagination,
  JhiItemCount
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { getEntitiesForCittadino as getEntities } from 'app/entities/transazione/transazione.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { download } from 'app/entities/document.reducer';
import {APP_DATE_FORMAT} from "app/config/constants";
import {TipologiaTransazioneView} from "app/modules/components/tipologia-transazione-view";
import fileDownload from "js-file-download";

export interface ITransazioneProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string; tipoTransazione?: string; categoriaEsercenteId?: string; esercenteId?: string}> {}

export interface ITransazioneState extends IPaginationBaseState {
}

export class Transazione extends React.Component<ITransazioneProps, ITransazioneState> {
  state: ITransazioneState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    //this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    const tipoTransazione = this.props.match.params.tipoTransazione;
    const categoriaEsercenteId = this.props.match.params.categoriaEsercenteId;
    const esercenteId = this.props.match.params.esercenteId;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, categoriaEsercenteId, esercenteId, tipoTransazione);
  };

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  render() {
    const { account, transazioneList, totalItems, loading } = this.props;
    const cittadinoEntity = account.cittadino;

    return (
      <div>
        <h2 id="esercente-heading">
          Elenco Transazioni
        </h2>
        <hr />
        {loading &&
          <div className="text-center">
            <h5>caricamento in corso...</h5>
            <Spinner color="dark" />
          </div>
        }
        {!loading &&
          <>
            <div className="table-responsive">
              {transazioneList && transazioneList.length > 0 ? (
                <Table responsive aria-describedby="transazione-heading" striped size="lg">
                  <thead>
                    <tr>
                      <th className="hand" onClick={this.sort('createdDate')}>
                        Data Creazione <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={this.sort('tipo')}>
                        Tipologia <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={this.sort('importo')}>
                        Importo <FontAwesomeIcon icon="sort" />
                      </th>
                      {cittadinoEntity && cittadinoEntity.comuneRiferimento && cittadinoEntity.comuneRiferimento.categorieAbilitate &&
                        <th className="hand" onClick={this.sort('categoriaEsercente')}>
                          Categoria Esercente <FontAwesomeIcon icon="sort" />
                        </th>
                      }
                      <th>Documento transazione</th>
                      <th className="hand" onClick={this.sort('esercente.ragioneSociale')}>
                        Esercente <FontAwesomeIcon icon="sort" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transazioneList.map((transazione, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          <TextFormat value={transazione.createdDate} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
                        </td>
                        <td><TipologiaTransazioneView tipo={transazione.tipo} /></td>
                        <td><Badge color="primary">{transazione.importo ? transazione.importo.toLocaleString() : 0 } €</Badge></td>
                        {cittadinoEntity && cittadinoEntity.comuneRiferimento && cittadinoEntity.comuneRiferimento.categorieAbilitate &&
                          <td>
                            {transazione.categoriaEsercente &&
                              <>
                                <FontAwesomeIcon icon="tags" fixedWidth/> {transazione.categoriaEsercente.denominazione}
                              </>
                            }
                          </td>
                        }
                        <td>
                          {transazione.scontrinoUrl ? (
                            <a onClick={this.onDownloadClick.bind(this,`/api/${transazione.scontrinoUrl}`, 'scontrino', transazione.scontrinoUrl)}>Download</a>
                          ) : null}
                        </td>
                        <td>
                          {transazione.esercente ?
                            <>
                              {transazione.esercente.ragioneSociale}
                            </>
                            :
                            ''}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="alert alert-warning">Nessuna transazione trovata</div>
              )}
            </div>
            <div className={transazioneList && transazioneList.length > 0 ? '' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} />
              </Row>
              <Row className="justify-content-center">
                <JhiPagination
                  activePage={this.state.activePage}
                  onSelect={this.handlePagination}
                  maxButtons={5}
                  itemsPerPage={this.state.itemsPerPage}
                  totalItems={this.props.totalItems}
                />
              </Row>
            </div>
          </>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ authentication, transazione, categoriaEsercente, document }: IRootState) => ({
  account: authentication.account,
  transazioneList: transazione.entities,
  categorieEsercenti: categoriaEsercente.entities,
  categorieEsercentiLoading: categoriaEsercente.loading,
  totalItems: transazione.totalItems,
  loading: transazione.loading,
  document: document
});

const mapDispatchToProps = {
  getEntities,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transazione);
