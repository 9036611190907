import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  Collapse
} from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { setFileData, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, setBlob, reset, setEntityProperty } from './amministrazione-comunale.reducer';
import {checkUserEmail} from "app/shared/util/entity-utils";
import { download } from 'app/entities/document.reducer';
import {StatoRochiedente} from "app/shared/model/enumerations/stato-richiedente";
import fileDownload from "js-file-download";

export interface IAmministrazioneComunaleUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IAmministrazioneComunaleUpdateState {
  isNew: boolean;
  changePassword: boolean;
}

export class AmministrazioneComunaleUpdate extends React.Component<
  IAmministrazioneComunaleUpdateProps,
  IAmministrazioneComunaleUpdateState
> {

  defaultAmministrazioneComunale = {
    coefficienteAnziano: 1,
    coefficienteDisabile: 1,
    coefficienteMinore: 1
  }

  constructor(props) {
    super(props);
    this.state = {
      isNew: !this.props.match.params || !this.props.match.params.id,
      changePassword: false
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }
  }

  onBlobChange = (isAnImage, name) => event => {
    const filename = event.target.files[0].name;
    const fileDataCallBackfunction = (contentType, data) => {
      this.props.setBlob(name, data, contentType, filename )
    };

    setFileData(event, fileDataCallBackfunction, isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined, undefined, undefined);
  };

  setEntityProperty = (propertyName, value)  => event => {
    this.props.setEntityProperty(propertyName, value);
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { amministrazioneComunaleEntity } = this.props;

      const responsabileServizio = {
        login: values.responsabileServizioEmail,
        firstName: values.responsabileServizioFirstName,
        lastName: values.responsabileServizioLastName,
        email: values.responsabileServizioEmail
      }

      if (values.responsabileServizioPassword) {
        responsabileServizio.password = values.responsabileServizioPassword;
      }
      if (values.responsabileServizioId) {
        responsabileServizio.id = values.responsabileServizioId;
      }

      const responsabiliServizios = [responsabileServizio];

      delete values.responsabileServizioFirstName;
      delete values.responsabileServizioLastName;
      delete values.responsabileServizioEmail;
      delete values.responsabileServizioPassword;
      delete values.responsabileServizioId;

      const entity = {
        ...amministrazioneComunaleEntity,
        ...values,
        responsabiliServizios
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        if (!this.state.changePassword) {
          entity.responsabiliServizios[0].password = null
        }
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/amministrazione-comunale');
  };

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  render() {
    const { amministrazioneComunaleEntity, loading, updating } = this.props;
    const { isNew } = this.state;

    const { logo, logoContentType, logoUrl, logoFileName } = amministrazioneComunaleEntity;
    const { templateModuloRichiesta, templateModuloRichiestaContentType, templateModuloRichiestaUrl, templateModuloRichiestaFileName } = amministrazioneComunaleEntity;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="12">
            <h2 id="buoniscontoApp.amministrazioneComunale.home.createOrEditLabel">{isNew ? 'Registra nuova amministrazione comunale' : 'Modifica amministrazione comunale'}</h2>
          </Col>
        </Row>
        <hr />
        <Row className="justify-content-center">
          <Col md="12">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? this.defaultAmministrazioneComunale : amministrazioneComunaleEntity} onSubmit={this.saveEntity}
                      ref={c => (this.form = c)}>
                <Row>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni generali
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="codiceMeccanograficoLabel" for="amministrazione-comunale-codiceMeccanografico" className="required">
                              Codice Meccanografico
                            </Label>
                          </Col>
                          <Col md="9">
                            {!isNew ?
                              <AvInput id="amministrazione-comunale-id" type="text" className="form-control" name="id" required readOnly hidden/>
                              :
                              ''
                            }
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-codiceMeccanografico"
                                type="text"
                                name="codiceMeccanografico"
                                validate={{
                                  required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="denominazioneLabel" for="amministrazione-comunale-denominazione">
                              Denominazione
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-denominazione" type="text" name="denominazione"
                                       validate={{
                                         required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                                       }}/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="indirizzoLabel" for="amministrazione-comunale-indirizzo">
                              Indirizzo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-indirizzo" type="text" name="indirizzo"
                                       validate={{
                                         required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                                       }}/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="codicePostaleLabel" for="amministrazione-comunale-codicePostale">
                              Codice Postale
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-codicePostale" type="text" name="codicePostale"
                                       validate={{
                                         required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                                       }}/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="comuneLabel" for="amministrazione-comunale-comune">
                              Comune
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-comune" type="text" name="comune"
                                       validate={{
                                         required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                                       }}/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="provinciaLabel" for="amministrazione-comunale-provincia">
                              Provincia
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-provincia" type="text" name="provincia"
                                       validate={{
                                         required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                                       }}/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="regioneLabel" for="amministrazione-comunale-regione">
                              Regione
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-regione" type="text" name="regione"
                                       validate={{
                                         required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                                       }}/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="popolazioneLabel" for="amministrazione-comunale-popolazione">
                              Popolazione
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvInput
                                id="amministrazione-comunale-popolazione"
                                type="select"
                                className="form-control"
                                name="popolazione"
                                value={(!isNew && amministrazioneComunaleEntity.popolazione) || 'P_0_5000'}
                              >
                                <option value="P_0_5000">P_0_5000</option>
                                <option value="P_5001_15000">P_5001_15000</option>
                                <option value="P_15001_100000">P_15001_100000</option>
                                <option value="P_100001">P_100001</option>
                              </AvInput>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right">
                            <Label id="formRegistrazioneCittadinoTemplateLabel" for="amministrazione-comunale-form-registrazione-cittadino-template">
                              Template per il form pubblico di registrazione del cittadino
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvGroup>
                              <AvInput
                                id="amministrazione-comunale-form-registrazione-cittadino-template"
                                type="select"
                                className="form-control"
                                name="formRegistrazioneCittadinoTemplate"
                                value={(!isNew && amministrazioneComunaleEntity.formRegistrazioneCittadinoTemplate) || 'TEMPLATE_1'}
                              >
                                <option value="TEMPLATE_1">TEMPLATE_1</option>
                                <option value="TEMPLATE_2">TEMPLATE_2</option>
                              </AvInput>
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="logoLabel" for="logo">
                              Logo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvGroup>
                                { logoUrl ?
                                  <div>
                                    <span>Logo.{logoUrl.split('.')[logoUrl.split('.').length - 1]}</span>
                                    <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${logoUrl}`, 'logo', logoContentType)}>(Download)</a>
                                    <a className="ml-1" onClick={this.setEntityProperty('logoUrl', '')}>(Remove)</a>
                                  </div>
                                  :
                                  <div>
                                    {logo ? (
                                        <div>
                                          <a onClick={openFile(logoContentType, logo)}>Open</a>
                                          <br />
                                          <Row>
                                            <Col md="11">
                                              <span>
                                                {logoFileName}
                                              </span>
                                            </Col>
                                            <Col md="1">
                                              <Button color="danger" onClick={this.clearBlob('logo')}>
                                                <FontAwesomeIcon icon="times-circle" />
                                              </Button>
                                            </Col>
                                          </Row>
                                        </div>
                                      )
                                      :
                                      <>
                                        <input id="file_logo" type="file" onChange={this.onBlobChange(false, 'logo')} />
                                        <AvInput type="hidden" name="logo" value={logo} />
                                      </>
                                    }
                                  </div>
                                }
                              </AvGroup>
                            </AvGroup>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="templateModuloRichiestaLabel" for="templateModuloRichiesta">
                              Modulo richiesta
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvGroup>
                                { templateModuloRichiestaUrl ?
                                  <div>
                                    <span>ModuloRichiesta.{templateModuloRichiestaUrl.split('.')[templateModuloRichiestaUrl.split('.').length - 1]}</span>
                                    <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${templateModuloRichiestaUrl}`, 'templateModuloRichiesta', templateModuloRichiestaContentType)}>(Download)</a>
                                    <a className="ml-1" onClick={this.setEntityProperty('templateModuloRichiestaUrl', '')}>(Remove)</a>
                                  </div>
                                  :
                                  <div>
                                    {templateModuloRichiesta ? (
                                        <div>
                                          <a onClick={openFile(templateModuloRichiestaContentType, templateModuloRichiesta)}>Open</a>
                                          <br />
                                          <Row>
                                            <Col md="11">
                                              <span>
                                                {templateModuloRichiestaFileName}
                                              </span>
                                            </Col>
                                            <Col md="1">
                                              <Button color="danger" onClick={this.clearBlob('templateModuloRichiesta')}>
                                                <FontAwesomeIcon icon="times-circle" />
                                              </Button>
                                            </Col>
                                          </Row>
                                        </div>
                                      )
                                      :
                                      <>
                                        <input id="file_templateModuloRichiesta" type="file" onChange={this.onBlobChange(false, 'templateModuloRichiesta')} />
                                        <AvInput type="hidden" name="templateModuloRichiesta" value={templateModuloRichiesta} />
                                      </>
                                    }
                                  </div>
                                }
                              </AvGroup>
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Coefficenti
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="7" className="text-right">
                            <Label className="required" id="coefficienteAnzianoLabel" for="amministrazione-comunale-coefficienteAnziano">
                              Coefficiente Anziano
                            </Label>
                          </Col>
                          <Col md="5">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteAnziano"
                                type="number"
                                className="form-control"
                                name="coefficienteAnziano"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'},
                                  min: {value: 0, errorMessage: 'Questo campo non può essere minore di 0.'}
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="7" className="text-right">
                            <Label className="required" id="coefficienteDisabileLabel" for="amministrazione-comunale-coefficienteDisabile">
                              Coefficiente Disabile
                            </Label>
                          </Col>
                          <Col md="5">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteDisabile"
                                type="number"
                                className="form-control"
                                name="coefficienteDisabile"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'},
                                  min: {value: 0, errorMessage: 'Questo campo non può essere minore di 0.'}
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="7" className="text-right">
                            <Label className="required" id="coefficienteMinoreLabel" for="amministrazione-comunale-coefficienteMinore">
                              Coefficiente Minore
                            </Label>
                          </Col>
                          <Col md="5">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteMinore"
                                type="number"
                                className="form-control"
                                name="coefficienteMinore"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'},
                                  min: {value: 0, errorMessage: 'Questo campo non può essere minore di 0.'}
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni di contatto per i cittadini
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="contattoTelefonicoLabel" for="amministrazione-comunale-contatto-telefonico">
                              Contatto telefonico
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-contatto-telefonico" type="text" name="contattoTelefonico" />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioEmail">Contatto email</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                name="contattoEmail"
                                type="email"
                                id="contattoEmail"
                                validate={{
                                  email: {
                                    errorMessage: 'Email non valida'
                                  },
                                  minLength: {
                                    value: 5,
                                    errorMessage: 'L\'email deve essere lunga almeno 5 caratteri'
                                  },
                                  maxLength: {
                                    value: 254,
                                    errorMessage: 'L\'email non deve essere più lunga di 50 caratteri'
                                  }
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni Accesso Utente
                      </CardHeader>
                      <CardBody>
                        <AvInput type="text"
                                 className="form-control"
                                 name="responsabileServizioId"
                                 readOnly
                                 hidden
                                 value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].id : ''}/>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" for="responsabileServizioFirstName">Accesso Utente (Nome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="responsabileServizioFirstName"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Nome obbligatorio'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Il nome non può essere più lungo di 50 caratteri'
                                  }
                                }}
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].firstName : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" for="responsabileServizioLastName">Accesso Utente (Cognome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="responsabileServizioLastName"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Cognome obbligatorio'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Il cognome non può essere più lungo di 50 caratteri'
                                  }
                                }}
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].lastName : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label className="required" for="responsabileServizioEmail">Accesso Utente (Email)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                name="responsabileServizioEmail"
                                type="email"
                                id="responsabileServizioEmail"
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].email : ''}
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Email obbligatoria'
                                  },
                                  email: {
                                    errorMessage: 'Email non valida'
                                  },
                                  minLength: {
                                    value: 5,
                                    errorMessage: 'L\'email deve essere lunga almeno 5 caratteri'
                                  },
                                  maxLength: {
                                    value: 254,
                                    errorMessage: 'L\'email non deve essere più lunga di 50 caratteri'
                                  },
                                  async: checkUserEmail(amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].email : '')
                                }}
                              />
                            </AvGroup>
                          </Col>
                        </Row>

                        {!isNew &&
                          <Row>
                            <Col xs="12">
                              <Button color="primary" size="sm" block onClick={() => {this.setState({changePassword: !this.state.changePassword})}} style={{marginBottom: '1rem'}}>
                                {this.state.changePassword ? "ANNULLA Cambia Password" : "Cambia Password"}
                              </Button>
                            </Col>
                          </Row>
                        }
                        <Collapse isOpen={this.state.changePassword || isNew}>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" for="responsabileServizioPassword">Password</Label>
                            </Col>
                            <Col md="9">
                              <AvField
                                name="responsabileServizioPassword"
                                type="password"
                                placeholder="Inserisci password"
                                autoComplete="new-password"
                                validate={{
                                  required: { value: (isNew || this.state.changePassword), errorMessage: 'Password obbligatoria' },
                                  minLength: { value: 4, errorMessage: 'La password deve essere lunga almeno 4 caratteri' },
                                  maxLength: { value: 50, errorMessage: 'La password non deve essere più lunga di 50 caratteri' }
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" for="confirmPassword">Conferma password</Label>
                            </Col>
                            <Col md="9">
                              <AvField
                                name="confirmPassword"
                                placeholder="Inserisci conferma password"
                                type="password"
                                validate={{
                                  required: {
                                    value: (isNew || this.state.changePassword),
                                    errorMessage: 'Conferma password è obbligatorio'
                                  },
                                  minLength: {
                                    value: 4,
                                    errorMessage: 'Inserire almeno 4 caratteri'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Lunghezza massima 50 caratteri'
                                  },
                                  match: {
                                    value: 'responsabileServizioPassword',
                                    errorMessage: 'Conferma password non corretta!'
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </Collapse>

                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <hr/>
                <Row className="text-right">
                  <Col>
                    <Button tag={Link} id="cancel-save" to="/amministrazione-comunale" replace color="info">
                      <FontAwesomeIcon icon="arrow-left"/>
                      &nbsp;
                      <span className="d-none d-md-inline">Elenco amministrazioni comunali</span>
                    </Button>
                    &nbsp;
                    <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                      <FontAwesomeIcon icon="save"/>
                      &nbsp; Salva
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  amministrazioneComunaleEntity: storeState.amministrazioneComunale.entity,
  loading: storeState.amministrazioneComunale.loading,
  updating: storeState.amministrazioneComunale.updating,
  updateSuccess: storeState.amministrazioneComunale.updateSuccess,
  document: storeState.document
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setEntityProperty,
  setBlob,
  createEntity,
  reset,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmministrazioneComunaleUpdate);
