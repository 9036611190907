import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Cittadino from './cittadino';
import Transazione from "./transazione";

const Routes = ({ match }) => {
  return(
    <div>
      <Switch>
        {/* prettier-ignore */}
        {/*<ErrorBoundaryRoute path={`${match.url}/cittadino`} component={Cittadino} />*/}
        <ErrorBoundaryRoute path={`${match.url}/transazione`} component={Transazione} />
      </Switch>
    </div>
  );
};

export default Routes;
