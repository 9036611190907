import React from 'react';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import AmministrazioneSettings from './settings/amministrazione-settings';

const Routes = ({ match }) => (
  <div>
    <ErrorBoundaryRoute exact path={`${match.url}/settings`} component={AmministrazioneSettings} />
  </div>
);

export default Routes;
