import { IAmministrazioneComunale } from 'app/shared/model/amministrazione-comunale.model';
import { ICittadino } from 'app/shared/model/cittadino.model';
import { ICategoriaEsercente } from 'app/shared/model/categoria-esercente.model';
import { IEsercente } from 'app/shared/model/esercente.model';
import { TipologiaTransazione } from 'app/shared/model/enumerations/tipologia-transazione.model';
import { StatoTransazione } from 'app/shared/model/enumerations/stato-transazione.model';

export interface ITransazione {
  id?: number;
  uuid?: string;
  tipo?: TipologiaTransazione;
  importo?: number;
  stato?: StatoTransazione;
  scontrinoContentType?: string;
  scontrino?: any;
  scontrinoUrl?: string;
  comuneRiferimento?: IAmministrazioneComunale;
  cittadino?: ICittadino;
  categoriaEsercente?: ICategoriaEsercente;
  esercente?: IEsercente;
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
}

export const defaultValue: Readonly<ITransazione> = {};
