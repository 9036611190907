import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, Redirect } from 'react-router-dom';
import { Button,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Alert, Form, FormGroup, FormFeedback, Spinner, UncontrolledAlert
} from 'reactstrap';
import { AvFeedback, AvGroup, AvField, AvForm } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getPublicEntities as getCategorieEsercenti } from 'app/entities/categoria-esercente/categoria-esercente.reducer';
import { getPublicEsercenti, getPublicEsercentiVoucher } from 'app/entities/esercente/esercente.reducer';
import { approveCittadino, denyCittadino, reopenCittadino, getPublicEntity as getEntity, getPublicSaldo, getPublicSaldoVoucher, reset } from 'app/entities/cittadino/cittadino.reducer';

import { IRootState } from 'app/shared/reducers';
import {creaAccreditoDiretto, creaAssegnazione} from "app/entities/transazione/transazione.reducer";
import { StatoRochiedente } from "app/shared/model/enumerations/stato-richiedente";
import { download } from 'app/entities/document.reducer';
import {TipologiaTransazione} from "app/shared/model/enumerations/tipologia-transazione.model";
import fileDownload from "js-file-download";

export interface ICittadinoDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICittadinoDetailState {
  comuneRiferimentoId: string;
  showModal: boolean;
  showApproveModal: boolean;
  showDenyModal: boolean;
  showReopenModal: boolean;
  importo: number;
  voucherAssegnati: any;
  importoTextDisabled: boolean;
  importoButtonDisabled: boolean;
  categoriaEsercente: any;
  tipologiaTransazione?: TipologiaTransazione;
  esercenteTransazione?: number;
}

export class CittadinoDetail extends React.Component<ICittadinoDetailProps, ICittadinoDetailState> {
  constructor(props) {
    super(props);
    this.state = {
      comuneRiferimentoId: '0',
      showModal: false,
      showApproveModal: false,
      showDenyModal: false,
      showReopenModal: false,
      importo: null,
      voucherAssegnati: {},
      importoTextDisabled: true,
      importoButtonDisabled: true,
      categoriaEsercente: null,
      tipologiaTransazione: null
    };

    this.creaTransazione = this.creaTransazione.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
  }

  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
    this.props.getPublicSaldo(this.props.match.params.id);
    if (this.props.account && this.props.account.amministrazioneComunale && this.props.account.amministrazioneComunale.voucherAbilitati) {
      this.props.getPublicSaldoVoucher(this.props.match.params.id);
    }
  }

  componentDidUpdate(propsPrecedenti) {
    if (this.props.transazioneUpdateSuccess !== propsPrecedenti.transazioneUpdateSuccess && this.props.transazioneUpdateSuccess === true) {
      this.handleCloseModal();
      this.setState({importo: null});
    }
  }

  creaTransazione(tipologiaTransazione, event) {
    if (this.state.importo && !isNaN(this.state.importo) && this.state.importo !== 0) {
      const transazioneEntity = {
        importo: this.state.importo,
        cittadinoId: this.props.match.params.id
      }

      if (this.props.account.amministrazioneComunale.categorieAbilitate && tipologiaTransazione === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO) {
        transazioneEntity.categoriaEsercente = {
          id: this.state.categoriaEsercente
        }
      }
      if (this.props.account.amministrazioneComunale.voucherAbilitati && tipologiaTransazione === TipologiaTransazione.TT_08_ASSEGNAZIONE) {
        transazioneEntity.esercenteId = this.state.esercenteTransazione
      }

      if (tipologiaTransazione === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO) {
        this.props.creaAccreditoDiretto(transazioneEntity).then(respone => {
          if (this.props.account && this.props.account.amministrazioneComunale && this.props.account.amministrazioneComunale.categorieAbilitate) {
            this.setState({categoriaEsercente: this.props.categorieEsercenti[0].id}, () => {
              this.props.getEntity(this.props.match.params.id);
              this.props.getPublicSaldo(this.props.match.params.id);
            });
          } else {
            this.props.getEntity(this.props.match.params.id);
            this.props.getPublicSaldo(this.props.match.params.id);
          }
        });
      }

      if (tipologiaTransazione === TipologiaTransazione.TT_08_ASSEGNAZIONE) {
        this.props.creaAssegnazione(transazioneEntity).then(respone => {
          if (this.props.account && this.props.account.amministrazioneComunale && this.props.account.amministrazioneComunale.categorieAbilitate) {
            this.setState({categoriaEsercente: this.props.categorieEsercenti[0].id}, () => {
              this.props.getEntity(this.props.match.params.id);
              this.props.getPublicSaldo(this.props.match.params.id);
              this.props.getPublicSaldoVoucher(this.props.match.params.id);
            });
          } else {
            this.props.getEntity(this.props.match.params.id);
            this.props.getPublicSaldo(this.props.match.params.id);
            this.props.getPublicSaldoVoucher(this.props.match.params.id);
          }
        });
      }
    }
  };

  handleChange = event => {
    this.setState({importo: event.target.value});
  };


  handleChangeCategoria = event => {
    this.setState({categoriaEsercente: event.target.value});
  };

  handleChangeEsercenteTransazione = event => {
    this.setState({esercenteTransazione: event.target.value});
  };

  handleCloseModal() {
    this.setState({
      showModal: false,
      tipologiaTransazione: null
    });
  };

  handleOpenModal(tipologiaTransazione = null){
    var _that = this;
    this.setState({
      showModal: true,
      importo: null,
      tipologiaTransazione
    });

    if (tipologiaTransazione && tipologiaTransazione === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO ) {
      if (this.props.account && this.props.account.amministrazioneComunale && this.props.account.amministrazioneComunale.categorieAbilitate) {
        this.props.getCategorieEsercenti().then(response => {
          this.setState({categoriaEsercente: this.props.categorieEsercenti[0].id});
        });
      }
    }

    if (tipologiaTransazione && tipologiaTransazione === TipologiaTransazione.TT_08_ASSEGNAZIONE ) {
      _that.props.getPublicEsercentiVoucher().then( resp => {
          if (_that.props.esercentiVoucherMancanti.length > 0) {
            _that.setState({esercenteTransazione: _that.props.esercentiVoucherMancanti[0].esercenteId})
          }
      });
    }
  };

  handleOpenApproveModal = () => {
    this.setState({ showApproveModal: true });
  };

  handleOpenDenyModal = () => {
    this.setState({ showDenyModal: true });
  };

  handleOpenReopenModal = () => {
    this.setState({ showReopenModal: true });
  };

  confirmApprove = event => {
    this.props.approveCittadino(this.props.cittadinoEntity.id).then(result => {
      this.props.history.push("/amministrazione/cittadino");
    });
  };

  confirmDeny = event => {
    this.props.denyCittadino(this.props.cittadinoEntity.id).then(result => {
      this.props.history.push("/amministrazione/richiesta");
    });
  };

  confirmReopen = event => {
    this.props.reopenCittadino(this.props.cittadinoEntity.id).then(result => {
      this.handleCloseReopenModal(null);
      this.props.getEntity(this.props.match.params.id);
    });
  };

  handleCloseApproveModal = event => {
    this.setState({ showApproveModal: false });
  };

  handleCloseDenyModal = event => {
    this.setState({ showDenyModal: false });
  };

  handleCloseReopenModal = event => {
    this.setState({ showReopenModal: false });
  };

  componentWillUnmount() {
    this.props.reset();
  }

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  render() {
    const { account,
      saldo,
      saldoVoucher,
      loadingSaldo,
      loadingSaldoVoucher,
      categorieEsercenti,
      cittadinoEntity,
      loading,
      updating,
      transazioneUpdating,
      transazioneUpdateSuccess,
      match,
      esercentiVoucherMancanti,
      esercentiLoading
    } = this.props;
    const { showModal,
      showApproveModal,
      showDenyModal,
      showReopenModal,
      importo,
      tipologiaTransazione,
      esercenteTransazione
    } = this.state;

    const esercenteId = esercenteTransazione && parseInt(esercenteTransazione);
    const esercenteSelezionato = esercentiVoucherMancanti && esercenteId && esercentiVoucherMancanti.find(el => el.esercenteId === esercenteId);

    const accreditoDirettoValid = importo && !isNaN(importo) && importo !== 0 ? true : false;
    const accreditoVoucherValid = importo && !isNaN(importo) && importo !== 0 && esercenteSelezionato && esercenteSelezionato.importo >= importo ? true : false;
    const transazioneValid = tipologiaTransazione && ((tipologiaTransazione === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO && accreditoDirettoValid) || (tipologiaTransazione !== TipologiaTransazione.TT_05_ACCREDITO_DIRETTO && accreditoVoucherValid))

    if (cittadinoEntity.stato && cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA && match.path === '/amministrazione/richiesta/:id')
      return <Redirect to={'/amministrazione/cittadino/' + this.props.match.params.id} />
    else if (cittadinoEntity.stato && cittadinoEntity.stato !== StatoRochiedente.RICHIESTA_APPROVATA && match.path === '/amministrazione/cittadino/:id')
      return <Redirect to={'/amministrazione/richiesta/' + this.props.match.params.id} />
    else {
      return (
        <div>
          <Row className="justify-content-center">
            <Col md="12">
              <h2>Dettagli {cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ? 'cittadino' : 'richiesta'}</h2>
            </Col>
          </Row>
          <hr />
          <Row className="justify-content-center">
            <Col md="12">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <AvForm model={cittadinoEntity}>
                  {transazioneUpdateSuccess &&
                  <Row>
                    <Col>
                      <UncontrolledAlert color="success">
                        Transazione salvata con successo.
                      </UncontrolledAlert>
                    </Col>
                  </Row>
                  }
                  <Row>
                    <Col md="6">
                      <Card>
                        <CardHeader>
                          Informazioni cittadino
                        </CardHeader>
                        <CardBody>
                          <Row className="align-items-center">
                            <Col md="3" className="text-right">
                              <Label for="cittadino-firstName">Nome</Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  name="firstName"
                                  id="cittadino-firstName"
                                  readOnly
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label for="cittadino-lastName">Cognome</Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  name="lastName"
                                  id="cittadino-lastName"
                                  readOnly
                                />
                                <AvFeedback>This field cannot be longer than 50 characters.</AvFeedback>
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label id="codiceFiscaleLabel" for="cittadino-codiceFiscale">
                                Codice Fiscale
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  id="cittadino-codiceFiscale"
                                  type="text"
                                  name="codiceFiscale"
                                  readOnly
                                />
                              </AvGroup>
                            </Col>
                          </Row>



                         <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label id="codiceFiscaleGenitore1Label" for="cittadino-codiceFiscaleGenitore1">
                                Codice Fiscale Genitore 1
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  id="cittadino-codiceFiscaleGenitore1"
                                  type="text"
                                  name="codiceFiscaleGenitore1"
                                  readOnly
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                        <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label id="codiceFiscaleGenitore2Label" for="cittadino-codiceFiscaleGenitore2">
                                Codice Fiscale Genitore 2
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  id="cittadino-codiceFiscaleGenitore2"
                                  type="text"
                                  name="codiceFiscaleGenitore2"
                                  readOnly
                                />
                              </AvGroup>
                            </Col>
                          </Row>


                          <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label id="cellulareLabel" for="cittadino-cellulare">
                                Cellulare
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField id="cittadino-cellulare" type="text" name="cellulare" readOnly/>
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label for="cittadino-email">Email</Label>
                            </Col>
                            <Col md="9">
                              <AvField
                                name="email"
                                id="cittadino-email"
                                type="email"
                                readOnly
                              />
                            </Col>
                          </Row>


                          <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label id="telefonoLabel" for="cittadino-telefono">
                                Telefono
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  id="cittadino-telefono"
                                  type="text"
                                  name="telefono"
                                  readOnly
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label id="ibanLabel" for="cittadino-iban">
                                IBAN
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  id="cittadino-iban"
                                  type="text"
                                  name="iban"
                                  readOnly
                                />
                              </AvGroup>
                            </Col>
                          </Row>

                          <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label id="indirizzoLabel" for="cittadino-indirizzo">
                                Indirizzo
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField id="cittadino-indirizzo" type="text" name="indirizzo" readOnly/>
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row className="align-items-center" >
                            <Col md="3" className="text-right">
                              <Label id="codicePostaleLabel" for="cittadino-codicePostale">
                                Codice Postale
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField id="cittadino-codicePostale" type="text" name="codicePostale" readOnly/>
                              </AvGroup>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="mt-3">
                        <CardBody>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label id="tesseraLabel" for="tessera">
                                Documento d'Identità
                              </Label>
                            </Col>
                            <Col md="9">
                              {cittadinoEntity.tesseraUrl ? (
                                <>
                                  <span>DocumentoIdentità.{cittadinoEntity.tesseraUrl.split('.')[cittadinoEntity.tesseraUrl.split('.').length - 1]}</span>
                                  <a className="ml-1" onClick={this.onDownloadClick.bind(this, `/api/${cittadinoEntity.tesseraUrl}`, 'tessera', cittadinoEntity.tesseraContentType)}>(Download)</a>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                          <hr/>
                          <Row className="pt-2">
                            <Col md="3" className="text-right">
                              <Label id="iseLabel" for="ise">
                                ISE
                              </Label>
                            </Col>
                            <Col md="9">
                              {cittadinoEntity.iseUrl ? (
                                <>
                                  <span>ISE.{cittadinoEntity.iseUrl.split('.')[cittadinoEntity.iseUrl.split('.').length - 1]}</span>
                                  <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${cittadinoEntity.iseUrl}`, 'ise', cittadinoEntity.iseContentType)}>(Download)</a>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                          <hr/>
                          <Row className="pt-2">
                            <Col md="3" className="text-right">
                              <Label id="domandaLabel" for="domanda">
                                Domanda (doc/pdf)
                              </Label>
                            </Col>
                            <Col md="9">
                              {cittadinoEntity.richiestaUrl ? (
                                <>
                                  <span>Domanda.{cittadinoEntity.richiestaUrl.split('.')[cittadinoEntity.richiestaUrl.split('.').length - 1]}</span>
                                  <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${cittadinoEntity.richiestaUrl}`, 'richiesta', cittadinoEntity.richiestaContentType)}>(Download)</a>
                                </>
                              ) : null}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card>
                        <CardHeader>
                          Informazioni Nucleo Familiare del cittadino
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="7" className="text-right pt-1">
                              <Label id="componentiNucleoFamiliareLabel" for="cittadino-componentiNucleoFamiliare">
                                N° componenti del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-componentiNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="componentiNucleoFamiliare"
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="7" className="text-right pt-1">
                              <Label id="disabiliNucleoFamiliareLabel" for="cittadino-disabiliNucleoFamiliare">
                                N° componenti disabili del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-disabiliNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="disabiliNucleoFamiliare"
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="7" className="text-right pt-1">
                              <Label id="anzianiNucleoFamiliareLabel" for="cittadino-anzianiNucleoFamiliare">
                                N° componenti anziani del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-anzianiNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="anzianiNucleoFamiliare"
                                readOnly
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="7" className="text-right pt-1">
                              <Label id="minoriNucleoFamiliareLabel" for="cittadino-minoriNucleoFamiliare">
                                N° componenti minori del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-minoriNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="minoriNucleoFamiliare"
                                readOnly
                              />
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            <Col xs="12">
                              <Alert color="danger">
                                <FontAwesomeIcon icon="exclamation-triangle" /> Sezione dedicata all'emergenza COVID-19
                              </Alert>
                            </Col>
                            <Col md="7" className="text-right pt-1">
                              <Label id="quarantenaNucleoFamiliareLabel" for="cittadino-quarantenaNucleoFamiliare">
                                N° componenti in qurantena del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-quarantenaNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="quarantenaNucleoFamiliare"
                                readOnly
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card className="mt-3">
                        <CardBody>
                          {cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ?
                            <>
                              <Row className="align-items-center">
                                <Col md="6" className="text-right d-inline">
                                  <Label className="mb-0" style={{verticalAlign: 'middle'}}>
                                    Saldo Disponibile:
                                  </Label>
                                  <Label className="ml-2 mb-0" style={{verticalAlign: 'middle'}}>
                                    <strong className="h3">{cittadinoEntity.saldoDisponibile ? cittadinoEntity.saldoDisponibile.toLocaleString() : 0} €</strong>
                                  </Label>
                                </Col>
                                <Col md="6">
                                  <Button onClick={this.handleOpenModal.bind(this, TipologiaTransazione.TT_05_ACCREDITO_DIRETTO)} color="success" block>
                                    <FontAwesomeIcon icon="plus" /> aggiungi buono
                                  </Button>
                                </Col>
                              </Row>
                              {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                                <Row className="align-items-center pt-4">
                                  {loadingSaldo ?
                                    <Col xs="12" className="text-center">
                                      <Spinner color="dark" />
                                    </Col>
                                  :
                                    <Col md="12">
                                      <Table striped responsive size="sm">
                                        <thead>
                                        <tr>
                                          <th>
                                            Categoria
                                          </th>
                                          <th>
                                            Saldo
                                          </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {saldo && saldo.map((s, i) => (
                                          <tr key={`saldo-${i}`}>
                                            <td>{s.categoriaEsercente && s.categoriaEsercente.denominazione}</td>
                                            <td>{s.saldo.toLocaleString()} €</td>
                                          </tr>
                                        ))}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  }

                                </Row>
                              }
                              {account && account.amministrazioneComunale && account.amministrazioneComunale.voucherAbilitati &&
                                <>
                                  <Row className="align-items-center pt-3">
                                    <Col md="6" className="text-right d-inline">
                                      <Label className="mb-0" style={{verticalAlign: 'middle'}}>
                                        Saldo Voucher Disponibile:
                                      </Label>
                                      <Label className="ml-2 mb-0" style={{verticalAlign: 'middle'}}>
                                        <strong className="h3">{cittadinoEntity.saldoDisponibileVouchers ? cittadinoEntity.saldoDisponibileVouchers.toLocaleString() : 0} €</strong>
                                      </Label>
                                    </Col>
                                    <Col md="6">
                                      <Button onClick={this.handleOpenModal.bind(this, TipologiaTransazione.TT_08_ASSEGNAZIONE)} color="success" block>
                                        <FontAwesomeIcon icon="plus" /> aggiungi voucher
                                      </Button>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-center pt-4">
                                    {loadingSaldoVoucher ?
                                      <Col xs="12" className="text-center">
                                        <Spinner color="dark" />
                                      </Col>
                                      :
                                      <Col md="12">
                                        <Table striped responsive size="sm">
                                          <thead>
                                          <tr>
                                            <th>
                                              Esercente
                                            </th>
                                            <th>
                                              Saldo
                                            </th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          {saldoVoucher && saldoVoucher.map((s, i) => (
                                            <tr key={`saldo-${i}`}>
                                              <td>{s.esercente && s.esercente.ragioneSociale}</td>
                                              <td>{s.saldo.toLocaleString()} €</td>
                                            </tr>
                                          ))}
                                          </tbody>
                                        </Table>
                                      </Col>
                                    }

                                  </Row>
                                </>
                              }
                            </>
                            :
                            <>
                              { cittadinoEntity.stato === StatoRochiedente.RICHIESTA_PENDING ?
                                <>
                                  <Row className="align-items-center">
                                    <Col md="12">
                                      <Button onClick={() => {
                                        if (account && account.amministrazioneComunale &&
                                          (!account.amministrazioneComunale.categorieAbilitate && !account.amministrazioneComunale.voucherAbilitati)
                                        ) {
                                          this.handleOpenModal(TipologiaTransazione.TT_05_ACCREDITO_DIRETTO);
                                        } else {
                                          this.handleOpenApproveModal();
                                        }

                                      }} color="success" block>
                                        <FontAwesomeIcon icon="check-circle"/> <b>APPROVA RICHIESTA</b>
                                        {account && account.amministrazioneComunale &&
                                          (!account.amministrazioneComunale.categorieAbilitate && !account.amministrazioneComunale.voucherAbilitati) &&
                                            'E SELEZIONA L\'IMPORTO DA ASSEGNARE AL CITTADINO'
                                        }
                                      </Button>
                                    </Col>
                                  </Row>
                                  <hr/>
                                  <Row>
                                    <Col md="12">
                                      <Button onClick={() => this.handleOpenDenyModal()} color="danger" block>
                                        <FontAwesomeIcon icon="ban"/> <b>RIFIUTA RICHIESTA</b>
                                      </Button>
                                    </Col>
                                  </Row>
                                </>
                                :
                                <>
                                  {cittadinoEntity.stato === StatoRochiedente.RICHIESTA_BOCCIATA &&
                                    <Row>
                                      <Col md="12">
                                        <Button onClick={() => this.handleOpenReopenModal()} color="primary" block>
                                          <FontAwesomeIcon icon="unlock"/> <b>RIAPRI RICHIESTA</b>
                                        </Button>
                                      </Col>
                                    </Row>
                                  }
                                </>
                              }
                            </>
                          }
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="align-items-center text-right">
                    <Col>
                      <Button tag={Link} id="cancel-save" to={cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ? '/amministrazione/cittadino' : '/amministrazione/richiesta'} replace color="info">
                        <FontAwesomeIcon icon="arrow-left" />
                        &nbsp;
                        <span className="d-none d-md-inline">Elenco {cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ? 'cittadini' : 'richieste'}</span>
                      </Button>
                      &nbsp;
                      <Button tag={Link} to={`/amministrazione/${cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ? 'cittadino' : 'richiesta'}/${cittadinoEntity.id}/edit`} replace color="primary">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Modifica {cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ? 'cittadino' : 'richiesta'}</span>
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              )}
            </Col>
          </Row>

          {/* ACCREDITO DIRETTO / ASSEGNA VOUCHER MODAL */}
          <Modal isOpen={showModal} toggle={this.handleCloseModal} className="modal-lg">
            <ModalHeader toggle={this.handleCloseModal}>{tipologiaTransazione &&
              (tipologiaTransazione === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO ?
                  'Aggiungi saldo'
                  :
                  'Assegna vouchers'
              )}
            </ModalHeader>
            <ModalBody>
              {transazioneUpdating &&
                <div className="text-center">
                  <h5>salvataggio transazione in corso...</h5>
                  <Spinner color="dark" />
                </div>
              }
              {!transazioneUpdating &&
                <>
                  {<p>Il saldo corrente è: <strong>€ {cittadinoEntity && (cittadinoEntity.saldoDisponibile || cittadinoEntity.saldoDisponibileVouchers)
                    ? (tipologiaTransazione === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO) ?
                      cittadinoEntity.saldoDisponibile.toLocaleString()
                      :
                      cittadinoEntity.saldoDisponibileVouchers.toLocaleString()
                    :
                    0
                  }
                  </strong>.
                  </p>
                  }
                  <p>Indica di seguito l'importo da aggiungere al saldo corrente:</p>
                  <Form>
                    {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                    tipologiaTransazione && tipologiaTransazione === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO &&
                      <Row>
                        <Col md="3" className="pt-1 pb-2">
                          <Label id="categoriaEsercenteLabel" for="categoriaEsercente" className="required">
                            Categoria Esercente
                          </Label>
                        </Col>
                        <Col md="9" className="pt-1 pb-2">
                          <Input type="select" name="categoriaEsercente" id="categoriaEsercente" onChange={this.handleChangeCategoria}>
                            {categorieEsercenti ?
                              categorieEsercenti.map(otherEntity => (
                                  <option value={otherEntity.id} key={otherEntity.id}>
                                    {otherEntity.denominazione}
                                  </option>
                                ))
                              :
                              null
                            }
                          </Input>
                        </Col>
                      </Row>
                    }
                    {account && account.amministrazioneComunale && account.amministrazioneComunale.voucherAbilitati &&
                    tipologiaTransazione && tipologiaTransazione !== TipologiaTransazione.TT_05_ACCREDITO_DIRETTO &&
                      <Row>
                        <Col md="3" className="pt-1 pb-2">
                          <Label id="esercenteTransazioneLabel" for="esercenteTransazione" className="required">
                            Esercente
                          </Label>
                        </Col>
                        {esercentiLoading ?
                          <Spinner/>
                          :
                          <Col md="9" className="pt-1 pb-2">
                            <Input type="select" name="esercenteTransazione" id="esercenteTransazione" onChange={this.handleChangeEsercenteTransazione}>
                              {esercentiVoucherMancanti.map(esercente => (
                                  <option value={esercente.esercenteId} key={esercente.esercenteId}>
                                    {esercente.esercenteRagioneSociale} (saldo disponibile: {esercente.importo} €)
                                  </option>
                                ))
                              }
                            </Input>
                          </Col>
                        }

                      </Row>
                    }
                    <FormGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>€</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="saldo"
                          type="number"
                          className="form-control"
                          onChange={this.handleChange}
                          value={importo}
                          valid={ transazioneValid }
                          invalid={ !transazioneValid }
                          name="saldo"
                          placeholder="indica l'importo da aggiungere, es: 100,00"
                        />
                        <InputGroupAddon addonType="append">
                          <Button color="success" onClick={this.creaTransazione.bind(this, tipologiaTransazione)} disabled={!transazioneValid || transazioneUpdating}>aggiungi</Button>
                        </InputGroupAddon>
                      </InputGroup>
                      { tipologiaTransazione && tipologiaTransazione === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO &&
                      <FormFeedback
                        valid={ accreditoDirettoValid }
                        invalid={ !accreditoDirettoValid }>Il campo importo è obbligatorio e deve essere un numerico</FormFeedback>
                      }
                      { tipologiaTransazione && tipologiaTransazione !== TipologiaTransazione.TT_05_ACCREDITO_DIRETTO &&
                      <FormFeedback
                        valid={ accreditoVoucherValid }
                        invalid={ !accreditoVoucherValid }>E' necessario impostare un importo maggiore di 0 e minore del saldo disponibile per l'esercente</FormFeedback>
                      }
                    </FormGroup>
                  </Form>
                </>
              }
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleCloseModal}>Chiudi</Button>
            </ModalFooter>
          </Modal>

          {/* APPROVA RICHIESTA CONFIRM MODAL */}
          <Modal isOpen={showApproveModal} toggle={this.handleCloseApproveModal}>
            <ModalHeader toggle={this.handleCloseDenyModal}>Conferma Approvazione Richiesta</ModalHeader>
            <ModalBody>
              {updating &&
              <div className="text-center">
                <h5>salvataggio approvazione in corso...</h5>
                <Spinner color="dark" />
              </div>
              }
              {!updating &&
              <>
                Sei sicuro di voler <b>APPROVARE</b> questa richiesta?
              </>
              }

            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleCloseApproveModal}>
                <FontAwesomeIcon icon="ban" />
                &nbsp; Indietro
              </Button>
              <Button id="jhi-confirm-delete-cittadino" color="danger" onClick={this.confirmApprove}>
                <FontAwesomeIcon icon="trash" />
                &nbsp; Approva richiesta
              </Button>
            </ModalFooter>
          </Modal>

          {/* RIFIUTA RICHIESTA CONFIRM MODAL */}
          <Modal isOpen={showDenyModal} toggle={this.handleCloseDenyModal}>
            <ModalHeader toggle={this.handleCloseDenyModal}>Conferma cancellazione</ModalHeader>
            <ModalBody>
              {updating &&
              <div className="text-center">
                <h5>salvataggio cancellazione in corso...</h5>
                <Spinner color="dark" />
              </div>
              }
              {!updating &&
                <>
                  Sei sicuro di voler <b>RIFIUTARE</b> questa richiesta?
                </>
              }

            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleCloseDenyModal}>
                <FontAwesomeIcon icon="ban" />
                &nbsp; Indietro
              </Button>
              <Button id="jhi-confirm-delete-cittadino" color="danger" onClick={this.confirmDeny}>
                <FontAwesomeIcon icon="trash" />
                &nbsp; Rifiuta richiesta
              </Button>
            </ModalFooter>
          </Modal>

          {/* REOPEN RICHIESTA CONFIRM MODAL */}
          <Modal isOpen={showReopenModal} toggle={this.handleCloseReopenModal}>
            <ModalHeader toggle={this.handleCloseReopenModal}>Conferma riapertura richiesta</ModalHeader>
            <ModalBody>
              {updating &&
              <div className="text-center">
                <h5>salvataggio riapertura in corso...</h5>
                <Spinner color="dark" />
              </div>
              }
              {!updating &&
              <>
                Sei sicuro di voler <b>RIAPRIRE</b> questa richiesta?
              </>
              }

            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.handleCloseDenyModal}>
                <FontAwesomeIcon icon="ban" />
                &nbsp; Indietro
              </Button>
              <Button id="jhi-confirm-delete-cittadino" color="success" onClick={this.confirmReopen}>
                <FontAwesomeIcon icon="trash" />
                &nbsp; Riapri richiesta
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }

  }
}

const mapStateToProps = (storeState: IRootState) => ({
  categorieEsercenti: storeState.categoriaEsercente.entities,
  cittadinoEntity: storeState.cittadino.entity,
  saldo: storeState.cittadino.saldo,
  saldoVoucher: storeState.cittadino.saldoVoucher,
  loadingSaldo: storeState.cittadino.loadingSaldo,
  loadingSaldoVoucher: storeState.cittadino.loadingSaldo,
  loading: storeState.cittadino.loading,
  updating: storeState.cittadino.updating,
  transazioneUpdating: storeState.transazione.updating,
  transazioneUpdateSuccess: storeState.transazione.updateSuccess,
  account: storeState.authentication.account,
  esercentiVoucherMancanti: storeState.esercente.esercentiVoucherMancanti,
  esercentiLoading: storeState.esercente.loading,
  document: storeState.document
});

const mapDispatchToProps = {
  getCategorieEsercenti,
  getPublicEsercenti,
  getPublicEsercentiVoucher,
  getEntity,
  getPublicSaldo,
  getPublicSaldoVoucher,
  creaAccreditoDiretto,
  creaAssegnazione,
  reset,
  approveCittadino,
  denyCittadino,
  reopenCittadino,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CittadinoDetail);
