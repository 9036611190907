import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { ICrudGetAction, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from 'app/entities/transazione/transazione.reducer';
import { ITransazione } from 'app/shared/model/transazione.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ITransazioneDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class TransazioneDetail extends React.Component<ITransazioneDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  render() {
    const { transazioneEntity } = this.props;
    return (
      <Row>
        <Col md="8">
          <h2>
            Transazione [<b>{transazioneEntity.id}</b>]
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="uuid">Uuid</span>
            </dt>
            <dd>{transazioneEntity.uuid}</dd>
            <dt>
              <span id="tipo">Tipo</span>
            </dt>
            <dd>{transazioneEntity.tipo}</dd>
            <dt>
              <span id="importo">Importo</span>
            </dt>
            <dd>{transazioneEntity.importo}</dd>
            <dt>
              <span id="stato">Stato</span>
            </dt>
            <dd>{transazioneEntity.stato}</dd>
            <dt>
              <span id="scontrino">Scontrino</span>
            </dt>
            <dd>
              {transazioneEntity.scontrino ? (
                <div>
                  <a onClick={openFile(transazioneEntity.scontrinoContentType, transazioneEntity.scontrino)}>Open&nbsp;</a>
                  <span>
                    {transazioneEntity.scontrinoContentType}, {byteSize(transazioneEntity.scontrino)}
                  </span>
                </div>
              ) : null}
            </dd>
            <dt>Comune Riferimento</dt>
            <dd>{transazioneEntity.comuneRiferimento ? transazioneEntity.comuneRiferimento.id : ''}</dd>
            <dt>Cittadino</dt>
            <dd>{transazioneEntity.cittadino ? transazioneEntity.cittadino.id : ''}</dd>
            <dt>Esercente</dt>
            <dd>{transazioneEntity.esercente ? transazioneEntity.esercente.id : ''}</dd>
          </dl>
          <Button tag={Link} to="/transazione" replace color="info">
            <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Back</span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/transazione/${transazioneEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
          </Button>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ transazione }: IRootState) => ({
  transazioneEntity: transazione.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransazioneDetail);
