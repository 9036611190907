import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IRichiesta, defaultValue } from 'app/shared/model/richiesta.model';

export const ACTION_TYPES = {
  CREATE_RICHIESTA: 'richiesta/CREATE_RICHIESTA',
  SEND_RICHIESTA_EMAIL: 'richiesta/SEND_RICHIESTA_EMAIL',
  SET_BLOB: 'richiesta/SET_BLOB',
  SET_ENTITY_PROPERTY: 'richiesta/SET_ENTITY_PROPERTY',
  RESET: 'richiesta/RESET'
};

const initialState = {
  updating: false,
  updateSuccess: false,
  errorMessage: null,
  emailSending: false,
  emailSent: false,
  errorEmailMessage: null,
  entity: defaultValue
};

export type RichiestaState = Readonly<typeof initialState>;

// Reducer

export default (state: RichiestaState = initialState, action): RichiestaState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.CREATE_RICHIESTA):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.SEND_RICHIESTA_EMAIL):
      return {
        ...state,
        errorEmailMessage: null,
        emailSending: true,
        emailSent: false
      };
    case FAILURE(ACTION_TYPES.SEND_RICHIESTA_EMAIL):
      return {
        ...state,
        emailSending: false,
        emailSent: false,
        errorEmailMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.CREATE_RICHIESTA):
      return {
        ...state,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.CREATE_RICHIESTA):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.SEND_RICHIESTA_EMAIL):
      return {
        ...state,
        emailSending: false,
        emailSent: true
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, filename, size } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: filename,
          [name + 'Size']: size
        }
      };
    }
    case ACTION_TYPES.SET_ENTITY_PROPERTY: {
      const { propertyName, value } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [propertyName]: value
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/richieste';
const apiUrlPublic = 'api/public/richieste';

// Actions

export const setBlob = (name, data, contentType?, filename?, size?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    filename,
    size
  }
});

export const setEntityProperty = (propertyName, value) => ({
  type: ACTION_TYPES.SET_ENTITY_PROPERTY,
  payload: {
    propertyName,
    value
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const createPublicEntityForCittadino = (entity, comuneStringId) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_RICHIESTA,
    payload: axios.post('api/public/richiesta/' + comuneStringId, cleanEntity(entity))
  });
  return result;
};

export const sendEmail = (entityId, comuneStringId) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.SEND_RICHIESTA_EMAIL,
    payload: axios.get('api/public/richiesta/send-email/' + entityId + '/' + comuneStringId)
  });
  return result;
};
