import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button,
  Spinner,
  Row,
  Table,
  Col,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  Badge,
  Label} from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {getSortState, IPaginationBaseState, JhiPagination, JhiItemCount, TextFormat} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getPublicCittadini as getEntities } from 'app/entities/cittadino/cittadino.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import {download} from "app/shared/util/entity-utils";
import {itaLocale} from "app/shared/util/date-utils";
import {getUrlParameter} from "app/shared/util/misc-utils";
import moment from "moment";
import {APP_DATE_FORMAT} from "app/config/constants";
import axios from "axios";

export interface ICittadinoProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface ICittadinoState extends IPaginationBaseState {
  showSearchFilters: boolean;
  nomeFilter: string;
  cognomeFilter: string;
  codiceFiscaleFilter: string;
  approvedFromFilter: string;
  approvedToFilter: string;
  exporting: boolean;
};

export class Cittadino extends React.Component<ICittadinoProps, ICittadinoState> {
  state: ICittadinoState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    showSearchFilters: false,
    nomeFilter: '',
    cognomeFilter: '',
    codiceFiscaleFilter: '',
    approvedFromFilter: '',
    approvedToFilter: '',
    exporting: false
  };

  searchFilterParams = '';

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    const nomeFilter = getUrlParameter('nome', this.props.location.search) ? getUrlParameter('nome', this.props.location.search) : '';
    const cognomeFilter = getUrlParameter('cognome', this.props.location.search) ? getUrlParameter('cognome', this.props.location.search) : '';
    const codiceFiscaleFilter = getUrlParameter('codiceFiscale', this.props.location.search) ? getUrlParameter('codiceFiscale', this.props.location.search) : '';
    const approvedFromFilter = getUrlParameter('approvedFrom', this.props.location.search) ? moment(getUrlParameter('approvedFrom', this.props.location.search)).format('DD/MM/YYYY') : '';
    const approvedToFilter = getUrlParameter('approvedTo', this.props.location.search) ? moment(getUrlParameter('approvedTo', this.props.location.search)).format('DD/MM/YYYY') : '';

    this.setState({
      nomeFilter,
      cognomeFilter,
      codiceFiscaleFilter,
      approvedFromFilter,
      approvedToFilter
    }, () => this.handleFilter(null, true));

    // this.getEntities();
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateSuccess && !prevProps.updateSuccess) {
      this.getEntities();
    }
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    if (this.searchFilterParams && this.searchFilterParams.length > 0) {
      this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}&${this.searchFilterParams}`);
    } else {
      this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = (isFirstLoading = false) => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    if (!isFirstLoading) {
      if (this.searchFilterParams && this.searchFilterParams.length > 0) {
        this.props.history.push(`${this.props.location.pathname}?${this.searchFilterParams}` + (sort ? `&page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}` : ''));
      } else {
        this.props.history.push(`${this.props.location.pathname}` + (sort ? `?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}` : ''));
      }
    }
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, this.searchFilterParams);
  };

  handleFilter(event, isFirstLoading = false) {
    if (event) {
      event.preventDefault();
    }

    const { nomeFilter,
      cognomeFilter,
      codiceFiscaleFilter,
      approvedFromFilter,
      approvedToFilter
    } = this.state;

    let searchFilterParams = ''
    if (nomeFilter) {
      searchFilterParams += '&nome='+nomeFilter
    }
    if (cognomeFilter) {
      searchFilterParams += '&cognome='+cognomeFilter
    }
    if (codiceFiscaleFilter) {
      searchFilterParams += '&codiceFiscale='+codiceFiscaleFilter
    }
    if (approvedFromFilter) {
      searchFilterParams += '&approvedFrom='+moment(approvedFromFilter, 'DD/MM/YYYY').toISOString()
    }
    if (approvedToFilter) {
      searchFilterParams += '&approvedTo='+moment(approvedToFilter, 'DD/MM/YYYY').toISOString()
    }

    if (searchFilterParams.length > 0) {
      this.searchFilterParams = searchFilterParams.replace('&', '');
      this.setState({
        showSearchFilters: true
      })
    } else {
      this.searchFilterParams = '';
    }
    this.getEntities(isFirstLoading);
  }

  resetSearch = () => {
    this.setState({
      nomeFilter: '',
      cognomeFilter: '',
      codiceFiscaleFilter: '',
      approvedFromFilter: '',
      approvedToFilter: ''
    }, () => this.handleFilter(null));
  }

  handleInputChange(event, picker) {
    let target = event.target;
    let value = target.value && target.value;
    let propName = target.name && target.name

    if (picker) {
      value = picker.startDate.format('DD/MM/YYYY')
      propName = picker.element.find('input').attr('name')
    }

    this.setState({
      [propName]: value
    });
  }

  exportExcel = () =>  {
    const { activePage, itemsPerPage, sort, order } = this.state;

    const exportRequestUrl = this.getXlsEntitiesRequestUrl(0, 1000000, `${sort},${order}`, this.searchFilterParams);
    this.setState({exporting: true});
    let _that = this;
    axios({
      url: exportRequestUrl,
      method: 'GET',
      responseType: 'arraybuffer' // important
    }).then(response => {
      // handle success
      const filename = response.headers["content-disposition"].split('; filename=')[1];
      const blobType = response.headers["content-type"];
      const url = window.URL.createObjectURL(new Blob([response.data],
        {type: blobType}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      // handle error
      _that.setState({exporting: false});
    }).then(function () {
      // always executed
      _that.setState({exporting: false});
    });;
  }

  getXlsEntitiesRequestUrl = (page, size, sort, searchFilterParams) => {
    let params = '?stato=approvata';
    if (sort) {
      params += `&page=${page}&size=${size}&sort=${sort}`;
    }
    if (searchFilterParams) {
      params += `&${searchFilterParams}`;
    }
    params += `&cacheBuster=${new Date().getTime()}`;
    return (`api/public/cittadinos-export${params}`);
  };

  render() {
    const { cittadinoList, match, totalItems, loading, account } = this.props;
    const { showSearchFilters, exporting } = this.state;
    const { nomeFilter,
      cognomeFilter,
      codiceFiscaleFilter,
      approvedFromFilter,
      approvedToFilter
    } = this.state;

    return (
      <div>
        <h2 id="cittadino-heading">
          Elenco Cittadini
          <Button color="excel" className="float-right ml-3" disabled={exporting || loading || !(totalItems && totalItems > 0)} onClick={this.exportExcel}>
            <FontAwesomeIcon icon={exporting ? 'spinner' : 'file-excel' } spin={exporting} />
            &nbsp; Esporta cittadini
          </Button>
          <Link to={`/amministrazione/richiesta/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Aggiungi richiesta
          </Link>
        </h2>
        <hr />
        <Col xs="12" className="mb-3">
          <Card>
            <CardHeader style={{padding: '0px'}}>
              <Row>
                <Col xs="6" md={{ size: 10, offset: 1 }} className="text-md-center text-sm-left">
                  <a className="btn" data-target="#searchFilters" onClick={() => this.setState({showSearchFilters: !showSearchFilters})}>
                    <h6>Cerca <FontAwesomeIcon icon="search" /></h6>
                  </a>
                </Col>
                <Col xs="6" md={{ size: 1 }} className="text-right">
                  <a className="btn" data-target="#searchFilters" onClick={() => this.setState({showSearchFilters: !showSearchFilters})}>
                    <h6><FontAwesomeIcon icon={showSearchFilters ? "chevron-circle-down" : "chevron-circle-right"} /></h6>
                  </a>
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={this.state.showSearchFilters} id="searchFilters">
              <CardBody>
                <form onSubmit={this.handleFilter}>
                  <Row>
                    <Col xs="12" md="3">
                      <Label htmlFor="approvedFromFilter">Data approvazione - (dal)</Label><br />
                      <DateRangePicker containerStyles={{width: '100%'}} singleDatePicker onApply={this.handleInputChange} locale={itaLocale}>
                        <InputGroup size="sm">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><FontAwesomeIcon icon="calendar" /></InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" name="approvedFromFilter" id="approvedFromFilter" placeholder="Inserisci data inizio..." value={approvedFromFilter} readOnly autoComplete="dummy-autocomplete" />
                        </InputGroup>
                      </DateRangePicker>
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="approvedToFilter">Data approvazione - (sino al)</Label><br />
                      <DateRangePicker containerStyles={{width: '100%'}} singleDatePicker onApply={this.handleInputChange} locale={itaLocale}>
                        <InputGroup size="sm">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><FontAwesomeIcon icon="calendar" /></InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" name="approvedToFilter" id="approvedToFilter" placeholder="Inserisci data fine..." value={approvedToFilter} readOnly autoComplete="dummy-autocomplete" />
                        </InputGroup>
                      </DateRangePicker>
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="nomeFilter">Nome cittadino</Label><br />
                      <Input type="text" name="nomeFilter" id="nomeFilter" size="sm" placeholder="Inserisci nome..." value={nomeFilter} onChange={this.handleInputChange} />
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="cognomeFilter">Cognome cittadino</Label><br />
                      <Input type="text" name="cognomeFilter" id="cognomeFilter" size="sm" placeholder="Inserisci cognome..." value={cognomeFilter} onChange={this.handleInputChange} />
                    </Col>
                  </Row>
                  <Row className="mt-md-2">
                    <Col xs="12" md="3">
                      <Label htmlFor="codiceFiscaleFilter">Codice fiscale</Label><br />
                      <Input type="text" name="codiceFiscaleFilter" id="codiceFiscaleFilter" size="sm" placeholder="Inserisci codice fiscale..." value={codiceFiscaleFilter} onChange={this.handleInputChange} />
                    </Col>
                  </Row>
                  <hr/>
                  <Row>
                    <Col sm="12"  md={{ size: 2, offset: 8 }} className="pb-3">
                      <Button color="primary" block onClick={this.resetSearch}><FontAwesomeIcon icon="redo" /> Pulisci ricerca</Button>
                    </Col>
                    <Col sm="12" md="2">
                      <Button color="primary" block type="submit"><FontAwesomeIcon icon="search" /> Cerca</Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
        {loading &&
          <div className="text-center">
            <h5>caricamento in corso...</h5>
            <Spinner color="dark" />
          </div>
        }
        {!loading &&
          <>
            <div className="table-responsive">
              {cittadinoList && cittadinoList.length > 0 ? (
                <Table responsive aria-describedby="cittadino-heading" striped size="sm">
                  <thead>
                  <tr>
                    <th className="hand" onClick={this.sort('approvedDate')}>
                      Data Approvazione <FontAwesomeIcon icon="sort" />
                    </th>
                    <th className="hand" onClick={this.sort('codiceFiscale')}>
                      Codice Fiscale <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th className="hand" onClick={this.sort('user.lastName')}>
                      Cognome <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th className="hand" onClick={this.sort('user.firstName')}>
                      Nome <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th className="hand" onClick={this.sort('user.email')}>
                      Email <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th>Cellulare</th>
                    <th className="hand" onClick={this.sort('saldoDisponibile')}>
                      Saldo Disponibile <FontAwesomeIcon icon="sort"/>
                    </th>
                    {account && account.amministrazioneComunale && account.amministrazioneComunale.voucherAbilitati &&
                      <th className="hand" onClick={this.sort('saldoVouchers')}>
                        Saldo voucher <FontAwesomeIcon icon="sort"/>
                      </th>
                    }
                    {/*<th className="hand" onClick={this.sort('indirizzo')}>
                      Indirizzo <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th className="hand" onClick={this.sort('codicePostale')}>
                      Codice Postale <FontAwesomeIcon icon="sort"/>
                    </th>
                    <th>Documento d'Identità</th>
                    <th>Documento richiesta</th> */}
                    <th/>
                  </tr>
                  </thead>
                  <tbody>
                  {cittadinoList.map((cittadino, i) => (
                    <tr key={`entity-${i}`}>
                      <td>
                        <TextFormat value={cittadino.approvedDate} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
                      </td>
                      <td>
                        <Button tag={Link} to={`${match.url}/${cittadino.id}`} color="link" size="sm">
                          {cittadino.codiceFiscale}
                        </Button>
                      </td>
                      <td>{cittadino.user.lastName}</td>
                      <td>{cittadino.user.firstName}</td>
                      <td>{cittadino.user.email ? cittadino.user.email : ''}</td>
                      <td>{cittadino.cellulare}</td>
                      <td><Badge
                        color="primary">{cittadino.saldoDisponibile ? cittadino.saldoDisponibile.toLocaleString() : 0} €</Badge>
                      </td>
                      {account && account.amministrazioneComunale && account.amministrazioneComunale.voucherAbilitati &&
                        <td><Badge
                          color="primary">{cittadino.saldoDisponibileVouchers ? cittadino.saldoDisponibileVouchers.toLocaleString() : 0} €</Badge>
                        </td>
                      }
                      {/*<td>{cittadino.indirizzo}</td>
                      <td>{cittadino.codicePostale}</td>
                      <td>
                        {cittadino.tesseraUrl ? (
                          <a
                            onClick={download(`/api/${cittadino.tesseraUrl}`, 'tessera', cittadino.tesseraContentType)}>Download</a>
                        ) : null}
                      </td>
                      <td>
                        {cittadino.richiestaUrl ? (
                          <a
                            onClick={download(`/api/${cittadino.richiestaUrl}`, 'richiesta', cittadino.richiestaContentType)}>Download</a>
                        ) : null}
                      </td>*/}
                      <td className="text-right">
                        <div>
                          <Button tag={Link} to={`${match.url}/${cittadino.id}`} color="info">
                            <FontAwesomeIcon icon="eye"/> <span className="d-none d-md-inline">Apri</span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${cittadino.id}/edit`} color="primary" className="ml-1">
                            <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Modifica</span>
                          </Button>
                          <Button tag={Link} to={`${match.url}/${cittadino.id}/delete`} color="danger" className="ml-1">
                            <FontAwesomeIcon icon="trash"/> <span className="d-none d-md-inline">Elimina</span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              ) : (
                <div className="alert alert-warning">Non è presente nessun cittadino</div>
              )}
            </div>
            <div className={cittadinoList && cittadinoList.length > 0 ? '' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage}/>
              </Row>
              <Row className="justify-content-center">
                <JhiPagination
                  activePage={this.state.activePage}
                  onSelect={this.handlePagination}
                  maxButtons={5}
                  itemsPerPage={this.state.itemsPerPage}
                  totalItems={this.props.totalItems}
                />
              </Row>
            </div>
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ cittadino, authentication }: IRootState) => ({
  cittadinoList: cittadino.entities,
  updateSuccess: cittadino.updateSuccess,
  totalItems: cittadino.totalItems,
  loading: cittadino.loading,
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Cittadino);
