import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { NavDropdown } from './menu-components';

const esercentiMenuItems = (
  <>
    <MenuItem icon="shopping-cart" to="/amministrazione/esercente">
      Elenco Esercenti
    </MenuItem>
    <MenuItem icon="tags" to="/amministrazione/categoria-esercente">
      Categorie esercenti
    </MenuItem>
  </>
);


export const EsercentiMenu = () => (
  <>
      <NavDropdown icon="shopping-cart" name="Esercenti" id="esercenti-menu">
        {esercentiMenuItems}
      </NavDropdown>
  </>
);

export default EsercentiMenu;
