import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, setFileData, openFile, byteSize, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IAmministrazioneComunale } from 'app/shared/model/amministrazione-comunale.model';
import { getEntities as getAmministrazioneComunales } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
import { ICittadino } from 'app/shared/model/cittadino.model';
import { getEntities as getCittadinos } from 'app/entities/cittadino/cittadino.reducer';
import { IEsercente } from 'app/shared/model/esercente.model';
import { getEntities as getEsercentes } from 'app/entities/esercente/esercente.reducer';
import { getEntity, updateEntity, createEntity, setBlob, reset } from 'app/entities/transazione/transazione.reducer';
import { ITransazione } from 'app/shared/model/transazione.model';
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ITransazioneUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ITransazioneUpdateState {
  isNew: boolean;
  comuneRiferimentoId: string;
  cittadinoId: string;
  esercenteId: string;
}

export class TransazioneUpdate extends React.Component<ITransazioneUpdateProps, ITransazioneUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      comuneRiferimentoId: '0',
      cittadinoId: '0',
      esercenteId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAmministrazioneComunales();
    this.props.getCittadinos();
    this.props.getEsercentes();
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined, undefined, undefined);
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { transazioneEntity } = this.props;
      const entity = {
        ...transazioneEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/transazione');
  };

  render() {
    const { transazioneEntity, amministrazioneComunales, cittadinos, esercentes, loading, updating } = this.props;
    const { isNew } = this.state;

    const { scontrino, scontrinoContentType } = transazioneEntity;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="buoniscontoApp.esercente.home.createOrEditLabel">{isNew ? 'Registra nuova transazione' : 'Modifica transazione'}</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : transazioneEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="transazione-id">ID</Label>
                    <AvInput id="transazione-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="uuidLabel" for="transazione-uuid">
                    Uuid
                  </Label>
                  <AvField
                    id="transazione-uuid"
                    type="text"
                    name="uuid"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="tipoLabel" for="transazione-tipo">
                    Tipo
                  </Label>
                  <AvInput
                    id="transazione-tipo"
                    type="select"
                    className="form-control"
                    name="tipo"
                    value={(!isNew && transazioneEntity.tipo) || 'TT_01_DOTAZIONE'}
                  >
                    <option value="TT_01_DOTAZIONE">TT_01_DOTAZIONE</option>
                    <option value="TT_02_RESTITUZIONE">TT_02_RESTITUZIONE</option>
                    <option value="TT_03_TRASFERIMENTO">TT_03_TRASFERIMENTO</option>
                    <option value="TT_04_LIQUIDAZIONE">TT_04_LIQUIDAZIONE</option>
                    <option value="TT_05_ACCREDITO_DIRETTO">TT_05_ACCREDITO_DIRETTO</option>
                    <option value="TT_06_ANTICIPO_SPESA">TT_06_ANTICIPO_SPESA</option>
                    <option value="TT_07_BUONO_SPESA">TT_07_BUONO_SPESA</option>
                    <option value="TT_08_ANNULLO_BUONO_SPESA">TT_08_ANNULLO_BUONO_SPESA</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label id="importoLabel" for="transazione-importo">
                    Importo
                  </Label>
                  <AvField
                    id="transazione-importo"
                    type="string"
                    className="form-control"
                    name="importo"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="statoLabel" for="transazione-stato">
                    Stato
                  </Label>
                  <AvInput
                    id="transazione-stato"
                    type="select"
                    className="form-control"
                    name="stato"
                    value={(!isNew && transazioneEntity.stato) || 'ST_01_VALIDA'}
                  >
                    <option value="ST_01_VALIDA">ST_01_VALIDA</option>
                    <option value="ST_02_ANNULLATA">ST_02_ANNULLATA</option>
                    <option value="ST_03_SOSPESA">ST_03_SOSPESA</option>
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <AvGroup>
                    <Label id="scontrinoLabel" for="scontrino">
                      Scontrino
                    </Label>
                    <br />
                    {scontrino ? (
                      <div>
                        <a onClick={openFile(scontrinoContentType, scontrino)}>Open</a>
                        <br />
                        <Row>
                          <Col md="11">
                            <span>
                              {scontrinoContentType}, {byteSize(scontrino)}
                            </span>
                          </Col>
                          <Col md="1">
                            <Button color="danger" onClick={this.clearBlob('scontrino')}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                    <input id="file_scontrino" type="file" onChange={this.onBlobChange(false, 'scontrino')} />
                    <AvInput type="hidden" name="scontrino" value={scontrino} />
                  </AvGroup>
                </AvGroup>
                <AvGroup>
                  <Label for="transazione-comuneRiferimento">Comune Riferimento</Label>
                  <AvInput id="transazione-comuneRiferimento" type="select" className="form-control" name="comuneRiferimento.id">
                    <option value="" key="0" />
                    {amministrazioneComunales
                      ? amministrazioneComunales.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="transazione-cittadino">Cittadino</Label>
                  <AvInput id="transazione-cittadino" type="select" className="form-control" name="cittadino.id">
                    <option value="" key="0" />
                    {cittadinos
                      ? cittadinos.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="transazione-esercente">Esercente</Label>
                  <AvInput id="transazione-esercente" type="select" className="form-control" name="esercente.id">
                    <option value="" key="0" />
                    {esercentes
                      ? esercentes.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/transazione" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  amministrazioneComunales: storeState.amministrazioneComunale.entities,
  cittadinos: storeState.cittadino.entities,
  esercentes: storeState.esercente.entities,
  transazioneEntity: storeState.transazione.entity,
  loading: storeState.transazione.loading,
  updating: storeState.transazione.updating,
  updateSuccess: storeState.transazione.updateSuccess
});

const mapDispatchToProps = {
  getAmministrazioneComunales,
  getCittadinos,
  getEsercentes,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransazioneUpdate);
