import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AmministrazioneComunale from './amministrazione-comunale';
import Cittadino from './cittadino';
import Esercente from './esercente';
import Transazione from './transazione';
import CategoriaEsercente from './categoria-esercente';
import Voucher from './voucher';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}amministrazione-comunale`} component={AmministrazioneComunale} />
      <ErrorBoundaryRoute path={`${match.url}cittadino`} component={Cittadino} />
      <ErrorBoundaryRoute path={`${match.url}esercente`} component={Esercente} />
      <ErrorBoundaryRoute path={`${match.url}transazione`} component={Transazione} />
      <ErrorBoundaryRoute path={`${match.url}categoria-esercente`} component={CategoriaEsercente} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
