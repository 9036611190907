import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button,
  Spinner,
  Row,
  Table,
  Col,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  Badge,
  Label} from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {
  getSortState,
  IPaginationBaseState,
  JhiPagination,
  JhiItemCount,
  TextFormat
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getPublicEntities as getEntities } from 'app/entities/esercente/esercente.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import {itaLocale} from "app/shared/util/date-utils";
import {getUrlParameter} from "app/shared/util/misc-utils";
import moment from "moment";
import axios from "axios";
import { getPublicEntities as getCategorieEsercenti } from 'app/entities/categoria-esercente/categoria-esercente.reducer';

export interface IEsercenteProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IEsercenteState extends IPaginationBaseState {
  showSearchFilters: boolean;
  ragioneSocialeFilter: string;
  referenteFilter: string;
  createdFromFilter: string;
  createdToFilter: string;
  categoriaEsercenteFilter: string;
  exporting: boolean;
};

export class Esercente extends React.Component<IEsercenteProps, IEsercenteState> {
  state: IEsercenteState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    showSearchFilters: false,
    ragioneSocialeFilter: '',
    referenteFilter: '',
    createdFromFilter: '',
    createdToFilter: '',
    categoriaEsercenteFilter: 'all',
    exporting: false
  };

  searchFilterParams = '';

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    const ragioneSocialeFilter = getUrlParameter('ragioneSociale', this.props.location.search) ? getUrlParameter('ragioneSociale', this.props.location.search) : '';
    const referenteFilter = getUrlParameter('referente', this.props.location.search) ? getUrlParameter('referente', this.props.location.search) : '';
    const createdFromFilter = getUrlParameter('createdFrom', this.props.location.search) ? moment(getUrlParameter('createdFrom', this.props.location.search)).format('DD/MM/YYYY') : '';
    const createdToFilter = getUrlParameter('createdTo', this.props.location.search) ? moment(getUrlParameter('createdTo', this.props.location.search)).format('DD/MM/YYYY') : '';
    const categoriaEsercenteFilter = getUrlParameter('categoriaEsercente', this.props.location.search) ? getUrlParameter('categoriaEsercente', this.props.location.search) : this.state.categoriaEsercenteFilter;

    this.props.getCategorieEsercenti();

    this.setState({
      ragioneSocialeFilter,
      referenteFilter,
      createdFromFilter,
      createdToFilter,
      categoriaEsercenteFilter
    }, () => this.handleFilter(null,true));

    // this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    if (this.searchFilterParams && this.searchFilterParams.length > 0) {
      this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}&${this.searchFilterParams}`);
    } else {
      this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = (isFirstLoading = false) => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    if (!isFirstLoading) {
      if (this.searchFilterParams && this.searchFilterParams.length > 0) {
        this.props.history.push(`${this.props.location.pathname}?${this.searchFilterParams}` + (sort ? `&page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}` : ''));
      } else {
        this.props.history.push(`${this.props.location.pathname}` + (sort ? `?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}` : ''));
      }
    }
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, this.searchFilterParams);
  };

  handleFilter(event, isFirstLoading = false) {
    if (event) {
      event.preventDefault();
    }

    const { ragioneSocialeFilter,
      referenteFilter,
      createdFromFilter,
      createdToFilter,
      categoriaEsercenteFilter
    } = this.state;

    let searchFilterParams = ''
    if (ragioneSocialeFilter) {
      searchFilterParams += '&ragioneSociale='+ragioneSocialeFilter
    }
    if (referenteFilter) {
      searchFilterParams += '&referente='+referenteFilter
    }
    if (createdFromFilter) {
      searchFilterParams += '&createdFrom='+moment(createdFromFilter, 'DD/MM/YYYY').toISOString()
    }
    if (createdToFilter) {
      searchFilterParams += '&createdTo='+moment(createdToFilter, 'DD/MM/YYYY').toISOString()
    }
    if (categoriaEsercenteFilter) {
      searchFilterParams += '&categoriaEsercente='+categoriaEsercenteFilter
    }

    if (searchFilterParams.length > 0) {
      this.searchFilterParams = searchFilterParams.replace('&', '');
      this.setState({
        showSearchFilters: true
      })
    } else {
      this.searchFilterParams = '';
    }
    this.getEntities(isFirstLoading);
  }

  handleChangeCategoria = event => {
    this.setState({categoriaEsercenteFilter: event.target.value});
  };

  resetSearch = () => {
    this.setState({
      ragioneSocialeFilter: '',
      referenteFilter: '',
      createdFromFilter: '',
      createdToFilter: '',
      categoriaEsercenteFilter: 'all'
    }, () => this.handleFilter(null));
  }

  handleInputChange(event, picker) {
    let target = event.target;
    let value = target.value && target.value;
    let propName = target.name && target.name

    if (picker) {
      value = picker.startDate.format('DD/MM/YYYY')
      propName = picker.element.find('input').attr('name')
    }

    this.setState({
      [propName]: value
    });
  }

  exportExcel = () =>  {
    const { activePage, itemsPerPage, sort, order } = this.state;

    const exportRequestUrl = this.getXlsEntitiesRequestUrl(0, 1000000, `${sort},${order}`, this.searchFilterParams);
    this.setState({exporting: true});
    let _that = this;
    axios({
      url: exportRequestUrl,
      method: 'GET',
      responseType: 'arraybuffer' // important
    }).then(response => {
      // handle success
      const filename = response.headers["content-disposition"].split('; filename=')[1];
      const blobType = response.headers["content-type"];
      const url = window.URL.createObjectURL(new Blob([response.data],
        {type: blobType}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      // handle error
      _that.setState({exporting: false});
    }).then(function () {
      // always executed
      _that.setState({exporting: false});
    });;
  }

  getXlsEntitiesRequestUrl = (page, size, sort, searchFilterParams) => {
    let params = '';
    if (sort) {
      params += `?page=${page}&size=${size}&sort=${sort}`;
    }
    if (searchFilterParams) {
      params += `${sort ? '&' : '?'}${searchFilterParams}`;
    }
    params += `${(sort || searchFilterParams) ? '&' : '?'}cacheBuster=${new Date().getTime()}`;
    return (`api/public/esercentes-export${params}`);
  };

  render() {
    const { account, esercenteList, match, totalItems, loading, categorieEsercenti, categorieEsercentiLoading } = this.props;
    const { showSearchFilters, exporting } = this.state;
    const { ragioneSocialeFilter,
      referenteFilter,
      createdFromFilter,
      createdToFilter,
      categoriaEsercenteFilter
    } = this.state;

    return (
      <div>
        <h2 id="esercente-heading">
          Elenco Esercenti
          <Button color="excel" className="float-right ml-3" disabled={exporting || loading || !(totalItems && totalItems > 0)} onClick={this.exportExcel}>
            <FontAwesomeIcon icon={exporting ? 'spinner' : 'file-excel' } spin={exporting} />
            &nbsp; Esporta esercenti
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Aggiungi esercente
          </Link>
        </h2>
        <hr />
        <Col xs="12" className="mb-3">
          <Card>
            <CardHeader style={{padding: '0px'}}>
              <Row>
                <Col xs="6" md={{ size: 10, offset: 1 }} className="text-md-center text-sm-left">
                  <a className="btn" data-target="#searchFilters" onClick={() => this.setState({showSearchFilters: !showSearchFilters})}>
                    <h6>Cerca <FontAwesomeIcon icon="search" /></h6>
                  </a>
                </Col>
                <Col xs="6" md={{ size: 1 }} className="text-right">
                  <a className="btn" data-target="#searchFilters" onClick={() => this.setState({showSearchFilters: !showSearchFilters})}>
                    <h6><FontAwesomeIcon icon={showSearchFilters ? "chevron-circle-down" : "chevron-circle-right"} /></h6>
                  </a>
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={this.state.showSearchFilters} id="searchFilters">
              <CardBody>
                <form onSubmit={this.handleFilter}>
                  <Row>
                    <Col xs="12" md="3">
                      <Label htmlFor="createdFromFilter">Data creazione - (dal)</Label><br />
                      <DateRangePicker containerStyles={{width: '100%'}} singleDatePicker onApply={this.handleInputChange} locale={itaLocale}>
                        <InputGroup size="sm">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><FontAwesomeIcon icon="euro-sign" /></InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" name="createdFromFilter" id="createdFromFilter" placeholder="Inserisci data inizio..." value={createdFromFilter} readOnly autoComplete="dummy-autocomplete" />
                        </InputGroup>
                      </DateRangePicker>
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="createdToFilter">Data creazione - (sino al)</Label><br />
                      <DateRangePicker containerStyles={{width: '100%'}} singleDatePicker onApply={this.handleInputChange} locale={itaLocale}>
                        <InputGroup size="sm">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><FontAwesomeIcon icon="calendar" /></InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" name="createdToFilter" id="createdToFilter" placeholder="Inserisci data fine..." value={createdToFilter} readOnly autoComplete="dummy-autocomplete" />
                        </InputGroup>
                      </DateRangePicker>
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="ragioneSocialeFilter">Nome esercizio</Label><br />
                      <Input type="text" name="ragioneSocialeFilter" id="ragioneSocialeFilter" size="sm" placeholder="Inserisci nome esercizio..." value={ragioneSocialeFilter} onChange={this.handleInputChange} />
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="referenteFilter">Nominativo referente</Label><br />
                      <Input type="text" name="referenteFilter" id="referenteFilter" size="sm" placeholder="Inserisci nome e/o cognome..." value={referenteFilter} onChange={this.handleInputChange} />
                    </Col>
                  </Row>
                  {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                    <Row className="mt-md-2">
                      <Col xs="12" md="3">
                        <Label htmlFor="categoriaEsercenteFilter">Categoria</Label>
                        <Row>
                          {categorieEsercentiLoading &&
                            <Col xs="auto" style={{paddingTop: '7px', paddingRight: '0px'}}><FontAwesomeIcon icon='spinner' spin/></Col>
                          }
                          <Col >
                            <Input name="categoriaEsercenteFilter" id="categoriaEsercenteFilter" type="select" className="form-control" size="sm"
                                   onChange={this.handleChangeCategoria}
                                   disabled={categorieEsercentiLoading}
                                   value={categoriaEsercenteFilter}
                            >
                              <option value="all" key="all">
                                TUTTE
                              </option>
                              {categorieEsercenti
                                ? categorieEsercenti.map(categoriaEsercente => (
                                  <option value={categoriaEsercente.denominazione} key={categoriaEsercente.denominazione}>
                                    {categoriaEsercente.denominazione}
                                  </option>
                                ))
                                : null}
                            </Input>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                  <hr/>
                  <Row>
                    <Col sm="12"  md={{ size: 2, offset: 8 }} className="pb-3">
                      <Button color="primary" block onClick={this.resetSearch}><FontAwesomeIcon icon="redo" /> Pulisci ricerca</Button>
                    </Col>
                    <Col sm="12" md="2">
                      <Button color="primary" block type="submit"><FontAwesomeIcon icon="search" /> Cerca</Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
        {loading &&
          <div className="text-center">
            <h5>caricamento in corso...</h5>
            <Spinner color="dark" />
          </div>
        }
        {!loading &&
          <>
            <div className="table-responsive">
              {esercenteList && esercenteList.length > 0 ? (
                <Table responsive aria-describedby="esercente-heading" striped size="sm">
                  <thead>
                    <tr>
                      <th className="hand" onClick={this.sort('createdDate')}>
                        Data Creazione <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={this.sort('ragioneSociale')}>
                        Ragione Sociale <FontAwesomeIcon icon="sort" />
                      </th>
                      {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                        <th className="hand" onClick={this.sort('categoriaEsercente')}>
                          Categoria Esercente <FontAwesomeIcon icon="sort" />
                        </th>
                      }
                      <th className="hand" onClick={this.sort('referente')}>
                        Referente <FontAwesomeIcon icon="sort" />
                      </th>
                      <th>Telefono</th>
                      <th>Cellulare</th>
                      <th className="hand" onClick={this.sort('saldo')}>
                        Saldo <FontAwesomeIcon icon="sort" />
                      </th>
                      {account && account.amministrazioneComunale && account.amministrazioneComunale.voucherAbilitati &&
                        <th className="hand" onClick={this.sort('saldoVouchers')}>
                          Saldo voucher <FontAwesomeIcon icon="sort"/>
                        </th>
                      }
                      {/*<th className="hand" onClick={this.sort('comune')}>
                        Comune <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={this.sort('provincia')}>
                        Provincia <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand text-center" onClick={this.sort('consegnaDomicilio')}>
                        Consegna Domicilio <FontAwesomeIcon icon="sort" />
                      </th> */}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {esercenteList.map((esercente, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          <TextFormat value={esercente.createdDate} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
                        </td>
                        <td>
                          <Button tag={Link} to={`${match.url}/${esercente.id}`} color="link" size="sm">
                            {esercente.ragioneSociale}
                          </Button>
                        </td>
                        {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                        <td>
                          {esercente.categoriaEsercente &&
                          <>
                            <FontAwesomeIcon icon="tags" fixedWidth/> {esercente.categoriaEsercente.denominazione}
                          </>
                          }
                        </td>
                        }
                        <td>{esercente.referente}</td>
                        <td>{esercente.telefono}</td>
                        <td>{esercente.cellulare}</td>
                        <td><Badge color="primary">{esercente.saldo ? esercente.saldo.toLocaleString() : 0} €</Badge></td>
                        {account && account.amministrazioneComunale && account.amministrazioneComunale.voucherAbilitati &&
                          <td><Badge
                            color="primary">{esercente.saldoVouchers ? esercente.saldoVouchers.toLocaleString() : 0} €</Badge>
                          </td>
                        }
                        {/*<td>{esercente.comune}</td>
                        <td>{esercente.provincia}</td>
                        <td className="text-center">{esercente.consegnaDomicilio ? <FontAwesomeIcon icon="check-circle" /> : <FontAwesomeIcon color="danger" icon="times-circle" />}</td>*/}
                        <td className="text-right">
                          <div>
                            <Button tag={Link} to={`${match.url}/${esercente.id}`} color="info">
                              <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Visualizza</span>
                            </Button>
                            <Button tag={Link} to={`${match.url}/${esercente.id}/edit`} color="primary" className="ml-1">
                              <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Modifica</span>
                            </Button>
                            <Button tag={Link} to={`${match.url}/${esercente.id}/delete`} color="danger" className="ml-1">
                              <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Elimina</span>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="alert alert-warning">Non è presente nessun esercente</div>
              )}
            </div>
            <div className={esercenteList && esercenteList.length > 0 ? '' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} />
              </Row>
              <Row className="justify-content-center">
                <JhiPagination
                  activePage={this.state.activePage}
                  onSelect={this.handlePagination}
                  maxButtons={5}
                  itemsPerPage={this.state.itemsPerPage}
                  totalItems={this.props.totalItems}
                />
              </Row>
            </div>
          </>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ esercente, authentication, categoriaEsercente }: IRootState) => ({
  categorieEsercenti: categoriaEsercente.entities,
  categorieEsercentiLoading: categoriaEsercente.loading,
  esercenteList: esercente.entities,
  totalItems: esercente.totalItems,
  loading: esercente.loading,
  account: authentication.account
});

const mapDispatchToProps = {
  getEntities,
  getCategorieEsercenti
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Esercente);
