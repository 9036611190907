import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IAmministrazioneComunale, defaultValue } from 'app/shared/model/amministrazione-comunale.model';

export const ACTION_TYPES = {
  FETCH_AMMINISTRAZIONECOMUNALE_LIST: 'amministrazioneComunale/FETCH_AMMINISTRAZIONECOMUNALE_LIST',
  FETCH_AMMINISTRAZIONECOMUNALE: 'amministrazioneComunale/FETCH_AMMINISTRAZIONECOMUNALE',
  CREATE_AMMINISTRAZIONECOMUNALE: 'amministrazioneComunale/CREATE_AMMINISTRAZIONECOMUNALE',
  UPDATE_AMMINISTRAZIONECOMUNALE: 'amministrazioneComunale/UPDATE_AMMINISTRAZIONECOMUNALE',
  DELETE_AMMINISTRAZIONECOMUNALE: 'amministrazioneComunale/DELETE_AMMINISTRAZIONECOMUNALE',
  FETCH_SALDO_CHART: 'amministrazioneComunale/FETCH_SALDO_CHART',
  SET_BLOB: 'amministrazioneComunale/SET_BLOB',
  SET_ENTITY_PROPERTY: 'amministrazioneComunale/SET_ENTITY_PROPERTY',
  RESET: 'amministrazioneComunale/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IAmministrazioneComunale>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  errorSaldoChartMessage: null,
  loadingSaldoChart: false,
  saldoChart: null
};

export type AmministrazioneComunaleState = Readonly<typeof initialState>;

// Reducer

export default (state: AmministrazioneComunaleState = initialState, action): AmministrazioneComunaleState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_AMMINISTRAZIONECOMUNALE):
    case REQUEST(ACTION_TYPES.UPDATE_AMMINISTRAZIONECOMUNALE):
    case REQUEST(ACTION_TYPES.DELETE_AMMINISTRAZIONECOMUNALE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.FETCH_SALDO_CHART):
      return {
        ...state,
        loadingSaldoChart: true,
        errorSaldoChartMessage: null
      };
    case FAILURE(ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE):
    case FAILURE(ACTION_TYPES.CREATE_AMMINISTRAZIONECOMUNALE):
    case FAILURE(ACTION_TYPES.UPDATE_AMMINISTRAZIONECOMUNALE):
    case FAILURE(ACTION_TYPES.DELETE_AMMINISTRAZIONECOMUNALE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.FETCH_SALDO_CHART):
      return {
        ...state,
        loadingSaldoChart: false,
        errorSaldoChartMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_AMMINISTRAZIONECOMUNALE):
    case SUCCESS(ACTION_TYPES.UPDATE_AMMINISTRAZIONECOMUNALE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_AMMINISTRAZIONECOMUNALE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.FETCH_SALDO_CHART):
      return {
        ...state,
        loadingSaldoChart: false,
        saldoChart: action.payload.data
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, filename, size } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: filename,
          [name + 'Size']: size
        }
      };
    }
    case ACTION_TYPES.SET_ENTITY_PROPERTY: {
      const { propertyName, value } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [propertyName]: value
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/amministrazione-comunales';

// Actions

export const getEntities: ICrudGetAllAction<IAmministrazioneComunale> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE_LIST,
    payload: axios.get<IAmministrazioneComunale>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IAmministrazioneComunale> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE,
    payload: axios.get<IAmministrazioneComunale>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IAmministrazioneComunale> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_AMMINISTRAZIONECOMUNALE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IAmministrazioneComunale> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_AMMINISTRAZIONECOMUNALE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IAmministrazioneComunale> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_AMMINISTRAZIONECOMUNALE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?, filename?, size?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    filename,
    size
  }
});

export const setEntityProperty = (propertyName, value) => ({
  type: ACTION_TYPES.SET_ENTITY_PROPERTY,
  payload: {
    propertyName,
    value
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getPublicEntity = () => async dispatch => {
  const requestUrl = `api/public/amministrazione-comunale`;
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE,
    payload: axios.get<IAmministrazioneComunale>(requestUrl)
  });
  return result;
};

export const getPublicEntityForCittadino = stringId => {
  const requestUrl = `api/public/cittadinos/amministrazione-comunale/${stringId}`;
  return {
    type: ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE,
    payload: axios.get<IAmministrazioneComunale>(requestUrl)
  };
};

export const getPublicEntityForGuest = stringId => async dispatch => {
  const requestUrl = `api/guest/amministrazione-comunale/${stringId}`;
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_AMMINISTRAZIONECOMUNALE,
    payload: axios.get<IAmministrazioneComunale>(requestUrl)
  });
  return result;
};

export const updatePublicEntity: ICrudPutAction<IAmministrazioneComunale> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_AMMINISTRAZIONECOMUNALE,
    payload: axios.put('api/public/amministrazione-comunale', cleanEntity(entity))
  });
  dispatch(getPublicEntity());
  return result;
};

export const getPublicSaldoChart = () => {
  const requestUrl = `api/public/amministrazione-comunale/saldo-chart`;
  return {
    type: ACTION_TYPES.FETCH_SALDO_CHART,
    payload: axios.get<any>(requestUrl)
  };
};
