import React from 'react';
import { connect } from 'react-redux';
import {Link, Redirect, RouteComponentProps } from 'react-router-dom';
import { Button,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  Alert,
  Collapse,
  Badge
} from 'reactstrap';
import {  AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { setFileData, openFile } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import {
  getPublicEntity as getEntity,
  updatePublicEntity as updateEntity,
  createPublicEntity as createEntity,
  setBlob,
  reset,
  setEntityProperty, setEmailToNullIdEmpty
} from 'app/entities/cittadino/cittadino.reducer';
import { creaAccreditoDiretto } from 'app/entities/transazione/transazione.reducer';
import {checkCittadinoCodicefiscale, checkUserEmail } from "app/shared/util/entity-utils";
import { download } from 'app/entities/document.reducer';
import {StatoRochiedente} from "app/shared/model/enumerations/stato-richiedente";
import fileDownload from "js-file-download";

export interface ICittadinoUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICittadinoUpdateState {
  isNew: boolean;
  userId: string;
  comuneRiferimentoId: string;
  showModal: boolean;
  importo: number;
  changePassword: boolean;
  changePin: boolean;
}

export class CittadinoUpdate extends React.Component<ICittadinoUpdateProps, ICittadinoUpdateState> {

  defaultCittadino = {
    componentiNucleoFamiliare: 1,
    disabiliNucleoFamiliare: 0,
    anzianiNucleoFamiliare: 0,
    minoriNucleoFamiliare: 0,
    quarantenaNucleoFamiliare: 0,
    codicePostale: this.props.account.amministrazioneComunale.codicePostale
  }

  constructor(props) {
    super(props);
    this.state = {
      userId: '0',
      comuneRiferimentoId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      showModal: false,
      importo: 0,
      changePassword: false,
      changePin: false
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

  }

  onBlobChange = (isAnImage, name) => event => {
    const filename = event.target.files[0].name;
    const fileDataCallBackfunction = (contentType, data) => {
      this.props.setBlob(name, data, contentType, filename )
    };

    setFileData(event, fileDataCallBackfunction, isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined, undefined, undefined);
  };

  setEntityProperty = (propertyName, value)  => event => {
    this.props.setEntityProperty(propertyName, value);
  }

  handleInputChange = event => {
    const target = event.target;
    const propertyName = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    if (value && propertyName === 'codiceFiscale') {
      value = value.toUpperCase();

      this.form.props.model[propertyName] = value;
      this.form.reset();
        }
      }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { cittadinoEntity } = this.props;

      const entity = {
        ...cittadinoEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(setEmailToNullIdEmpty(entity));
      } else {
        if (!this.state.changePassword) {
          entity.password = null
        }
        if (!this.state.changePin) {
          entity.pin = null
        }

        this.props.updateEntity(setEmailToNullIdEmpty(entity));
      }
    }
  };

  handleClose = () => {
    const { cittadinoEntity, match } = this.props;
    const { isNew } = this.state;
    if (isNew) {
      this.props.history.push('/amministrazione/richiesta');
    } else {
      if (cittadinoEntity.stato === StatoRochiedente.RICHIESTA_PENDING || cittadinoEntity.stato === StatoRochiedente.RICHIESTA_BOCCIATA ) {
        this.props.history.push('/amministrazione/richiesta');
      } else {
        this.props.history.push('/amministrazione/cittadino');
      }
    }

  };

  componentWillUnmount() {
    this.props.reset();
  }

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  render() {
    const { cittadinoEntity, loading, updating, match } = this.props;
    const { isNew } = this.state;

    const { tessera, tesseraContentType, tesseraUrl, tesseraFileName } = cittadinoEntity;
    const { richiesta, richiestaContentType, richiestaUrl, richiestaFileName } = cittadinoEntity;
    const { ise, iseContentType, iseUrl, iseFileName } = cittadinoEntity;

    if (cittadinoEntity.stato && cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA && match.path === '/amministrazione/richiesta/:id/edit')
      return <Redirect to={'/amministrazione/cittadino/' + this.props.match.params.id + '/edit'} />
    else if (cittadinoEntity.stato && cittadinoEntity.stato !== StatoRochiedente.RICHIESTA_APPROVATA && match.path === '/amministrazione/cittadino/:id/edit')
      return <Redirect to={'/amministrazione/richiesta/' + this.props.match.params.id + '/edit'} />
    else {
      return (
        <div>
          <Row className="justify-content-center">
            <Col md="12">
              <h2
                id="buoniscontoApp.cittadino.home.createOrEditLabel">{isNew ? 'Registra nuovo cittadino' : 'Modifica cittadino'}</h2>
            </Col>
          </Row>
          <hr/>
          <Row className="justify-content-center">
            <Col md="12">
              {loading ? (
                <p>Loading...</p>
              ) : (
                <AvForm model={isNew ? this.defaultCittadino : cittadinoEntity} onSubmit={this.saveEntity}
                        ref={c => (this.form = c)}>
                  <Row>
                    <Col md="6">
                      <Card>
                        <CardHeader>
                          Informazioni cittadino
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" for="cittadino-firstName">Nome</Label>
                            </Col>
                            <Col md="9">
                              {!isNew ?
                                <AvInput type="text" className="form-control" name="userId" readOnly hidden/>
                                :
                                ''
                              }
                              <AvGroup>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  name="firstName"
                                  id="cittadino-firstName"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Nome obbligatorio'
                                    },
                                    maxLength: {
                                      value: 50,
                                      errorMessage: 'Lunghezza massima 50 caratteri'
                                    }
                                  }}
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" for="cittadino-lastName">Cognome</Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  type="text"
                                  className="form-control"
                                  name="lastName"
                                  id="cittadino-lastName"
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Cognome obbligatorio'
                                    },
                                    maxLength: {
                                      value: 50,
                                      errorMessage: 'Lunghezza massima 50 caratteri'
                                    }
                                  }}
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" id="codiceFiscaleLabel" for="cittadino-codiceFiscale">
                                Codice Fiscale
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  id="cittadino-codiceFiscale"
                                  type="text"
                                  name="codiceFiscale"
                                  validate={{
                                    required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                    pattern: {
                                      value: '^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9][A-Za-z0-9][0-9][A-Za-z]$',
                                      errorMessage: 'Non è un codice fiscale valido'
                                    },
                                    async: checkCittadinoCodicefiscale(cittadinoEntity.codiceFiscale)
                                  }}
                                  helpMessage="Il codice fiscale verrà utilizzato dal cittadino come username per accedere all'app"
                                  style={{textTransform: 'uppercase'}}
                                />
                              </AvGroup>
                            </Col>
                          </Row>


                          <Row>
                          <Col md="3" className="text-right">
                            <Label id="codiceFiscaleGenitore1Label" for="cittadino-codiceFiscaleGenitore1">
                              Codice Fiscale Genitore 1
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="cittadino-codiceFiscaleGenitore1"
                                type="text"
                                name="codiceFiscaleGenitore1"
                                validate={{
                                  pattern: {
                                    value: '^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9][A-Za-z0-9][0-9][A-Za-z]$',   
                                    errorMessage: 'Non è un codice fiscale valido'
                                  },
                                  async: checkCittadinoCodicefiscale(cittadinoEntity.codiceFiscaleGenitore1)
                                }}
                                elpMessage="Inserire il codice fiscale del genitore 1"
                                style={{textTransform: 'uppercase'}}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="codiceFiscaleGenitore2Label" for="cittadino-codiceFiscaleGenitore2">
                              Codice Fiscale Genitore 2
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="cittadino-codiceFiscaleGenitore2"
                                type="text"
                                name="codiceFiscaleGenitore2"
                                validate={{
                                  pattern: {
                                    value: '^[A-Za-z]{6}[0-9]{2}[A-Za-z][0-9]{2}[A-Za-z][0-9][A-Za-z0-9][0-9][A-Za-z]$',   
                                    errorMessage: 'Non è un codice fiscale valido'
                                  },
                                  async: checkCittadinoCodicefiscale(cittadinoEntity.codiceFiscaleGenitore1)
                                }}
                                elpMessage="Inserire il codice fiscale del genitore 2"
                                style={{textTransform: 'uppercase'}}
                              />
                            </AvGroup>
                          </Col>
                        </Row>


                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" id="cellulareLabel" for="cittadino-cellulare">
                                Cellulare
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField id="cittadino-cellulare" type="text" name="cellulare"
                                         validate={{
                                           required: {value: true, errorMessage: 'Questo campo è obbligatorio.'}
                                         }}/>
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label for="cittadino-email">Email</Label>
                            </Col>
                            <Col md="9">
                              <AvField
                                name="email"
                                id="cittadino-email"
                                type="email"
                                validate={{
                                  required: {
                                    value: false,
                                    errorMessage: 'Email obbligatoria'
                                  },
                                  email: {
                                    errorMessage: 'Email non valida'
                                  },
                                  minLength: {
                                    value: 5,
                                    errorMessage: 'L\'email deve essere lunga almeno 5 caratteri'
                                  },
                                  maxLength: {
                                    value: 254,
                                    errorMessage: 'L\'email non deve essere più lunga di 5o caratteri'
                                  },
                                  async: checkUserEmail(cittadinoEntity.email)
                                }}
                              />
                            </Col>
                          </Row>


                          <Row>
                            <Col md="3" className="text-right">
                              <Label id="telefonoLabel" for="cittadino-telefono">
                                Telefono
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  id="cittadino-telefono"
                                  type="text"
                                  name="telefono"
                                />
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label id="ibanLabel" for="cittadino-iban">
                                IBAN
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField
                                  id="cittadino-iban"
                                  type="text"
                                  name="iban"
                                  style={{textTransform: 'uppercase'}}
                                />
                              </AvGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="3" className="text-right">
                              <Label id="indirizzoLabel" for="cittadino-indirizzo">
                                Indirizzo
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField id="cittadino-indirizzo" type="text" name="indirizzo"/>
                              </AvGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label id="codicePostaleLabel" for="cittadino-codicePostale">
                                Codice Postale
                              </Label>
                            </Col>
                            <Col md="9">
                              <AvGroup>
                                <AvField id="cittadino-codicePostale" type="text" name="codicePostale"/>
                              </AvGroup>
                            </Col>
                          </Row>
                          {!isNew ? (
                            <AvGroup>
                              <AvInput id="cittadino-id" type="text" className="form-control" name="id" required
                                       readOnly hidden/>
                            </AvGroup>
                          ) : null}
                        </CardBody>
                      </Card>
                      <Card className="mt-3">
                        <CardBody>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label id="tesseraLabel" for="tessera">
                                Documento d'Identità
                              </Label>
                            </Col>
                            <Col md="9">
                              {tesseraUrl ?
                                <div>
                                  <span>DocumentoIdentità.{tesseraUrl.split('.')[tesseraUrl.split('.').length - 1]}</span>
                                  <a className="ml-1"
                                     onClick={this.onDownloadClick.bind(this,`/api/${tesseraUrl}`, 'tessera', tesseraContentType)}>(Download)</a>
                                  <a className="ml-1" onClick={this.setEntityProperty('tesseraUrl', '')}>(Remove)</a>
                                </div>
                                :
                                <div>
                                  {tessera ? (
                                      <div>
                                        <Row>
                                          <Col md="12">
                                          <span>
                                            {tesseraFileName}
                                          </span>
                                            <Badge color="danger" onClick={this.clearBlob('tessera')} className="ml-2" style={{cursor: "pointer"}}>
                                              <FontAwesomeIcon icon="times-circle"/>
                                            </Badge>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                    :
                                    <>
                                      <input id="file_tessera" type="file"
                                             onChange={this.onBlobChange(false, 'tessera')}/>
                                      <AvInput type="hidden" name="tessera" value={tessera}/>
                                    </>
                                  }
                                </div>
                              }
                            </Col>
                          </Row>
                          <hr/>
                          <Row className="pt-2">
                            <Col md="3" className="text-right">
                              <Label id="iseLabel" for="ise">
                                ISE
                              </Label>
                            </Col>
                            <Col md="9">
                              {iseUrl ?
                                <div>
                                  <span>ISE.{iseUrl.split('.')[iseUrl.split('.').length - 1]}</span>
                                  <a className="ml-1"
                                     onClick={this.onDownloadClick.bind(this,`/api/${iseUrl}`, 'ise', iseContentType)}>(Download)</a>
                                  <a className="ml-1" onClick={this.setEntityProperty('iseUrl', '')}>(Remove)</a>
                                </div>
                                :
                                <div>
                                  {ise ? (
                                      <div>
                                        <Row>
                                          <Col md="12">
                                              <span>
                                                {iseFileName}
                                              </span>
                                            <Badge color="danger" onClick={this.clearBlob('ise')} className="ml-2" style={{cursor: "pointer"}}>
                                              <FontAwesomeIcon icon="times-circle"/>
                                            </Badge>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                    :
                                    <>
                                      <input id="file_ise" type="file"
                                             onChange={this.onBlobChange(false, 'ise')}/>
                                      <AvInput type="hidden" name="ise" value={ise}/>
                                    </>
                                  }
                                </div>
                              }
                            </Col>
                          </Row>
                          <hr/>
                          <Row className="pt-2">
                            <Col md="3" className="text-right">
                              <Label id="domandaLabel" for="domanda">
                                Domanda (doc/pdf)
                              </Label>
                            </Col>
                            <Col md="9">
                              {richiestaUrl ?
                                <div>
                                  <span>Domanda.{richiestaUrl.split('.')[richiestaUrl.split('.').length - 1]}</span>
                                  <a className="ml-1"
                                     onClick={this.onDownloadClick.bind(this,`/api/${richiestaUrl}`, 'richiesta', richiestaContentType)}>(Download)</a>
                                  <a className="ml-1" onClick={this.setEntityProperty('richiestaUrl', '')}>(Remove)</a>
                                </div>
                                :
                                <div>
                                  {richiesta ? (
                                      <div>
                                        <Row>
                                          <Col md="12">
                                              <span>
                                                {richiestaFileName}
                                              </span>
                                            <Badge color="danger" onClick={this.clearBlob('richiesta')} className="ml-2" style={{cursor: "pointer"}}>
                                              <FontAwesomeIcon icon="times-circle"/>
                                            </Badge>
                                          </Col>
                                        </Row>
                                      </div>
                                    )
                                    :
                                    <>
                                      <input id="file_richiesta" type="file"
                                             onChange={this.onBlobChange(false, 'richiesta')}/>
                                      <AvInput type="hidden" name="richiesta" value={richiesta}/>
                                    </>
                                  }
                                </div>
                              }
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <Card>
                        <CardHeader>
                          Informazioni Nucleo Familiare del cittadino
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="7" className="text-right">
                              <Label id="componentiNucleoFamiliareLabel" for="cittadino-componentiNucleoFamiliare">
                                <div>N° componenti del nucleo familiare</div>
                                <div style={{marginTop: '-7px'}}><span
                                  style={{fontSize: '10px'}}>(COMPRESO IL RICHIEDENTE)</span></div>
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-componentiNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="componentiNucleoFamiliare"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'},
                                  min: {value: 1, errorMessage: 'Questo campo non può essere minore di 1.'}
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="7" className="text-right">
                              <Label id="disabiliNucleoFamiliareLabel" for="cittadino-disabiliNucleoFamiliare">
                                N° componenti disabili del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-disabiliNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="disabiliNucleoFamiliare"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'}
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="7" className="text-right">
                              <Label id="anzianiNucleoFamiliareLabel" for="cittadino-anzianiNucleoFamiliare">
                                N° componenti anziani del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-anzianiNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="anzianiNucleoFamiliare"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'}
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="7" className="text-right">
                              <Label id="minoriNucleoFamiliareLabel" for="cittadino-minoriNucleoFamiliare">
                                N° componenti minori del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-minoriNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="minoriNucleoFamiliare"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'}
                                }}
                              />
                            </Col>
                          </Row>
                          <hr/>
                          <Row>
                            <Col xs="12">
                              <Alert color="danger">
                                <FontAwesomeIcon icon="exclamation-triangle"/> Sezione dedicata all'emergenza COVID-19
                              </Alert>
                            </Col>
                            <Col md="7" className="text-right">
                              <Label id="quarantenaNucleoFamiliareLabel" for="cittadino-quarantenaNucleoFamiliare">
                                N° componenti in qurantena del nucleo familiare
                              </Label>
                            </Col>
                            <Col md="5">
                              <AvField
                                id="cittadino-quarantenaNucleoFamiliare"
                                type="number"
                                className="form-control"
                                name="quarantenaNucleoFamiliare"
                                validate={{
                                  required: {value: true, errorMessage: 'Questo campo è obbligatorio.'},
                                  number: {value: true, errorMessage: 'Questo campo deve essere numerico.'}
                                }}
                              />
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      {(isNew || cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA) &&
                        <Card className="mt-3">
                          <CardHeader>
                            Informazioni d'accesso
                          </CardHeader>
                          <CardBody>
                            {!isNew &&
                            <Row>
                              <Col xs="12">
                                <Button color="primary" size="sm" block onClick={() => {
                                  this.setState({changePassword: !this.state.changePassword})
                                }} style={{marginBottom: '1rem'}}>
                                  {this.state.changePassword ? "ANNULLA Cambia Password" : "Cambia Password"}
                                </Button>
                              </Col>
                            </Row>
                            }
                            <Collapse isOpen={this.state.changePassword || isNew}>
                              <Row>
                                <Col md="3" className="text-right">
                                  <Label className="required" for="password">Password</Label>
                                </Col>
                                <Col md="9">
                                  <AvField
                                    name="password"
                                    type="password"
                                    placeholder="Inserisci password"
                                    autoComplete="new-password"
                                    validate={{
                                      required: {
                                        value: (isNew || this.state.changePassword),
                                        errorMessage: 'Password obbligatoria'
                                      },
                                      minLength: {
                                        value: 4,
                                        errorMessage: 'La password deve essere lunga almeno 4 caratteri'
                                      },
                                      maxLength: {
                                        value: 50,
                                        errorMessage: 'La password non deve essere più lunga di 50 caratteri'
                                      }
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col md="3" className="text-right">
                                  <Label className="required" for="confirmPassword">Conferma password</Label>
                                </Col>
                                <Col>
                                  <AvField
                                    name="confirmPassword"
                                    placeholder="Inserisci conferma password"
                                    type="password"
                                    validate={{
                                      required: {
                                        value: (isNew || this.state.changePassword),
                                        errorMessage: 'Conferma password è obbligatorio'
                                      },
                                      minLength: {
                                        value: 4,
                                        errorMessage: 'Inserire almeno 4 caratteri'
                                      },
                                      maxLength: {
                                        value: 50,
                                        errorMessage: 'Lunghezza massima 50 caratteri'
                                      },
                                      match: {
                                        value: 'password',
                                        errorMessage: 'Conferma password non corretta!'
                                      }
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Collapse>

                            {!isNew &&
                            <Row>
                              <Col xs="12">
                                <Button color="primary" size="sm" block onClick={() => {
                                  this.setState({changePin: !this.state.changePin})
                                }} style={{marginBottom: '1rem'}}>
                                  {this.state.changePin ? "ANNULLA Cambia PIN" : "Cambia PIN"}
                                </Button>
                              </Col>
                            </Row>
                            }
                            <Collapse isOpen={this.state.changePin || isNew}>
                              <Row>
                                <Col md="3" className="text-right">
                                  <Label className="required" id="pinLabel" for="cittadino-pin">
                                    Pin
                                  </Label>
                                </Col>
                                <Col md="5">
                                  <AvField
                                    id="cittadino-pin"
                                    type="string"
                                    className="form-control"
                                    name="pin"
                                    placeholder={'Inserisci PIN'}
                                    validate={{
                                      required: {
                                        value: (isNew || this.state.changePin),
                                        errorMessage: 'Questo campo è obbligatorio.'
                                      },
                                      number: {value: true, errorMessage: 'Questo campo deve contenere solo numeri.'},
                                      pattern: {
                                        value: '^[0-9]*$',
                                        errorMessage: 'Questo campo deve contenere solo numeri.'
                                      },
                                      minLength: {
                                        value: 5,
                                        errorMessage: 'Il Pin deve contenere 5 cifre'
                                      },
                                      maxLength: {
                                        value: 5,
                                        errorMessage: 'Il Pin deve contenere 5 cifre'
                                      }
                                    }}
                                    helpMessage="PIN segreto composto da 5 caratteri numerici"
                                  />
                                </Col>
                              </Row>
                            </Collapse>
                          </CardBody>
                        </Card>
                      }
                    </Col>
                  </Row>
                  <hr/>
                  <Row className="text-right">
                    <Col>
                      <Button tag={Link} id="cancel-save" to={cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ? '/amministrazione/cittadino' : '/amministrazione/richiesta'} replace color="info">
                        <FontAwesomeIcon icon="arrow-left"/>
                        &nbsp;
                        <span className="d-none d-md-inline">Elenco {cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ? 'cittadini' : 'richieste'}</span>
                      </Button>
                      &nbsp;
                      <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                        <FontAwesomeIcon icon="save"/>
                        &nbsp; Salva
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              )}
            </Col>
          </Row>
        </div>
      );
    }
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  amministrazioneComunales: storeState.amministrazioneComunale.entities,
  cittadinoEntity: storeState.cittadino.entity,
  loading: storeState.cittadino.loading,
  updating: storeState.cittadino.updating,
  updateSuccess: storeState.cittadino.updateSuccess,
  transazioneUpdating: storeState.transazione.updating,
  transazioneupdateSuccess: storeState.transazione.updateSuccess,
  account: storeState.authentication.account,
  document: storeState.document
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  setEntityProperty,
  setBlob,
  createEntity,
  reset,
  creaAccreditoDiretto,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CittadinoUpdate);
