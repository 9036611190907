import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem, NavItem, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink as Link } from 'react-router-dom';
import { NavDropdown } from './menu-components';
import { connect } from 'react-redux';
import { Home, IHomeProp } from 'app/modules/home/home';
import { AUTHORITIES } from 'app/config/constants';
import {AccountMenu} from "app/shared/layout/menus/account";
import EsercentiMenu from "app/shared/layout/menus/esercenti";

export type IEntitiesMenuProp = StateProps;

export const EntitiesMenuComponent = ({ account }: IEntitiesMenuProp) => {
  if (account && account.authorities && account.authorities.find(auth => auth === AUTHORITIES.ADMIN)) {
    return (
      <NavDropdown icon="th-list" name="Entities" id="entity-menu">
        <MenuItem icon="asterisk" to="/amministrazione-comunale">
          Gestione Amministrazioni Comunali
        </MenuItem>
        <MenuItem icon="asterisk" to="/cittadino">
          Gestione Cittadini
        </MenuItem>
        <MenuItem icon="asterisk" to="/categoria-esercente">
          Gestione Categorie Esercenti
        </MenuItem>
        <MenuItem icon="asterisk" to="/esercente">
          Gestione Esercenti
        </MenuItem>
        <MenuItem icon="asterisk" to="/transazione">
          Gestione Transazioni
        </MenuItem>
        <MenuItem icon="asterisk" to="/voucher">
          Gestione Voucher
        </MenuItem>
        {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
      </NavDropdown>
    );
  } else if (account && account.authorities && account.authorities.find(auth => auth === AUTHORITIES.AMMINISTRAZIONE)) {
    return (
      <>
        <NavItem>
          <NavLink tag={Link} to="/amministrazione/richiesta" className="d-flex align-items-center">
            <FontAwesomeIcon icon="folder-open" fixedWidth />
            <span>Richieste</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/amministrazione/cittadino" className="d-flex align-items-center">
            <FontAwesomeIcon icon="users" fixedWidth />
            <span>Cittadini</span>
          </NavLink>
        </NavItem>
        {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate ?
          <EsercentiMenu/>
          :
          <NavItem>
            <NavLink tag={Link} to="/amministrazione/esercente" className="d-flex align-items-center">
              <FontAwesomeIcon icon="shopping-cart" fixedWidth />
              <span>Esercenti</span>
            </NavLink>
          </NavItem>
        }
        <NavItem>
          <NavLink tag={Link} to="/amministrazione/transazione" className="d-flex align-items-center">
            <FontAwesomeIcon icon="euro-sign" fixedWidth />
            <span>Transazioni</span>
          </NavLink>
        </NavItem>
      </>
    );
  } else {
    return <></>;
  }
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export const EntitiesMenu = connect(mapStateToProps)(EntitiesMenuComponent);
