import './home.scss';

import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row, Col, Alert, Card, CardBody, CardTitle, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, InputGroup, InputGroupAddon, InputGroupText, Form, FormGroup, FormFeedback, Spinner, UncontrolledAlert } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getPublicSaldoChart } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
import { getPublicEntities as getCittadini } from 'app/entities/cittadino/cittadino.reducer';
import { getPublicEntities as getEsercenti } from 'app/entities/esercente/esercente.reducer';
import { creaDotazione } from 'app/entities/transazione/transazione.reducer';
import {getSession} from "app/shared/reducers/authentication";
import ReactEcharts from 'echarts-for-react';
import moment from "moment";

export interface IHomeAmministrazioneProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IHomeAmministrazioneState {
  showModal: boolean;
  importo: number;
}

export class HomeAmministrazione extends React.Component<IHomeAmministrazioneProps, IHomeAmministrazioneState> {
  state: IHomeAmministrazioneState = {
    showModal: false,
    importo: null
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      importo: null
    };
  }

  componentDidMount() {
    this.props.getCittadini(0, 10, `saldoDisponibile,desc`, 'stato=approvata');
    this.props.getEsercenti(0, 10, `saldo,desc`, null);
    this.props.getPublicSaldoChart();    
  }

  componentDidUpdate(propsPrecedenti) {
    if (this.props.transazioneUpdateSuccess !== propsPrecedenti.transazioneUpdateSuccess && this.props.transazioneUpdateSuccess === true) {
      this.handleCloseModal();
      this.setState({importo: null});
    }
  }

  creaDotazione = event => {
    if (this.state.importo && !isNaN(this.state.importo) && this.state.importo !== 0) {
      const transazioneEntity = { importo: this.state.importo }
      this.props.creaDotazione(transazioneEntity);
    }
    // this.handleCloseModal();
  };

  handleChange = event => {
    this.setState({importo: event.target.value});
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleOpen = () => {
    this.setState({ showModal: true });
  };


  render() {
    const { account, cittadini, cittadiniTotali, esercenti, esercentiTotali, transazioneUpdating, transazioneUpdateSuccess, saldoChart } = this.props;
    const { showModal, importo } = this.state;
    const valid = importo && !isNaN(importo) && importo !== 0;

    const lastThirtyDays = new Array();
    const lastThirtyValues = new Array();
    saldoChart && saldoChart.forEach(el => {
      console.log(el);
      // lastThirtyDays.push(moment().startOf("day").subtract(i, "days").format('DD/MM'));
      lastThirtyDays.push(moment(el.date).format('DD/MM'));
      lastThirtyValues.push(el.saldo);
    });

    const option = {
      color: ['#990000'],
      legend: {
          data: ['Saldo']
      },
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'cross',
              label: {
                  backgroundColor: '#990000'
              }
          }
      },
      xAxis: {
        type: 'category',
        data: lastThirtyDays
      },
      yAxis: {
        type: 'value'
      },
      series: [{
        name: 'Saldo',
        data: lastThirtyValues, 
        type: 'line'
      }]
    };

    return (
      <>
        <Row>
          <Col md="8">
            <h2>Benvenuto, {account.firstName + ' ' + account.lastName}</h2>
          </Col>
        </Row>
        <hr />
        {transazioneUpdateSuccess &&
          <Row>
            <Col>
              <UncontrolledAlert color="success">
                Transazione salvata con successo.
              </UncontrolledAlert>
            </Col>
          </Row>
        }
        <Row>
          <Col md="2"></Col>
          <Col md="8">
            <Row>
              <Col md="4">
                <Card style={{ backgroundColor: '#06c', color: '#fff' }} className="p-2">
                  <Row>
                    <Col className="col-md-9">
                      BENEFICIARI
                      <h3><FontAwesomeIcon icon="users" fixedWidth className="mr-3"/>{cittadiniTotali}</h3>
                    </Col>
                    <Col className="col-md-3">
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{ backgroundColor: '#06c', color: '#fff' }} className="p-2">
                  <Row>
                    <Col className="col-md-9">
                      ESERCENTI
                      <h3><FontAwesomeIcon icon="shopping-cart" fixedWidth className="mr-4"/>{esercentiTotali}</h3>
                    </Col>
                    <Col className="col-md-3">
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{ backgroundColor: '#06c', color: '#fff' }} className="p-2">
                  <Row>
                    <Col className="col-md-9">
                      RESIDUO
                      <h3>€ {account && account.amministrazioneComunale && account.amministrazioneComunale.saldo ? account.amministrazioneComunale.saldo.toLocaleString() : 0}</h3>
                    </Col>
                    <Col className="col-md-3">
                      <Button onClick={() => this.handleOpen()} color="success" size="lg" className="mt-2" style={{ borderRadius: '40px' }}>
                        <FontAwesomeIcon icon="plus" />
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md="12">
            <Card style={{ backgroundColor: '#0066cc' }} className="p-2">
              <CardTitle style={{ fontWeight: 'bold', color: '#fff' }}>
                Andamento saldo ultimo mese
              </CardTitle>
              <CardBody style={{padding: '0.25rem' }}>
                <div style={{padding: '0.25rem', backgroundColor: '#fff'  }}>
                  <ReactEcharts option={option}/>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md="12">
            <Row>
              <Col md="6">
                <Card style={{ backgroundColor: '#06c' }} className="p-2">
                  <CardTitle style={{ fontWeight: 'bold', color: '#fff' }}>
                    SALDI ESERCENTE
                    <Button tag={Link} to={`/amministrazione/esercente/new`} color="primary" size="sm" className="float-right">Nuovo esercente</Button>
                  </CardTitle>
                  <CardBody style={{ padding: '0.25rem' }}>
                    <Table responsive striped style={{ backgroundColor: '#fff' }}>
                      <thead>
                        <tr>
                          <th>Esercente</th>
                          <th>Saldo</th>
                          <th>N. transazioni</th>
                          <th>Scontrino medio</th>
                        </tr>
                      </thead>
                      <tbody>
                        {esercenti.map((esercente, i) => (
                          <tr>
                            <td>
                              <Button tag={Link} to={`/amministrazione/esercente/${esercente.id}`} color="link" size="sm">
                              {esercente.ragioneSociale}
                              </Button>
                            </td>
                            <td>€ {esercente.saldo ? esercente.saldo.toLocaleString() : 0}</td>
                            <td>n.d.</td>
                            <td>n.d.</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div>
                      <Button tag={Link} to={`/amministrazione/esercente`} color="link" size="sm" className="float-right" style={{ color: '#fff' }}>Vai a Gestione Esercenti ></Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card style={{ backgroundColor: '#0066cc' }} className="p-2">
                  <CardTitle style={{ fontWeight: 'bold', color: '#fff' }}>
                    SALDI CITTADINO
                    <Button tag={Link} to={`/amministrazione/richiesta/new`} color="primary" size="sm" className="float-right">Nuova richiesta</Button>
                  </CardTitle>
                  <CardBody style={{ backgroundColor: '#06c', padding: '0.25rem' }}>
                    <Table responsive striped style={{ backgroundColor: '#fff' }}>
                      <thead>
                        <tr>
                          <th>Cittadino</th>
                          <th>Saldo</th>
                          <th>N. transazioni</th>
                          <th>Scontrino medio</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cittadini.map((cittadino, i) => (
                          <tr>
                            <td>
                              <Button tag={Link} to={`/amministrazione/cittadino/${cittadino.id}`} color="link" size="sm">
                              {cittadino.user && cittadino.user.firstName + ' ' + cittadino.user.lastName}
                              </Button>
                            </td>
                            <td>€ {cittadino.saldoDisponibile ? cittadino.saldoDisponibile.toLocaleString() : 0}</td>
                            <td>n.d.</td>
                            <td>n.d.</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <div>
                      <Button tag={Link} to={`/amministrazione/cittadino`} color="link" size="sm" className="float-right" style={{ color: '#fff' }}>Vai a Gestione Cittadini ></Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal isOpen={showModal} toggle={this.handleCloseModal}>
          <ModalHeader toggle={this.handleCloseModal}>Aggiungi saldo</ModalHeader>
          <ModalBody>
            {transazioneUpdating &&
              <div className="text-center">
                <h5>salvataggio transazione in corso...</h5>
                <Spinner color="dark" />
              </div>
            }
            {!transazioneUpdating &&
              <>
                {<p>Il saldo corrente è: <strong>€ {account && account.amministrazioneComunale && account.amministrazioneComunale.saldo ? account.amministrazioneComunale.saldo.toLocaleString() : 0}</strong>.</p>}
                <p>Indica di seguito l'importo da aggiungere al saldo corrente</p>
                <Form>
                  <FormGroup>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>€</InputGroupText>
                      </InputGroupAddon>
                      <Input
                          id="saldo"
                          type="number"
                          className="form-control"
                          onChange={this.handleChange}
                          value={importo}
                          valid={ valid }
                          invalid={ !valid }
                          name="saldo"
                          placeholder="indica l'importo"
                          validate={{
                            required: { value: true, errorMessage: 'Questo campo è obbligatorio.' },
                            number: { value: true, errorMessage: 'Questo campo deve essere numerico.' }
                          }}
                        />
                      <InputGroupAddon addonType="append">
                        <Button color="success" onClick={this.creaDotazione} disabled={!valid || transazioneUpdating}>aggiungi</Button>
                      </InputGroupAddon>
                    </InputGroup>
                    <FormFeedback
                      valid={ valid }
                      invalid={ !valid }>Il campo importo è obbligatorio e deve essere un numerico</FormFeedback>
                  </FormGroup>
                </Form>
              </>
            }
            {/* <p className="pt-4">Riepilogo transazioni:</p>
            <Table responsive striped size="sm">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Importo inserito</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    12/03/2020
                  </td>
                  <td>€ { (2000).toLocaleString()}</td>
                </tr>
              </tbody>
            </Table> */}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.handleCloseModal}>Chiudi</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  cittadini: storeState.cittadino.entities,
  cittadiniTotali: storeState.cittadino.totalItems,
  esercenti: storeState.esercente.entities,
  esercentiTotali: storeState.esercente.totalItems,
  transazioneUpdating: storeState.transazione.updating,
  transazioneUpdateSuccess: storeState.transazione.updateSuccess,
  saldoChart: storeState.amministrazioneComunale.saldoChart
});

const mapDispatchToProps = {
  getCittadini,
  getEsercenti,
  getPublicSaldoChart,
  creaDotazione,
  getSession
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeAmministrazione);
