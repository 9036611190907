import { ITransazione } from 'app/shared/model/transazione.model';
import { IUser } from 'app/shared/model/user.model';
import { IAmministrazioneComunale } from 'app/shared/model/amministrazione-comunale.model';
import { ICategoriaEsercente } from 'app/shared/model/categoria-esercente.model';
import { IVoucher } from 'app/shared/model/voucher.model';

export interface IEsercente {
  id?: number;
  partitaIva?: string;
  ragioneSociale?: string;
  codiceAtecoPrincipale?: string;
  referente?: string;
  email?: string;
  telefono?: string;
  cellulare?: string;
  indirizzo?: string;
  codicePostale?: string;
  comune?: string;
  provincia?: string;
  iban?: string;
  banca?: string;
  saldo?: number;
  saldoVouchers?: number;
  consegnaDomicilio?: boolean;
  transaziones?: ITransazione[];
  operatoris?: IUser[];
  comuneRiferimento?: IAmministrazioneComunale;
  categoriaEsercente?: ICategoriaEsercente;
  vouchers?: IVoucher[];
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
}

export const defaultValue: Readonly<IEsercente> = {
  consegnaDomicilio: false
};
