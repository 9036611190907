import React from 'react';
import {
  Badge} from 'reactstrap';
import {TipologiaTransazione} from "app/shared/model/enumerations/tipologia-transazione.model";

export interface ITipologiaTransazioneViewProps {
  tipo?: string;
}

export const TipologiaTransazioneView = (props: ITipologiaTransazioneViewProps) => {
  const { tipo } = props;

  let tipologiaTransazione;
  let color;
  if (tipo === TipologiaTransazione.TT_05_ACCREDITO_DIRETTO) {
    tipologiaTransazione = 'ACCREDITO DIRETTO';
    color = 'primary';
  } else if (tipo === TipologiaTransazione.TT_04_LIQUIDAZIONE) {
    tipologiaTransazione = 'LIQUIDAZIONE';
    color = 'secondary';
  } else if (tipo === TipologiaTransazione.TT_07_BUONO_SPESA) {
    tipologiaTransazione = 'BUONO SPESA';
    color = 'info';
  } else if (tipo === TipologiaTransazione.TT_01_DOTAZIONE) {
    tipologiaTransazione = 'DOTAZIONE';
    color = 'warning';
  } else if (tipo === TipologiaTransazione.TT_06_ANTICIPO_SPESA) {
    tipologiaTransazione = 'ANTICIPO SPESA';
    color = 'success';
  } else if (tipo === TipologiaTransazione.TT_08_ASSEGNAZIONE) {
    tipologiaTransazione = 'ASSEGNAZIONE VOUCHERS';
    color = 'danger';
  } else if (tipo === TipologiaTransazione.TT_09_VOUCHER_SPESA) {
    tipologiaTransazione = 'UTILIZZO BUONO VOUCHER';
    color = 'dark';
  }

  return (
    <Badge color={color}>{tipologiaTransazione}</Badge>
  );
}
