import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { ICrudSearchTransactionAction } from 'app/shared/reducers/custom-redux-action.type';

import { ITransazione, defaultValue } from 'app/shared/model/transazione.model';
import { getSession } from 'app/shared/reducers/authentication';
import { ICittadino } from 'app/shared/model/cittadino.model';

export const ACTION_TYPES = {
  FETCH_TRANSAZIONE_LIST: 'transazione/FETCH_TRANSAZIONE_LIST',
  FETCH_TRANSAZIONE_WITH_TOTAL_LIST: 'transazione/FETCH_TRANSAZIONE_WITH_TOTAL_LIST',
  FETCH_TRANSAZIONE: 'transazione/FETCH_TRANSAZIONE',
  CREATE_TRANSAZIONE: 'transazione/CREATE_TRANSAZIONE',
  UPDATE_TRANSAZIONE: 'transazione/UPDATE_TRANSAZIONE',
  DELETE_TRANSAZIONE: 'transazione/DELETE_TRANSAZIONE',
  SUM_TRANSAZIONE: 'transazione/SUM_TRANSAZIONE',
  SET_BLOB: 'transazione/SET_BLOB',
  RESET: 'transazione/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ITransazione>,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  total: 0,
  sum: 0,
  sumLoading: false
};

export type TransazioneState = Readonly<typeof initialState>;

// Reducer

export default (state: TransazioneState = initialState, action): TransazioneState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.SUM_TRANSAZIONE):
      return {
        ...state,
        sum: 0,
        sumLoading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_TRANSAZIONE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_TRANSAZIONE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_TRANSAZIONE):
    case REQUEST(ACTION_TYPES.UPDATE_TRANSAZIONE):
    case REQUEST(ACTION_TYPES.DELETE_TRANSAZIONE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case REQUEST(ACTION_TYPES.FETCH_TRANSAZIONE_WITH_TOTAL_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
        total: 0
      };

    case FAILURE(ACTION_TYPES.SUM_TRANSAZIONE):
      return {
        ...state,
        sum: 0,
        sumLoading: false
      };
    case FAILURE(ACTION_TYPES.FETCH_TRANSAZIONE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TRANSAZIONE_WITH_TOTAL_LIST):
    case FAILURE(ACTION_TYPES.FETCH_TRANSAZIONE):
    case FAILURE(ACTION_TYPES.CREATE_TRANSAZIONE):
    case FAILURE(ACTION_TYPES.UPDATE_TRANSAZIONE):
    case FAILURE(ACTION_TYPES.DELETE_TRANSAZIONE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
        total: 0
      };
    case SUCCESS(ACTION_TYPES.SUM_TRANSAZIONE):
      return {
        ...state,
        sum: action.payload.data,
        sumLoading: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRANSAZIONE_WITH_TOTAL_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data.transazioni,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
        total: action.payload.data.total
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRANSAZIONE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_TRANSAZIONE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_TRANSAZIONE):
    case SUCCESS(ACTION_TYPES.UPDATE_TRANSAZIONE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_TRANSAZIONE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, filename, size } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: filename,
          [name + 'Size']: size
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/transaziones';
const apiUrlPublic = 'api/public/transaziones';
const apiUrlPublicAmministrazione = 'api/public/amministrazione-comunale';
const apiUrlPublicCittadino = 'api/public/cittadinos/transaziones';

// Actions

export const getEntities: ICrudGetAllAction<ITransazione> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TRANSAZIONE_LIST,
    payload: axios.get<ITransazione>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntitiesForCittadino: ICrudGetAllAction<ITransazione> = (
  page,
  size,
  sort,
  categoriaEsercenteId,
  esercenteId,
  tipoTransazione
) => {
  const apiUrl = tipoTransazione === 'voucher' ? apiUrlPublicCittadino + '-voucher' : apiUrlPublicCittadino;
  const requestUrl =
    `${apiUrl}` +
    `${categoriaEsercenteId !== undefined ? `/${categoriaEsercenteId}` : ''}` +
    `${esercenteId !== undefined ? `/${esercenteId}` : ''}` +
    `${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_TRANSAZIONE_WITH_TOTAL_LIST,
    payload: axios.get<ITransazione>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ITransazione> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_TRANSAZIONE,
    payload: axios.get<ITransazione>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<ITransazione> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSAZIONE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ITransazione> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TRANSAZIONE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ITransazione> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TRANSAZIONE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?, filename?, size?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    filename,
    size
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getPublicEntities = (page, size, sort, searchFilterParams) => {
  let params = '';
  if (sort) {
    params += `?page=${page}&size=${size}&sort=${sort}`;
  }
  if (searchFilterParams) {
    params += `${sort ? '&' : '?'}${searchFilterParams}`;
  }
  params += `${sort || searchFilterParams ? '&' : '?'}cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrlPublic}${params}`;
  return {
    type: ACTION_TYPES.FETCH_TRANSAZIONE_LIST,
    payload: axios.get<ITransazione>(`${requestUrl}`)
  };
};

export const sumAllTransazioni = (page, size, sort, searchFilterParams) => {
  let params = '';
  if (sort) {
    params += `?page=${page}&size=${size}&sort=${sort}`;
  }
  if (searchFilterParams) {
    params += `${sort ? '&' : '?'}${searchFilterParams}`;
  }
  params += `${sort || searchFilterParams ? '&' : '?'}cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrlPublic}-stats${params}`;
  return {
    type: ACTION_TYPES.SUM_TRANSAZIONE,
    payload: axios.get<ITransazione>(`${requestUrl}`)
  };
};

export const searchTransazioni: ICrudSearchTransactionAction<ITransazione> = (cittadino, esercente, page, size, sort) => {
  let queryParam = `${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  queryParam = queryParam !== '' && cittadino ? `${queryParam}&cittadino=${cittadino}` : cittadino ? `?cittadino=${cittadino}` : queryParam;
  queryParam = queryParam !== '' && esercente ? `${queryParam}&esercente=${esercente}` : esercente ? `?esercente=${esercente}` : queryParam;

  const requestUrl = `${apiUrlPublic}${queryParam}`;
  return {
    type: ACTION_TYPES.FETCH_TRANSAZIONE_LIST,
    payload: axios.get<ITransazione>(`${requestUrl}${queryParam !== '' ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const creaDotazione: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSAZIONE,
    payload: axios.post(apiUrlPublicAmministrazione + '/crea-dotazione', entity)
  });
  dispatch(getSession());
  return result;
};

export const creaAccreditoDiretto: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSAZIONE,
    payload: axios.post(apiUrlPublicAmministrazione + '/crea-accredito-diretto', entity)
  });
  dispatch(getSession());
  return result;
};

export const creaAssegnazione: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSAZIONE,
    payload: axios.post(apiUrlPublicAmministrazione + '/crea-assegnazione', entity)
  });
  dispatch(getSession());
  return result;
};

export const creaLiquidazione: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSAZIONE,
    payload: axios.post(apiUrlPublicAmministrazione + '/crea-liquidazione', entity)
  });
  dispatch(getSession());
  return result;
};

export const creaAnticipoSpesa: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSAZIONE,
    payload: axios.post(apiUrlPublicAmministrazione + '/crea-anticipo-spesa', entity)
  });
  dispatch(getSession());
  return result;
};

export const creaDotazioneVoucher: any = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TRANSAZIONE,
    payload: axios.post(apiUrlPublicAmministrazione + '/crea-dotazione-voucher', entity)
  });
  dispatch(getSession());
  return result;
};
