import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { NavDropdown } from './menu-components';

const accountMenuItems = (
  <Button color="light" tag={Link} to="/login" size="lg" outline style={{paddingLeft: '50px', paddingRight: '50px'}}>
    <FontAwesomeIcon icon="sign-in-alt"/>
    &nbsp; Accedi
  </Button>
);

export const AccountMenu = ({ isAuthenticated = false, account }) => (
  <>
    {isAuthenticated ?
      <NavDropdown icon="user" id="account-menu" name={' '}>
        <>
          <MenuItem icon="wrench" to="/account/settings">
            Impostazioni
          </MenuItem>
          <MenuItem icon="lock" to="/account/password">
            Password
          </MenuItem>
          <MenuItem icon="sign-out-alt" to="/logout">
            Logout
          </MenuItem>
          <div style={{padding: '0.25rem 1.5rem'}}>
            {account.id ? account.lastName + ' ' + account.firstName : ''}
          </div>
        </>
      </NavDropdown>
    :
      <>
        {accountMenuItems}
      </>
    }
  </>
);

export default AccountMenu;
