import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  Input,
  Collapse
} from 'reactstrap';
import {  AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getPublicEntities as getCategorieEsercenti } from 'app/entities/categoria-esercente/categoria-esercente.reducer';
import { getPublicEntity as getEntity, updatePublicEntity as updateEntity, createPublicEntity as createEntity, reset } from 'app/entities/esercente/esercente.reducer';
import { creaLiquidazione } from 'app/entities/transazione/transazione.reducer';
import {checkUserEmail} from 'app/shared/util/entity-utils';

export interface IEsercenteUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEsercenteUpdateState {
  isNew: boolean;
  comuneRiferimentoId: string;
  changePassword: boolean;
  categoriaEsercente: any;
}

export class EsercenteUpdate extends React.Component<IEsercenteUpdateProps, IEsercenteUpdateState> {
  defaultEsercente = {}

  constructor(props) {
    super(props);
    this.state = {
      comuneRiferimentoId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id,
      changePassword: false,
      categoriaEsercente: null
    };

    this.defaultEsercente = {
      comune: this.props.account.amministrazioneComunale.comune,
      codicePostale: this.props.account.amministrazioneComunale.codicePostale,
      provincia: this.props.account.amministrazioneComunale.provincia
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    var _that = this;
    if (this.props.account.amministrazioneComunale.categorieAbilitate) {
      this.props.getCategorieEsercenti().then(res1=> {
        if (this.state.isNew) {
          this.props.reset();
          this.setState({categoriaEsercente: this.props.categorieEsercenti[0].id});
        } else {
          this.props.getEntity(this.props.match.params.id).then(res2 => {
            this.setState({categoriaEsercente: this.props.esercenteEntity.categoriaEsercente.id});
          });
        }
      });
    } else {
      if (this.state.isNew) {
        this.props.reset();
      } else {
        this.props.getEntity(this.props.match.params.id);
      }
    }
  }

  handleChangeCategoria = event => {
    this.setState({categoriaEsercente: event.target.value});
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { esercenteEntity } = this.props;

      const operatore = {
        login: values.operatoreEmail,
        firstName: values.operatoreFirstName,
        lastName: values.operatoreLastName,
        email: values.operatoreEmail
      }

      if (values.operatorePassword) {
        operatore.password = values.operatorePassword;
      }
      if (values.operatoreId) {
        operatore.id = values.operatoreId;
      }

      const operatoris = [operatore];

      delete values.operatoreFirstName;
      delete values.operatoreLastName;
      delete values.operatoreEmail;
      delete values.operatorePassword;
      delete values.operatoreId;

      const entity = {
        ...esercenteEntity,
        ...values,
        operatoris
      };

      if (this.props.account.amministrazioneComunale.categorieAbilitate) {
        entity.categoriaEsercente = {
          id: this.state.categoriaEsercente
        };
      }

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        if (!this.state.changePassword) {
          entity.operatoris[0].password = null
        }
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/amministrazione/esercente');
  };


  render() {
    const { account, categorieEsercenti, categorieEsercentiLoading, esercenteEntity, loading, updating } = this.props;
    const { isNew, categoriaEsercente } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="12">
            <h2 id="buoniscontoApp.esercente.home.createOrEditLabel">{isNew ? 'Registra nuovo esercente' : 'Modifica esercente'}</h2>
          </Col>
        </Row>
        <hr />
        <Row className="justify-content-center">
          <Col md="12">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? this.defaultEsercente : esercenteEntity} onSubmit={this.saveEntity}>
                <Row>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni esercente
                      </CardHeader>
                      <CardBody>
                        {!isNew ? (
                          <AvGroup>
                            <AvInput id="esercente-id" type="text" className="form-control" name="id" required readOnly hidden/>
                          </AvGroup>
                        ) : null}
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="ragioneSocialeLabel" for="esercente-ragioneSociale" className="required">
                              Ragione Sociale
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              id="esercente-ragioneSociale"
                              type="text"
                              name="ragioneSociale"
                              validate={{
                                required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="telefonoLabel" for="esercente-telefono">
                              Telefono
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              id="esercente-telefono"
                              type="text"
                              name="telefono"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="indirizzoLabel" for="esercente-indirizzo" className="required">
                              Indirizzo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-indirizzo" type="text" name="indirizzo"
                              validate={{
                                required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                            }}/>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="codicePostaleLabel" for="esercente-codicePostale">
                              Codice Postale
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-codicePostale" type="text" name="codicePostale" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="comuneLabel" for="esercente-comune">
                              Comune
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-comune" type="text" name="comune" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="provinciaLabel" for="esercente-provincia">
                              Provincia
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-provincia" type="text" name="provincia" />
                          </Col>
                        </Row>
                        {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                          <Row>
                            <Col md="3" className="text-right pt-1">
                              <Label id="categoriaEsercenteLabel" for="esercente-categoriaEsercente" className="required">
                                Categoria Esercente
                              </Label>
                            </Col>
                            <Col md="9">
                              <Row>
                                  {categorieEsercentiLoading &&
                                    <Col xs="auto" style={{paddingTop: '7px', paddingRight: '0px'}}><FontAwesomeIcon icon='spinner' spin/></Col>
                                  }
                                  <Col >
                                    <Input name="categoriaEsercente" id="categoriaEsercente" type="select" className="form-control"
                                       validate={{
                                         required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                                       }}
                                           onChange={this.handleChangeCategoria}
                                           disabled={categorieEsercentiLoading}
                                           value={categoriaEsercente}
                                    >
                                      {categorieEsercenti
                                        ? categorieEsercenti.map(categoriaEsercente => (
                                          <option value={categoriaEsercente.id} key={categoriaEsercente.id}>
                                            {categoriaEsercente.denominazione}
                                          </option>
                                        ))
                                        : null}
                                    </Input>
                                  </Col>
                                  <Col xs={12}>
                                    <Link to="/amministrazione/categoria-esercente">
                                      <FontAwesomeIcon icon="plus" />
                                      &nbsp; Gestisci Categoria Esercente
                                    </Link>
                                  </Col>
                              </Row>

                            </Col>
                          </Row>
                        }
                        {!isNew ?
                          <AvGroup>
                            <AvInput type="text" className="form-control" name="operatoreId" readOnly hidden
                                     value={esercenteEntity.operatoris ? esercenteEntity.operatoris[0].id : ''}/>
                          </AvGroup>
                          :
                          ''
                        }
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni referente
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="referenteLabel" for="esercente-referente" className="required">
                              Referente Nominativo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField
                              id="esercente-referente"
                              type="text"
                              name="referente"
                              validate={{
                                required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="emailLabel" for="esercente-email">
                              Referente Email
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-email" type="text" name="email" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right pt-1">
                            <Label id="cellulareLabel" for="esercente-cellulare" className="required">
                              Referente Cellulare
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvField id="esercente-cellulare" type="text" name="cellulare"
                               validate={{
                                 required: { value: true, errorMessage: 'Questo campo è obbligatorio.' }
                               }}/>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni Generali
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="codiceAtecoPrincipaleLabel" for="esercente-codiceAtecoPrincipale">
                              Codice Ateco Principale
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvField id="esercente-codiceAtecoPrincipale" type="text" name="codiceAtecoPrincipale" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="ibanLabel" for="esercente-iban">
                              IBAN
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvField id="esercente-iban" type="text" name="iban" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="bancaLabel" for="esercente-banca">
                              Banca
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvField id="esercente-banca" type="text" name="banca" />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="partitaIvaLabel" for="esercente-partitaIva" className="required">
                              Partita Iva
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvField
                              id="esercente-partitaIva"
                              type="text"
                              name="partitaIva"
                              validate={{
                                required: { value: true, errorMessage: 'Questo campo è obbligatorio.' },
                                pattern: {value: '^[0-9]{11}$', errorMessage: 'Non è una partita IVA valida'}
                              }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right pt-1">
                            <Label id="consegnaDomicilioLabel">
                              Consegna Domicilio
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvInput id="esercente-consegnaDomicilio" type="select" className="form-control" name="consegnaDomicilio"
                                     value={isNew ? "false" : esercenteEntity.consegnaDomicilio}>
                              <option value="true">SI</option>
                              <option value="false">NO</option>
                            </AvInput>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni Accesso Utente
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="operatoreFirstName" className="required">Accesso Utente (Nome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="operatoreFirstName"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Nome obbligatorio'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Il nome non può essere più lungo di 50 caratteri'
                                  }
                                }}
                                value={esercenteEntity.operatoris ? esercenteEntity.operatoris[0].firstName : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="operatoreLastName" className="required">Accesso Utente (Cognome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="operatoreLastName"
                                validate={{
                                  required: {
                                    value: true,
                                    errorMessage: 'Cognome obbligatorio'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Il cognome non può essere più lungo di 50 caratteri'
                                  }
                                }}
                                value={esercenteEntity.operatoris ? esercenteEntity.operatoris[0].lastName : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="operatoreEmail" className="required">Accesso Utente (Email)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                name="operatoreEmail"
                                type="email"
                                readOnly={!isNew}
                                validate={{
                                  required: {
                                    value: isNew,
                                    errorMessage: 'Email obbligatoria'
                                  },
                                  email: {
                                    errorMessage: 'Email non valida'
                                  },
                                  minLength: {
                                    value: 5,
                                    errorMessage: 'L\'email deve essere lunga almeno 5 caratteri'
                                  },
                                  maxLength: {
                                    value: 254,
                                    errorMessage: 'L\'email non deve essere più lunga di 5o caratteri'
                                  },
                                  async: checkUserEmail((esercenteEntity.operatoris && esercenteEntity.operatoris[0]) ? esercenteEntity.operatoris[0].email : null)
                                }}
                                value={(esercenteEntity.operatoris && esercenteEntity.operatoris[0]) ? esercenteEntity.operatoris[0].email : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>



                        {!isNew &&
                          <Row>
                            <Col xs="12">
                              <Button color="primary" size="sm" block onClick={() => {this.setState({changePassword: !this.state.changePassword})}} style={{marginBottom: '1rem'}}>
                                {this.state.changePassword ? "ANNULLA Cambia Password" : "Cambia Password"}
                              </Button>
                            </Col>
                          </Row>
                        }
                        <Collapse isOpen={this.state.changePassword || isNew}>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" for="operatorePassword">Password</Label>
                            </Col>
                            <Col md="9">
                              <AvField
                                name="operatorePassword"
                                type="password"
                                placeholder="Inserisci password"
                                autoComplete="new-password"
                                validate={{
                                  required: { value: (isNew || this.state.changePassword), errorMessage: 'Password obbligatoria' },
                                  minLength: { value: 4, errorMessage: 'La password deve essere lunga almeno 4 caratteri' },
                                  maxLength: { value: 50, errorMessage: 'La password non deve essere più lunga di 50 caratteri' }
                                }}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md="3" className="text-right">
                              <Label className="required" for="confirmPassword">Conferma password</Label>
                            </Col>
                            <Col>
                              <AvField
                                name="confirmPassword"
                                placeholder="Inserisci conferma password"
                                type="password"
                                validate={{
                                  required: {
                                    value: (isNew || this.state.changePassword),
                                    errorMessage: 'Conferma password è obbligatorio'
                                  },
                                  minLength: {
                                    value: 4,
                                    errorMessage: 'Inserire almeno 4 caratteri'
                                  },
                                  maxLength: {
                                    value: 50,
                                    errorMessage: 'Lunghezza massima 50 caratteri'
                                  },
                                  match: {
                                    value: 'operatorePassword',
                                    errorMessage: 'Conferma password non corretta!'
                                  }
                                }}
                              />
                            </Col>
                          </Row>
                        </Collapse>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <hr />
                <Row className="text-right">
                  <Col>
                    <Button tag={Link} id="cancel-save" to="/amministrazione/esercente" replace color="info">
                      <FontAwesomeIcon icon="arrow-left" />
                      &nbsp;
                      <span className="d-none d-md-inline">Elenco esercenti</span>
                    </Button>
                    &nbsp;
                    <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                      <FontAwesomeIcon icon="save" />
                      &nbsp; Salva
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  categorieEsercenti: storeState.categoriaEsercente.entities,
  categorieEsercentiLoading: storeState.categoriaEsercente.loading,
  esercenteEntity: storeState.esercente.entity,
  loading: storeState.esercente.loading,
  updating: storeState.esercente.updating,
  updateSuccess: storeState.esercente.updateSuccess,
  account: storeState.authentication.account
});

const mapDispatchToProps = {
  getCategorieEsercenti,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  creaLiquidazione
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EsercenteUpdate);
