import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IEsercente, defaultValue } from 'app/shared/model/esercente.model';

export const ACTION_TYPES = {
  FETCH_ESERCENTE_LIST: 'esercente/FETCH_ESERCENTE_LIST',
  FETCH_ESERCENTE: 'esercente/FETCH_ESERCENTE',
  CREATE_ESERCENTE: 'esercente/CREATE_ESERCENTE',
  UPDATE_ESERCENTE: 'esercente/UPDATE_ESERCENTE',
  DELETE_ESERCENTE: 'esercente/DELETE_ESERCENTE',
  FETCH_ESERCENTE_VOUCHER_LIST: 'esercente/FETCH_ESERCENTE_VOUCHER_LIST',
  RESET: 'esercente/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IEsercente>,
  esercentiVoucherMancanti: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type EsercenteState = Readonly<typeof initialState>;

// Reducer

export default (state: EsercenteState = initialState, action): EsercenteState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ESERCENTE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ESERCENTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_ESERCENTE_VOUCHER_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_ESERCENTE):
    case REQUEST(ACTION_TYPES.UPDATE_ESERCENTE):
    case REQUEST(ACTION_TYPES.DELETE_ESERCENTE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_ESERCENTE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_ESERCENTE_VOUCHER_LIST):
    case FAILURE(ACTION_TYPES.FETCH_ESERCENTE):
    case FAILURE(ACTION_TYPES.CREATE_ESERCENTE):
    case FAILURE(ACTION_TYPES.UPDATE_ESERCENTE):
    case FAILURE(ACTION_TYPES.DELETE_ESERCENTE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_ESERCENTE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_ESERCENTE_VOUCHER_LIST):
      return {
        ...state,
        loading: false,
        esercentiVoucherMancanti: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_ESERCENTE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_ESERCENTE):
    case SUCCESS(ACTION_TYPES.UPDATE_ESERCENTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_ESERCENTE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/esercentes';
const apiUrlPublic = 'api/public/esercentes';

// Actions

export const getEntities: ICrudGetAllAction<IEsercente> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_ESERCENTE_LIST,
    payload: axios.get<IEsercente>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IEsercente> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_ESERCENTE,
    payload: axios.get<IEsercente>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IEsercente> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ESERCENTE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<IEsercente> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ESERCENTE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<IEsercente> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ESERCENTE,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const getPublicEntities = (page, size, sort, searchFilterParams) => {
  let params = '';
  if (sort) {
    params += `?page=${page}&size=${size}&sort=${sort}`;
  }
  if (searchFilterParams) {
    params += `${sort ? '&' : '?'}${searchFilterParams}`;
  }
  params += `${sort || searchFilterParams ? '&' : '?'}cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrlPublic}${params}`;
  return {
    type: ACTION_TYPES.FETCH_ESERCENTE_LIST,
    payload: axios.get<IEsercente>(`${requestUrl}`)
  };
};

export const getPublicEsercenti: any = (comuneStringId, page, size, sort) => async dispatch => {
  const requestUrl = `${apiUrlPublic}-public/${comuneStringId}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_ESERCENTE_LIST,
    payload: axios.get<IEsercente>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  });
  return result;
};

export const getPublicEsercentiVoucher: any = () => {
  const requestUrl = `${apiUrlPublic}/saldo-voucher-esercenti`;
  return {
    type: ACTION_TYPES.FETCH_ESERCENTE_VOUCHER_LIST,
    payload: axios.get<any>(`${requestUrl}?cacheBuster=${new Date().getTime()}`)
  };
};

export const getPublicEntity: ICrudGetAction<IEsercente> = id => async dispatch => {
  const requestUrl = `${apiUrlPublic}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_ESERCENTE,
    payload: axios.get<IEsercente>(requestUrl)
  });
  return result;
};

export const createPublicEntity: ICrudPutAction<IEsercente> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_ESERCENTE,
    payload: axios.post(apiUrlPublic, cleanEntity(entity))
  });
  return result;
};

export const updatePublicEntity: ICrudPutAction<IEsercente> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_ESERCENTE,
    payload: axios.put(apiUrlPublic, cleanEntity(entity))
  });
  return result;
};

export const deletePublicEntity: ICrudDeleteAction<IEsercente> = id => async dispatch => {
  const requestUrl = `${apiUrlPublic}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_ESERCENTE,
    payload: axios.delete(requestUrl)
  });
  return result;
};
