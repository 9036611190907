import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { openFile, byteSize, ICrudGetAllAction, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './transazione.reducer';
import { ITransazione } from 'app/shared/model/transazione.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';

export interface ITransazioneProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export type ITransazioneState = IPaginationBaseState;

export class Transazione extends React.Component<ITransazioneProps, ITransazioneState> {
  state: ITransazioneState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE)
  };

  componentDidMount() {
    this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = () => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`);
  };

  render() {
    const { transazioneList, match, totalItems } = this.props;
    return (
      <div>
        <h2 id="transazione-heading">
          Transazioni
          <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Crea una nuova Transazione
          </Link>
        </h2>
        <div className="table-responsive">
          {transazioneList && transazioneList.length > 0 ? (
            <Table responsive aria-describedby="transazione-heading">
              <thead>
                <tr>
                  <th className="hand" onClick={this.sort('id')}>
                    ID <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('uuid')}>
                    Uuid <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('tipo')}>
                    Tipo <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('importo')}>
                    Importo <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('stato')}>
                    Stato <FontAwesomeIcon icon="sort" />
                  </th>
                  <th className="hand" onClick={this.sort('scontrino')}>
                    Scontrino <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    Comune Riferimento <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    Cittadino <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    Esercente <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {transazioneList.map((transazione, i) => (
                  <tr key={`entity-${i}`}>
                    <td>
                      <Button tag={Link} to={`${match.url}/${transazione.id}`} color="link" size="sm">
                        {transazione.id}
                      </Button>
                    </td>
                    <td>{transazione.uuid}</td>
                    <td>{transazione.tipo}</td>
                    <td>{transazione.importo}</td>
                    <td>{transazione.stato}</td>
                    <td>
                      {transazione.scontrino ? (
                        <div>
                          <a onClick={openFile(transazione.scontrinoContentType, transazione.scontrino)}>Open &nbsp;</a>
                          <span>
                            {transazione.scontrinoContentType}, {byteSize(transazione.scontrino)}
                          </span>
                        </div>
                      ) : null}
                    </td>
                    <td>
                      {transazione.comuneRiferimento ? (
                        <Link to={`amministrazione-comunale/${transazione.comuneRiferimento.id}`}>{transazione.comuneRiferimento.id}</Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {transazione.cittadino ? <Link to={`cittadino/${transazione.cittadino.id}`}>{transazione.cittadino.id}</Link> : ''}
                    </td>
                    <td>
                      {transazione.esercente ? <Link to={`esercente/${transazione.esercente.id}`}>{transazione.esercente.id}</Link> : ''}
                    </td>
                    <td className="text-right">
                      <div className="btn-group flex-btn-group-container">
                        <Button tag={Link} to={`${match.url}/${transazione.id}`} color="info" size="sm">
                          <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Visualizza</span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${transazione.id}/edit`} color="primary" size="sm">
                          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Modifica</span>
                        </Button>
                        <Button tag={Link} to={`${match.url}/${transazione.id}/delete`} color="danger" size="sm">
                          <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Elimina</span>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="alert alert-warning">Nessuna transazione trovata</div>
          )}
        </div>
        <div className={transazioneList && transazioneList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={this.state.activePage}
              onSelect={this.handlePagination}
              maxButtons={5}
              itemsPerPage={this.state.itemsPerPage}
              totalItems={this.props.totalItems}
            />
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ transazione }: IRootState) => ({
  transazioneList: transazione.entities,
  totalItems: transazione.totalItems
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transazione);
