import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, setFileData, openFile, byteSize, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IAmministrazioneComunale } from 'app/shared/model/amministrazione-comunale.model';
import { getEntities as getAmministrazioneComunales } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
import { getEntity, updateEntity, createEntity, setBlob, reset } from 'app/entities/cittadino/cittadino.reducer';
import { ICittadino } from 'app/shared/model/cittadino.model';
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ICittadinoUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface ICittadinoUpdateState {
  isNew: boolean;
  userId: string;
  comuneRiferimentoId: string;
}

export class CittadinoUpdate extends React.Component<ICittadinoUpdateProps, ICittadinoUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      userId: '0',
      comuneRiferimentoId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getUsers();
    this.props.getAmministrazioneComunales();
  }

  onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => this.props.setBlob(name, data, contentType), isAnImage);
  };

  clearBlob = name => () => {
    this.props.setBlob(name, undefined, undefined, undefined, undefined);
  };

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { cittadinoEntity } = this.props;
      const entity = {
        ...cittadinoEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/cittadino');
  };

  render() {
    const { cittadinoEntity, users, amministrazioneComunales, loading, updating } = this.props;
    const { isNew } = this.state;

    const { foto, fotoContentType } = cittadinoEntity;
    const { tessera, tesseraContentType } = cittadinoEntity;
    const { richiesta, richiestaContentType } = cittadinoEntity;
    const { ise, iseContentType } = cittadinoEntity;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="buoniscontoApp.cittadino.home.createOrEditLabel">{isNew ? 'Registra nuovo cittadino' : 'Modifica cittadino'}</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : cittadinoEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="cittadino-id">ID</Label>
                    <AvInput id="cittadino-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="codiceFiscaleLabel" for="cittadino-codiceFiscale">
                    Codice Fiscale
                  </Label>
                  <AvField
                    id="cittadino-codiceFiscale"
                    type="text"
                    name="codiceFiscale"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="telefonoLabel" for="cittadino-telefono">
                    Telefono
                  </Label>
                  <AvField
                    id="cittadino-telefono"
                    type="text"
                    name="telefono"
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="cellulareLabel" for="cittadino-cellulare">
                    Cellulare
                  </Label>
                  <AvField id="cittadino-cellulare" type="text" name="cellulare"
                           validate={{
                             required: { value: true, errorMessage: 'This field is required.' }
                           }}/>
                </AvGroup>
                <AvGroup>
                  <Label id="indirizzoLabel" for="cittadino-indirizzo">
                    Indirizzo
                  </Label>
                  <AvField id="cittadino-indirizzo" type="text" name="indirizzo" />
                </AvGroup>
                <AvGroup>
                  <Label id="codicePostaleLabel" for="cittadino-codicePostale">
                    Codice Postale
                  </Label>
                  <AvField id="cittadino-codicePostale" type="text" name="codicePostale" />
                </AvGroup>
                <AvGroup>
                  <Label id="componentiNucleoFamiliareLabel" for="cittadino-componentiNucleoFamiliare">
                    Componenti Nucleo Familiare
                  </Label>
                  <AvField
                    id="cittadino-componentiNucleoFamiliare"
                    type="string"
                    className="form-control"
                    name="componentiNucleoFamiliare"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="disabiliNucleoFamiliareLabel" for="cittadino-disabiliNucleoFamiliare">
                    Disabili Nucleo Familiare
                  </Label>
                  <AvField
                    id="cittadino-disabiliNucleoFamiliare"
                    type="string"
                    className="form-control"
                    name="disabiliNucleoFamiliare"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="anzianiNucleoFamiliareLabel" for="cittadino-anzianiNucleoFamiliare">
                    Anziani Nucleo Familiare
                  </Label>
                  <AvField
                    id="cittadino-anzianiNucleoFamiliare"
                    type="string"
                    className="form-control"
                    name="anzianiNucleoFamiliare"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="minoriNucleoFamiliareLabel" for="cittadino-minoriNucleoFamiliare">
                    Anziani Nucleo Familiare
                  </Label>
                  <AvField
                    id="cittadino-minoriNucleoFamiliare"
                    type="string"
                    className="form-control"
                    name="minoriNucleoFamiliare"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="quarantenaNucleoFamiliareLabel" for="cittadino-quarantenaNucleoFamiliare">
                    Quarantena Nucleo Familiare
                  </Label>
                  <AvField
                    id="cittadino-quarantenaNucleoFamiliare"
                    type="string"
                    className="form-control"
                    name="quarantenaNucleoFamiliare"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="saldoDisponibileLabel" for="cittadino-saldoDisponibile">
                    Saldo Disponibile
                  </Label>
                  <AvField
                    id="cittadino-saldoDisponibile"
                    type="string"
                    className="form-control"
                    name="saldoDisponibile"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="pinLabel" for="cittadino-pin">
                    Pin
                  </Label>
                  <AvField
                    id="cittadino-pin"
                    type="string"
                    className="form-control"
                    name="pin"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <AvGroup>
                    <Label id="tesseraLabel" for="tessera">
                      Documento d'Identità
                    </Label>
                    <br />
                    {tessera ? (
                      <div>
                        <a onClick={openFile(tesseraContentType, tessera)}>Open</a>
                        <br />
                        <Row>
                          <Col md="11">
                            <span>
                              {tesseraContentType}, {byteSize(tessera)}
                            </span>
                          </Col>
                          <Col md="1">
                            <Button color="danger" onClick={this.clearBlob('tessera')}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                    <input id="file_tessera" type="file" onChange={this.onBlobChange(false, 'tessera')} />
                    <AvInput type="hidden" name="tessera" value={tessera} />
                  </AvGroup>
                </AvGroup>
                <AvGroup>
                  <AvGroup>
                    <Label id="richiestaLabel" for="richiesta">
                      Domanda
                    </Label>
                    <br />
                    {richiesta ? (
                      <div>
                        <a onClick={openFile(richiestaContentType, richiesta)}>Open</a>
                        <br />
                        <Row>
                          <Col md="11">
                            <span>
                              {richiestaContentType}, {byteSize(richiesta)}
                            </span>
                          </Col>
                          <Col md="1">
                            <Button color="danger" onClick={this.clearBlob('richiesta')}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                    <input id="file_richiesta" type="file" onChange={this.onBlobChange(false, 'richiesta')} />
                    <AvInput type="hidden" name="richiesta" value={richiesta} />
                  </AvGroup>
                </AvGroup>
                <AvGroup>
                  <AvGroup>
                    <Label id="iseLabel" for="ise">
                      ISE
                    </Label>
                    <br />
                    {ise ? (
                      <div>
                        <a onClick={openFile(iseContentType, ise)}>Open</a>
                        <br />
                        <Row>
                          <Col md="11">
                            <span>
                              {iseContentType}, {byteSize(ise)}
                            </span>
                          </Col>
                          <Col md="1">
                            <Button color="danger" onClick={this.clearBlob('ise')}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : null}
                    <input id="file_ise" type="file" onChange={this.onBlobChange(false, 'ise')} />
                    <AvInput type="hidden" name="ise" value={ise} />
                  </AvGroup>
                </AvGroup>
                <AvGroup>
                  <Label for="cittadino-user">User</Label>
                  <AvInput id="cittadino-user" type="select" className="form-control" name="user.id">
                    <option value="" key="0" />
                    {users
                      ? users.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <AvGroup>
                  <Label for="cittadino-comuneRiferimento">Comune Riferimento</Label>
                  <AvInput id="cittadino-comuneRiferimento" type="select" className="form-control" name="comuneRiferimento.id">
                    <option value="" key="0" />
                    {amministrazioneComunales
                      ? amministrazioneComunales.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/cittadino" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  amministrazioneComunales: storeState.amministrazioneComunale.entities,
  cittadinoEntity: storeState.cittadino.entity,
  loading: storeState.cittadino.loading,
  updating: storeState.cittadino.updating,
  updateSuccess: storeState.cittadino.updateSuccess
});

const mapDispatchToProps = {
  getUsers,
  getAmministrazioneComunales,
  getEntity,
  updateEntity,
  setBlob,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CittadinoUpdate);
