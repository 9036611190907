import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
// import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import amministrazioneComunale, {
  AmministrazioneComunaleState
} from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
// prettier-ignore
import cittadino, {
  CittadinoState
} from 'app/entities/cittadino/cittadino.reducer';
// prettier-ignore
import esercente, {
  EsercenteState
} from 'app/entities/esercente/esercente.reducer';
// prettier-ignore
import transazione, {
  TransazioneState
} from 'app/entities/transazione/transazione.reducer';
// prettier-ignore
import categoriaEsercente, {
  CategoriaEsercenteState
} from 'app/entities/categoria-esercente/categoria-esercente.reducer';
// prettier-ignore
import document, {
  DocumentState
} from 'app/entities/document.reducer';
import richiesta, { RichiestaState } from 'app/entities/richiesta/richiesta.reducer';

/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  // readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly amministrazioneComunale: AmministrazioneComunaleState;
  readonly cittadino: CittadinoState;
  readonly richiesta: RichiestaState;
  readonly esercente: EsercenteState;
  readonly transazione: TransazioneState;
  readonly categoriaEsercente: CategoriaEsercenteState;
  readonly document: DocumentState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  // register,
  activate,
  passwordReset,
  password,
  settings,
  amministrazioneComunale,
  cittadino,
  richiesta,
  esercente,
  transazione,
  categoriaEsercente,
  document,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar
});

export default rootReducer;
