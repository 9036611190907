import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Cittadino from './cittadino';
import Richiesta from './cittadino-pending';
import CittadinoDetail from './cittadino-detail';
import CittadinoUpdate from './cittadino-update';
import CittadinoDeleteDialog from './cittadino-delete-dialog';
import RichiestaDeleteDialog from './richiesta-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={CittadinoUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={CittadinoUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={CittadinoDetail} />
      {match.url === '/amministrazione/cittadino' &&
        <ErrorBoundaryRoute path={match.url} component={Cittadino} />
      }
      {match.url === '/amministrazione/richiesta' &&
        <ErrorBoundaryRoute path={match.url} component={Richiesta} />
      }
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete-richiesta`} component={RichiestaDeleteDialog} />
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={CittadinoDeleteDialog} />
  </>
);

export default Routes;
