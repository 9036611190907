import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import AmministrazioneComunale from './amministrazione-comunale';
import AmministrazioneComunaleDetail from './amministrazione-comunale-detail';
import AmministrazioneComunaleUpdate from './amministrazione-comunale-update';
import AmministrazioneComunaleDeleteDialog from './amministrazione-comunale-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={AmministrazioneComunaleUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={AmministrazioneComunaleUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={AmministrazioneComunaleDetail} />
      <ErrorBoundaryRoute path={match.url} component={AmministrazioneComunale} />
    </Switch>
    <ErrorBoundaryRoute path={`${match.url}/:id/delete`} component={AmministrazioneComunaleDeleteDialog} />
  </>
);

export default Routes;
