import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button,
  Spinner,
  Row,
  Table,
  Col,
  Card,
  CardHeader,
  Collapse,
  CardBody,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Input,
  Badge,
  Label} from 'reactstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {
  TextFormat,
  getSortState,
  IPaginationBaseState,
  JhiPagination,
  JhiItemCount
} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {getPublicEntities as getEntities, sumAllTransazioni} from 'app/entities/transazione/transazione.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { download } from 'app/entities/document.reducer';
import {APP_DATE_FORMAT} from "app/config/constants";
import {itaLocale} from "app/shared/util/date-utils";
import {checkImportoConVirgola, getUrlParameter} from "app/shared/util/misc-utils";
import moment from "moment";
import {TipologiaTransazioneView} from "app/modules/components/tipologia-transazione-view";
import { getPublicEntities as getCategorieEsercenti } from 'app/entities/categoria-esercente/categoria-esercente.reducer';
import axios from "axios";
import fileDownload from "js-file-download";

export interface ITransazioneProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface ITransazioneState extends IPaginationBaseState {
  showSearchFilters: boolean;
  tipoFilter: string;
  nomeFilter: string;
  cognomeFilter: string;
  ragioneSocialeFilter: string;
  importoFromFilter: string;
  importoToFilter: string;
  createdFromFilter: string;
  createdToFilter: string;
  categoriaEsercenteFilter: string;
  exporting: boolean;
}

export class Transazione extends React.Component<ITransazioneProps, ITransazioneState> {
  state: ITransazioneState = {
    ...getSortState(this.props.location, ITEMS_PER_PAGE),
    showSearchFilters: false,
    tipoFilter: '',
    nomeFilter: '',
    cognomeFilter: '',
    ragioneSocialeFilter: '',
    importoFromFilter: '',
    importoToFilter: '',
    createdFromFilter: '',
    createdToFilter: '',
    categoriaEsercenteFilter: '',
    exporting: false
  };

  searchFilterParams = '';

  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  componentDidMount() {
    const tipoFilter = getUrlParameter('tipo.equals', this.props.location.search) ? getUrlParameter('tipo.equals', this.props.location.search) : this.state.tipoFilter;
    const nomeFilter = getUrlParameter('cittadinoUserFirstName.contains', this.props.location.search) ? getUrlParameter('cittadinoUserFirstName.contains', this.props.location.search) : '';
    const cognomeFilter = getUrlParameter('cittadinoUserLastName.contains', this.props.location.search) ? getUrlParameter('cittadinoUserLastName.contains', this.props.location.search) : '';
    const ragioneSocialeFilter = getUrlParameter('esercenteRagioneSociale.contains', this.props.location.search) ? getUrlParameter('esercenteRagioneSociale.contains', this.props.location.search) : '';
    const importoFromFilter = getUrlParameter('importo.greaterThanOrEqual', this.props.location.search) ? getUrlParameter('importo.greaterThanOrEqual', this.props.location.search) : '';
    const importoToFilter = getUrlParameter('importo.lessThan', this.props.location.search) ? getUrlParameter('importo.lessThan', this.props.location.search) : '';
    const createdFromFilter = getUrlParameter('createdDate.greaterThanOrEqual', this.props.location.search) ? moment(getUrlParameter('createdDate.greaterThanOrEqual', this.props.location.search)).format('DD/MM/YYYY') : '';
    const createdToFilter = getUrlParameter('createdDate.lessThan', this.props.location.search) ? moment(getUrlParameter('createdDate.lessThan', this.props.location.search)).format('DD/MM/YYYY') : '';
    const categoriaEsercenteFilter = getUrlParameter('categoriaEsercenteDenominazione.equals', this.props.location.search) ? getUrlParameter('categoriaEsercenteDenominazione.equals', this.props.location.search) : this.state.categoriaEsercenteFilter;

    this.props.getCategorieEsercenti();

    this.setState({
      tipoFilter,
      nomeFilter,
      cognomeFilter,
      ragioneSocialeFilter,
      importoFromFilter,
      importoToFilter,
      createdFromFilter,
      createdToFilter,
      categoriaEsercenteFilter
    }, () => this.handleFilter(null, true));

    //this.getEntities();
  }

  sort = prop => () => {
    this.setState(
      {
        order: this.state.order === 'asc' ? 'desc' : 'asc',
        sort: prop
      },
      () => this.sortEntities()
    );
  };

  sortEntities() {
    this.getEntities();
    if (this.searchFilterParams && this.searchFilterParams.length > 0) {
      this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}&${this.searchFilterParams}`);
    } else {
      this.props.history.push(`${this.props.location.pathname}?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}`);
    }
  }

  handlePagination = activePage => this.setState({ activePage }, () => this.sortEntities());

  getEntities = (isFirstLoading = false) => {
    const { activePage, itemsPerPage, sort, order } = this.state;
    if (!isFirstLoading) {
      if (this.searchFilterParams && this.searchFilterParams.length > 0) {
        this.props.history.push(`${this.props.location.pathname}?${this.searchFilterParams}` + (sort ? `&page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}` : ''));
      } else {
        this.props.history.push(`${this.props.location.pathname}` + (sort ? `?page=${this.state.activePage}&sort=${this.state.sort},${this.state.order}` : ''));
      }
    }
    this.props.getEntities(activePage - 1, itemsPerPage, `${sort},${order}`, this.searchFilterParams);
    this.props.sumAllTransazioni(activePage - 1, itemsPerPage, `${sort},${order}`, this.searchFilterParams);
  };

  handleFilter(event, isFirstLoading = false) {
    if (event) {
      event.preventDefault();
    }

    const { tipoFilter,
      nomeFilter,
      cognomeFilter,
      ragioneSocialeFilter,
      importoFromFilter,
      importoToFilter,
      createdFromFilter,
      createdToFilter,
      categoriaEsercenteFilter
    } = this.state;

    let searchFilterParams = ''
    if (tipoFilter) {
      searchFilterParams += '&tipo.equals='+tipoFilter
    }
    if (nomeFilter) {
      searchFilterParams += '&cittadinoUserFirstName.contains='+nomeFilter
    }
    if (cognomeFilter) {
      searchFilterParams += '&cittadinoUserLastName.contains='+cognomeFilter
    }
    if (ragioneSocialeFilter) {
      searchFilterParams += '&esercenteRagioneSociale.contains='+ragioneSocialeFilter
    }
    if (importoFromFilter) {
      searchFilterParams += '&importo.greaterThanOrEqual='+importoFromFilter
    }
    if (importoToFilter) {
      searchFilterParams += '&importo.lessThan='+importoToFilter
    }
    if (createdFromFilter) {
      searchFilterParams += '&createdDate.greaterThanOrEqual='+moment(createdFromFilter, 'DD/MM/YYYY').toISOString()
    }
    if (createdToFilter) {
      searchFilterParams += '&createdDate.lessThan='+moment(createdToFilter, 'DD/MM/YYYY').toISOString()
    }
    if (categoriaEsercenteFilter) {
      searchFilterParams += '&categoriaEsercenteDenominazione.equals='+categoriaEsercenteFilter
    }

    if (searchFilterParams.length > 0) {
      this.searchFilterParams = searchFilterParams.replace('&', '');
      this.setState({
        showSearchFilters: true
      })
    } else {
      this.searchFilterParams = '';
    }
    this.getEntities(isFirstLoading);
  }

  handleChangeCategoria = event => {
    this.setState({categoriaEsercenteFilter: event.target.value});
  };

  resetSearch = () => {
    this.setState({
      tipoFilter: '',
      nomeFilter: '',
      cognomeFilter: '',
      ragioneSocialeFilter: '',
      importoFromFilter: '',
      importoToFilter: '',
      createdFromFilter: '',
      createdToFilter: '',
      categoriaEsercenteFilter: ''
    }, () => this.handleFilter(null));
  }

  handleInputChange(event, picker) {
    let target = event.target;
    let value = target.value && target.value;
    let propName = target.name && target.name

    if (picker) {
      value = picker.startDate.format('DD/MM/YYYY')
      propName = picker.element.find('input').attr('name')
    }

    this.setState({
      [propName]: value
    });
  }

  exportExcel = () =>  {
    const { sort, order } = this.state;

    const exportRequestUrl = this.getXlsEntitiesRequestUrl(0, 1000000, `${sort},${order}`, this.searchFilterParams);
    this.setState({exporting: true});
    let _that = this;
    axios({
      url: exportRequestUrl,
      method: 'GET',
      responseType: 'arraybuffer' // important
    }).then(response => {
      // handle success
      const filename = response.headers["content-disposition"].split('; filename=')[1];
      const blobType = response.headers["content-type"];
      const url = window.URL.createObjectURL(new Blob([response.data],
        {type: blobType}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    }).catch(function (error) {
      // handle error
      _that.setState({exporting: false});
    }).then(function () {
      // always executed
      _that.setState({exporting: false});
    });;
  }

  getXlsEntitiesRequestUrl = (page, size, sort, searchFilterParams) => {
    let params = '';
    if (sort) {
      params += `?page=${page}&size=${size}&sort=${sort}`;
    }
    if (searchFilterParams) {
      params += `${sort ? '&' : '?'}${searchFilterParams}`;
    }
    params += `${(sort || searchFilterParams) ? '&' : '?'}cacheBuster=${new Date().getTime()}`;
    return (`api/public/transaziones-export${params}`);
  };

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  render() {
    const { account, transazioneList, totalItems, loading, categorieEsercenti, categorieEsercentiLoading, totalImport, totalImportoLoading } = this.props;
    const { showSearchFilters, exporting } = this.state;
    const { tipoFilter,
      nomeFilter,
      cognomeFilter,
      ragioneSocialeFilter,
      importoFromFilter,
      importoToFilter,
      createdFromFilter,
      createdToFilter,
      categoriaEsercenteFilter
    } = this.state;

    return (
      <div>
        <h2 id="esercente-heading">
          Elenco Transazioni
          <Button color="excel" className="float-right ml-3" disabled={exporting || loading || !(totalItems && totalItems > 0)} onClick={this.exportExcel}>
            <FontAwesomeIcon icon={exporting ? 'spinner' : 'file-excel' } spin={exporting} />
            &nbsp; Esporta transazioni
          </Button>
        </h2>
        <hr />
        <Col xs="12" className="mb-3">
          <Card>
            <CardHeader style={{padding: '0px'}}>
              <Row>
                <Col xs="6" md={{ size: 10, offset: 1 }} className="text-md-center text-sm-left">
                  <a className="btn" data-target="#searchFilters" onClick={() => this.setState({showSearchFilters: !showSearchFilters})}>
                    <h6>Cerca <FontAwesomeIcon icon="search" /></h6>
                  </a>
                </Col>
                <Col xs="6" md={{ size: 1 }} className="text-right">
                  <a className="btn" data-target="#searchFilters" onClick={() => this.setState({showSearchFilters: !showSearchFilters})}>
                    <h6><FontAwesomeIcon icon={showSearchFilters ? "chevron-circle-down" : "chevron-circle-right"} /></h6>
                  </a>
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={this.state.showSearchFilters} id="searchFilters">
              <CardBody>
                <form onSubmit={this.handleFilter}>
                  <Row>
                    <Col xs="12" md="3">
                      <Label htmlFor="createdFromFilter">Data creazione - (dal)</Label><br />
                      <DateRangePicker containerStyles={{width: '100%'}} singleDatePicker onApply={this.handleInputChange} locale={itaLocale}>
                        <InputGroup size="sm">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><FontAwesomeIcon icon="calendar" /></InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" name="createdFromFilter" id="createdFromFilter" placeholder="Inserisci data inizio..." value={createdFromFilter} readOnly autoComplete="dummy-autocomplete" />
                        </InputGroup>
                      </DateRangePicker>
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="createdToFilter">Data creazione - (sino al)</Label><br />
                      <DateRangePicker containerStyles={{width: '100%'}} singleDatePicker onApply={this.handleInputChange} locale={itaLocale}>
                        <InputGroup size="sm">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText><FontAwesomeIcon icon="calendar" /></InputGroupText>
                          </InputGroupAddon>
                          <Input type="text" name="createdToFilter" id="createdToFilter" placeholder="Inserisci data fine..." value={createdToFilter} readOnly autoComplete="dummy-autocomplete" />
                        </InputGroup>
                      </DateRangePicker>
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="importoFromFilter">Importo - (da minimo)</Label><br />
                      <InputGroup size="sm">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><FontAwesomeIcon icon="euro-sign" /></InputGroupText>
                        </InputGroupAddon>
                        <Input type="number"
                               name="importoFromFilter"
                               id="importoFromFilter"
                               invalid={ !checkImportoConVirgola }
                               placeholder="Inserisci importo minimo..."
                               value={importoFromFilter}
                               onChange={this.handleInputChange} />
                      </InputGroup>
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="importoToFilter">Importo - (a massimo)</Label><br />
                      <InputGroup size="sm">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText><FontAwesomeIcon icon="euro-sign" /></InputGroupText>
                        </InputGroupAddon>
                        <Input type="number"
                               name="importoToFilter"
                               id="importoToFilter"
                               invalid={ !checkImportoConVirgola }
                               placeholder="Inserisci importo minimo..."
                               value={importoToFilter}
                               onChange={this.handleInputChange} />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row className="mt-md-2">
                    <Col xs="12" md="3">
                      <Label htmlFor="nomeFilter">Nome cittadino</Label><br />
                      <Input type="text" name="nomeFilter" id="nomeFilter" size="sm" placeholder="Inserisci nome..." value={nomeFilter} onChange={this.handleInputChange} />
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="cognomeFilter">Cognome cittadino</Label><br />
                      <Input type="text" name="cognomeFilter" id="cognomeFilter" size="sm" placeholder="Inserisci cognome..." value={cognomeFilter} onChange={this.handleInputChange} />
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="ragioneSocialeFilter">Nome esercizio</Label><br />
                      <Input type="text" name="ragioneSocialeFilter" id="ragioneSocialeFilter" size="sm" placeholder="Inserisci nome esercizio..." value={ragioneSocialeFilter} onChange={this.handleInputChange} />
                    </Col>
                    <Col xs="12" md="3">
                      <Label htmlFor="tipoFilter">Tipo transazione</Label><br />
                      {account && account.amministrazioneComunale && account.amministrazioneComunale.voucherAbilitati ?
                        <Input type="select" name="tipoFilter" id="tipoFilter" size="sm" value={tipoFilter}
                               onChange={this.handleInputChange}>
                          <option value="">Tutte</option>
                          <option value="TT_01_DOTAZIONE">DOTAZIONE</option>
                          <option value="TT_05_ACCREDITO_DIRETTO">ACCREDITO DIRETTO</option>
                          <option value="TT_07_BUONO_SPESA">BUONO SPESA</option>
                          <option value="TT_04_LIQUIDAZIONE">LIQUIDAZIONE</option>
                          <option value="TT_06_ANTICIPO_SPESA">ANTICIPO SPESA</option>
                          <option value="TT_08_ASSEGNAZIONE">ASSEGNAZIONE VOUCHERS</option>
                          <option value="TT_09_VOUCHER_SPESA">UTILIZZO BUONO VOUCHER</option>
                        </Input>
                        :
                        <Input type="select" name="tipoFilter" id="tipoFilter" size="sm" value={tipoFilter}
                               onChange={this.handleInputChange}>
                          <option value="">Tutte</option>
                          <option value="TT_01_DOTAZIONE">DOTAZIONE</option>
                          <option value="TT_05_ACCREDITO_DIRETTO">ACCREDITO DIRETTO</option>
                          <option value="TT_07_BUONO_SPESA">BUONO SPESA</option>
                          <option value="TT_04_LIQUIDAZIONE">LIQUIDAZIONE</option>
                        </Input>
                      }
                    </Col>
                  </Row>
                  {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                    <Row className="mt-md-2">
                      <Col xs="12" md="3">
                        <Label htmlFor="categoriaEsercenteFilter">Categoria</Label>
                        <Row>
                          {categorieEsercentiLoading &&
                          <Col xs="auto" style={{paddingTop: '7px', paddingRight: '0px'}}><FontAwesomeIcon icon='spinner' spin/></Col>
                          }
                          <Col >
                            <Input name="categoriaEsercenteFilter" id="categoriaEsercenteFilter" type="select" className="form-control" size="sm"
                                   onChange={this.handleChangeCategoria}
                                   disabled={categorieEsercentiLoading}
                                   value={categoriaEsercenteFilter}
                            >
                              <option value="" key="all">
                                TUTTE
                              </option>
                              {categorieEsercenti
                                ? categorieEsercenti.map(categoriaEsercente => (
                                  <option value={categoriaEsercente.denominazione} key={categoriaEsercente.denominazione}>
                                    {categoriaEsercente.denominazione}
                                  </option>
                                ))
                                : null}
                            </Input>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  }
                  <hr/>
                  <Row>
                    <Col sm="12"  md={{ size: 2, offset: 8 }} className="pb-3">
                      <Button color="primary" block onClick={this.resetSearch}><FontAwesomeIcon icon="redo" /> Pulisci ricerca</Button>
                    </Col>
                    <Col sm="12" md="2">
                      <Button color="primary" block type="submit"><FontAwesomeIcon icon="search" /> Cerca</Button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Collapse>
          </Card>
        </Col>
        {loading &&
          <div className="text-center">
            <h5>caricamento in corso...</h5>
            <Spinner color="dark" />
          </div>
        }
        <Row className="mb-2 text-right">
          <Col xs="12">
            <Badge color="primary">
              <h6>Totale importi:{' '}
                {totalImportoLoading ?
                  <FontAwesomeIcon icon="spinner" spin />
                  :
                  <>
                    {totalImport.toLocaleString() + '€'}
                  </>
                }
              </h6>
            </Badge>
          </Col>
        </Row>
        {!loading &&
          <>
            <div className="table-responsive">
              {transazioneList && transazioneList.length > 0 ? (
                <Table responsive aria-describedby="transazione-heading" striped size="sm">
                  <thead>
                    <tr>
                      <th className="hand" onClick={this.sort('createdDate')}>
                        Data Creazione <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={this.sort('lastModifiedBy')}>
                        Creata da <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={this.sort('tipo')}>
                        Tipologia <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={this.sort('importo')}>
                        Importo <FontAwesomeIcon icon="sort" />
                      </th>
                      {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                        <th className="hand" onClick={this.sort('categoriaEsercente')}>
                          Categoria Esercente <FontAwesomeIcon icon="sort" />
                        </th>
                      }
                      <th>Documento transazione</th>
                      <th className="hand" onClick={this.sort('cittadino.user.lastName')}>
                        Cittadino <FontAwesomeIcon icon="sort" />
                      </th>
                      <th className="hand" onClick={this.sort('esercente.ragioneSociale')}>
                        Esercente <FontAwesomeIcon icon="sort" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {transazioneList.map((transazione, i) => (
                      <tr key={`entity-${i}`}>
                        <td>
                          <TextFormat value={transazione.createdDate} type="date" format={APP_DATE_FORMAT} blankOnInvalid />
                        </td>
                        <td>{transazione.createdBy}</td>
                        <td><TipologiaTransazioneView tipo={transazione.tipo} /></td>
                        <td><Badge color="primary">{transazione.importo ? transazione.importo.toLocaleString() : 0 } €</Badge></td>
                        {account && account.amministrazioneComunale && account.amministrazioneComunale.categorieAbilitate &&
                          <td>
                            {transazione.categoriaEsercente &&
                              <>
                                <FontAwesomeIcon icon="tags" fixedWidth/> {transazione.categoriaEsercente.denominazione}
                              </>
                            }
                          </td>
                        }
                        <td>
                          {transazione.scontrinoUrl ? (
                            <a onClick={this.onDownloadClick.bind(this,`/api/${transazione.scontrinoUrl}`, 'scontrino', transazione.scontrinoUrl)}>Download</a>
                          ) : null}
                        </td>
                        <td>
                          {transazione.cittadino ?
                            <Button tag={Link} to={`cittadino/${transazione.cittadino.id}`} color="link" size="sm">
                              {transazione.cittadino.user.lastName + ' ' + transazione.cittadino.user.firstName}
                            </Button>
                            :
                          ''}
                        </td>
                        <td>
                          {transazione.esercente ?
                            <Button tag={Link} to={`esercente/${transazione.esercente.id}`} color="link" size="sm">
                              {transazione.esercente.ragioneSociale}
                            </Button>
                            :
                            ''}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <div className="alert alert-warning">Nessuna transazione trovata</div>
              )}
            </div>
            <div className={transazioneList && transazioneList.length > 0 ? '' : 'd-none'}>
              <Row className="justify-content-center">
                <JhiItemCount page={this.state.activePage} total={totalItems} itemsPerPage={this.state.itemsPerPage} />
              </Row>
              <Row className="justify-content-center">
                <JhiPagination
                  activePage={this.state.activePage}
                  onSelect={this.handlePagination}
                  maxButtons={5}
                  itemsPerPage={this.state.itemsPerPage}
                  totalItems={this.props.totalItems}
                />
              </Row>
            </div>
          </>
        }
      </div>
    );
  }
}

const mapStateToProps = ({ authentication, transazione, categoriaEsercente, document }: IRootState) => ({
  account: authentication.account,
  transazioneList: transazione.entities,
  categorieEsercenti: categoriaEsercente.entities,
  categorieEsercentiLoading: categoriaEsercente.loading,
  totalItems: transazione.totalItems,
  loading: transazione.loading,
  document: document,
  totalImport: transazione.sum,
  totalImportoLoading: transazione.sumLoading
});

const mapDispatchToProps = {
  getEntities,
  sumAllTransazioni,
  getCategorieEsercenti,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Transazione);
