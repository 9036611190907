import './home.scss';

import React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { connect } from 'react-redux';
import { Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Table,
  Button,
  CardFooter,
  Spinner
} from 'reactstrap';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getPublicSaldoChart } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
import {
  getCittadinoPublicSaldo,
  getCittadinoPublicSaldoVoucher,
  reset
} from 'app/entities/cittadino/cittadino.reducer';
import {getSession} from "app/shared/reducers/authentication";
import {StatoRochiedente} from "app/shared/model/enumerations/stato-richiedente";
import {TipologiaTransazione} from "app/shared/model/enumerations/tipologia-transazione.model";

export interface IHomeCittadinoProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export interface IHomeCittadinoState {
}

export class HomeCittadino extends React.Component<IHomeCittadinoProps, IHomeCittadinoState> {
  state: IHomeCittadinoState = {
  };

  constructor(props) {
    super(props);
    this.state = {
    };
    this.props.history.push(`${this.props.location.pathname}`);
  }

  componentDidMount() {
    this.props.getCittadinoPublicSaldo(this.props.account.cittadino.id);
    if (this.props.account && this.props.account.cittadino.comuneRiferimento && this.props.account.cittadino.comuneRiferimento.voucherAbilitati) {
      this.props.getCittadinoPublicSaldoVoucher(this.props.account.cittadino.id);
    }
  }

  render() {
    const { account,
      loadingSaldo,
      saldo,
      loadingSaldoVoucher,
      saldoVoucher
    } = this.props;
    const cittadinoEntity = account.cittadino;
    const { } = this.state;

    return (
      <>
        <Row>
          <Col md="8">
            <h2>Benvenuto, {account.firstName + ' ' + account.lastName}</h2>
          </Col>
        </Row>
        <hr />
        {cittadinoEntity.stato === StatoRochiedente.RICHIESTA_APPROVATA ?
          <>
            <Row>
              <Col md="12">
                <Card className="mt-3 w-100">
                  <CardTitle className="text-center mt-3">
                    <BrowserView>
                      <h3>Saldo disponibile: {cittadinoEntity.saldoDisponibile ? cittadinoEntity.saldoDisponibile.toLocaleString() : 0} €</h3>
                    </BrowserView>
                    <MobileView>
                      <h5>Saldo disponibile: {cittadinoEntity.saldoDisponibile ? cittadinoEntity.saldoDisponibile.toLocaleString() : 0} €</h5>
                    </MobileView>
                    <Button tag={Link} to="/cittadino/transazione/buonispesa" replace color="info">
                      <FontAwesomeIcon icon="list" />
                      &nbsp;
                      <span className="d-md-inline">Visualizza elenco transazioni</span>
                    </Button>
                    <hr />
                  </CardTitle>
                  <CardBody style={{paddingTop: '0px'}}>
                    {cittadinoEntity && cittadinoEntity.comuneRiferimento && cittadinoEntity.comuneRiferimento.categorieAbilitate &&
                    <div className="d-flex flex-wrap justify-content-center">
                      {loadingSaldo ?
                        <Col xs="12" className="text-center">
                          <Spinner color="dark" />
                        </Col>
                        :
                        <>
                          {saldo && saldo.map((s, i) => {
                            const renderItem = () => {
                              return (
                                <div key={`saldo-${i}`} className="text-center">
                                  <Card style={{ backgroundColor: '#06c', color: '#fff', width: (isMobile ? '300px' : '400px')}} className="m-4 p-2">
                                    <CardBody>
                                      <Row>
                                        <Col xs={12}>
                                          Categoria:
                                          <h4>{s.categoriaEsercente && s.categoriaEsercente.denominazione}</h4>
                                        </Col>
                                      </Row>
                                      <hr style={{borderColor: 'white'}}/>
                                      <Row>
                                        <Col xs={12} className="text-right">
                                          <h2>€ {s.saldo.toLocaleString()}</h2>
                                        </Col>
                                      </Row>
                                    </CardBody>
                                    <CardFooter>
                                      <Button tag={Link} to={"/cittadino/transazione/buonispesa/categoria/"+s.categoriaEsercente.id} replace color="info">
                                        <FontAwesomeIcon icon="list" />
                                        &nbsp;
                                        <span className="d-md-inline">Visualizza elenco transazioni</span>
                                      </Button>
                                    </CardFooter>
                                  </Card>
                                </div>
                              );
                            }
                            return (renderItem());
                          })}
                        </>
                      }

                    </div>
                    }
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {cittadinoEntity && cittadinoEntity.comuneRiferimento && cittadinoEntity.comuneRiferimento.voucherAbilitati &&
              <Row>
                <Col md="12">
                  <Card className="mt-3 w-100">
                    <CardTitle className="text-center mt-3">
                      <BrowserView>
                        <h3>Saldo Voucher Disponibile: {cittadinoEntity.saldoDisponibileVouchers ? cittadinoEntity.saldoDisponibileVouchers.toLocaleString() : 0} €</h3>
                      </BrowserView>
                      <MobileView>
                        <h5>Saldo Voucher Disponibile: {cittadinoEntity.saldoDisponibileVouchers ? cittadinoEntity.saldoDisponibileVouchers.toLocaleString() : 0} €</h5>
                      </MobileView>

                      <Button tag={Link} to="/cittadino/transazione/voucher" replace color="info">
                        <FontAwesomeIcon icon="list" />
                        &nbsp;
                        <span className="d-md-inline">Visualizza elenco transazioni</span>
                      </Button>
                      <hr />
                    </CardTitle>
                    <CardBody style={{paddingTop: '0px'}}>
                      <div className="d-flex flex-wrap justify-content-center">
                        {loadingSaldoVoucher ?
                          <Col xs="12" className="text-center">
                            <Spinner color="dark" />
                          </Col>
                          :
                          <>
                            {saldoVoucher && saldoVoucher.map((s, i) => {
                              const renderItem = () => {
                                return (
                                  <div key={`saldo-${i}`} className="text-center">
                                    <Card style={{ backgroundColor: '#1cbeef', color: '#fff', width: (isMobile ? '300px' : '400px')}} className="m-4 p-2">
                                      <CardBody>
                                        <Row>
                                          <Col xs={12}>
                                            Esercente:
                                            <h4>{s.esercente && s.esercente.ragioneSociale}</h4>
                                          </Col>
                                        </Row>
                                        <hr style={{borderColor: 'white'}}/>
                                        <Row>
                                          <Col xs={12} className="text-right">
                                            <h2>€ {s.saldo.toLocaleString()}</h2>
                                          </Col>
                                        </Row>
                                      </CardBody>
                                      <CardFooter>
                                        <Button tag={Link} to={"/cittadino/transazione/voucher/esercente/"+s.esercente.id} replace color="info">
                                          <FontAwesomeIcon icon="list" />
                                          &nbsp;
                                          <span className="d-md-inline">Visualizza elenco vouchers</span>
                                        </Button>
                                      </CardFooter>
                                    </Card>
                                  </div>
                                );
                              };
                              return (renderItem());
                            })}
                          </>
                        }

                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            }
          </>
          :
          <Row>
            <Col md="12">
              <Card className="mt-3 w-100 text-center">
                <CardBody>
                  <h2>La tua richiesta è in fase di approvazione.</h2>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }
      </>
    );
  }
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  saldo: storeState.cittadino.saldo,
  saldoVoucher: storeState.cittadino.saldoVoucher,
  loadingSaldo: storeState.cittadino.loadingSaldo,
  loadingSaldoVoucher: storeState.cittadino.loadingSaldo,
});

const mapDispatchToProps = {
  getPublicSaldoChart,
  getSession,
  getCittadinoPublicSaldo,
  getCittadinoPublicSaldoVoucher,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeCittadino);
