import React from 'react';
import { connect } from 'react-redux';
import {Link, RouteComponentProps} from 'react-router-dom';
import { Row,
  Col,
  Spinner,
  Card,
  CardBody,
  Button,
  Jumbotron,
  Badge,
  Alert
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getPublicEntityForGuest } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
import parse from 'html-react-parser';

import './guest.scss';
import CountUp from 'react-countup';
import NuovaRichiestaModal from "app/modules/components/nuova-richiesta-modal";
import {isMobile} from "react-device-detect";

export interface IComunePageProps extends StateProps, DispatchProps, RouteComponentProps<{ comuneStringId: string }> {}

export interface IComunePageState {
  htmlPage: string;
  categorie: any;
  nuovaRichiestaModalShow: boolean;
};

export class ComunePage extends React.Component<IComunePageProps, IComunePageState> {

  constructor(props) {
    super(props);

    this.state = {
      htmlPage: '',
      categorie: [],
      nuovaRichiestaModalShow: false
    }

    this.openNuovaRichiestaModal = this.openNuovaRichiestaModal.bind(this);
    this.closeNuovaRichiestaModal = this.closeNuovaRichiestaModal.bind(this);
  }

  componentDidMount() {
    this.props.getPublicEntityForGuest(this.props.match.params.comuneStringId);
  }

  openNuovaRichiestaModal() {
    this.setState({
      nuovaRichiestaModalShow: true
    })
  }

  closeNuovaRichiestaModal() {
    this.setState({
      nuovaRichiestaModalShow: false
    })
  }

  render() {
    const { amministrazioneLoading } = this.props;
    const { nuovaRichiestaModalShow } = this.state;
    const amministrazione = this.props.amministrazione.amministrazioneComunale;
    const stats = this.props.amministrazione.stats;
    return (
      <>
        <div>
          { (!amministrazione || amministrazioneLoading) ?
            <div className="text-center">
              <h5>caricamento in corso...</h5>
              <Spinner color="dark" />
            </div>
            :
            <>
              <Jumbotron>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs="auto">
                        <div style={{width: '300px',
                          height: '300px',
                          display: 'table-cell',
                          verticalAlign: 'middle',
                          textAlign: 'center'
                        }}>
                          {amministrazione.logoUrl &&
                          <img src={'api/' +amministrazione.logoUrl} style={{display: 'block',
                            maxWidth: '300px',
                            maxHeight: '300px',
                            width: 'auto',
                            height: 'auto'}}/>
                          }
                        </div>
                      </Col>
                      <Col>
                        <h1 className="display-3">{amministrazione.denominazione}</h1>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody>
                    <Row>
                      <Col style={{minWidth: (isMobile ? '250px' : '350px')}}>
                        <Card style={{ backgroundColor: '#06c', color: '#fff'}} className="m-2 p-1">
                          <CardBody>
                            <Row>
                              <Col style={{fontSize: '60px'}} xs="auto">
                                <FontAwesomeIcon icon="chart-area"/>
                              </Col>
                              <Col>
                                Totale
                                <h4>Cittadini<br/>benefeciari</h4>
                              </Col>
                            </Row>
                            <hr style={{borderColor: 'white'}}/>
                            <Row>
                              <Col xs={12} className="text-center">
                                <h1><CountUp
                                  start={0}
                                  end={stats.totaleAssegnatari}
                                  duration={2.75}
                                  separator="."
                                  useEasing={true}
                                /></h1>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col style={{minWidth: (isMobile ? '250px' : '350px')}}>
                        <Card style={{ backgroundColor: '#06c', color: '#fff'}} className="m-2 p-1">
                          <CardBody>
                            <Row>
                              <Col style={{fontSize: '60px'}} xs="auto">
                                <FontAwesomeIcon icon="chart-bar"/>
                              </Col>
                              <Col>
                                Totale
                                <h4>Esercizi<br/>convenzionati</h4>
                              </Col>
                            </Row>
                            <hr style={{borderColor: 'white'}}/>
                            <Row>
                              <Col xs={12} className="text-center">
                                <h1><CountUp
                                  start={0}
                                  end={stats.totaleEsercizi}
                                  duration={2.75}
                                  separator="."
                                  useEasing={true}
                                /></h1>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col style={{minWidth: (isMobile ? '250px' : '350px')}}>
                        <Card style={{ backgroundColor: '#06c', color: '#fff'}} className="m-2 p-1">
                          <CardBody>
                            <Row>
                              <Col style={{fontSize: '60px'}} xs="auto">
                                <FontAwesomeIcon icon="chart-line"/>
                              </Col>
                              <Col>
                                Totale
                                <h4>Buoni spesa<br />erogati</h4>
                              </Col>
                            </Row>
                            <hr style={{borderColor: 'white'}}/>
                            <Row>
                              <Col xs={12} className="text-center">
                                <h1><CountUp
                                  start={0}
                                  end={stats.totaleImportoBuoniSpesaErogati}
                                  duration={2.75}
                                  prefix="€ "
                                  decimal=","
                                  separator="."
                                  useEasing={true}
                                /></h1>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col style={{minWidth: (isMobile ? '250px' : '350px')}}>
                        <Card style={{ backgroundColor: '#06c', color: '#fff'}} className="m-2 p-1">
                          <CardBody>
                            <Row>
                              <Col style={{fontSize: '60px'}} xs="auto">
                                <FontAwesomeIcon icon="chart-area"/>
                              </Col>
                              <Col>
                                Totale
                                <h4>Transazioni<br/>eseguite</h4>
                              </Col>
                            </Row>
                            <hr style={{borderColor: 'white'}}/>
                            <Row>
                              <Col xs={12} className="text-center">
                                <h1><CountUp
                                  start={0}
                                  end={stats.totaleTransazioni}
                                  duration={2.75}
                                  separator="."
                                  useEasing={true}
                                /></h1>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col style={{minWidth: (isMobile ? '250px' : '350px')}}>
                        <Card style={{ backgroundColor: '#06c', color: '#fff'}} className="m-2 p-1">
                          <CardBody>
                            <Row>
                              <Col style={{fontSize: '60px'}} xs="auto">
                                <FontAwesomeIcon icon="chart-line"/>
                              </Col>
                              <Col >
                                Totale
                                <h4>Buoni spesa<br/>utilizzati</h4>
                              </Col>
                            </Row>
                            <hr style={{borderColor: 'white'}}/>
                            <Row>
                              <Col xs={12} className="text-center">
                                <h1><CountUp
                                  start={0}
                                  end={stats.totaleImportoTransazioni}
                                  duration={2.75}
                                  prefix="€ "
                                  separator="."
                                  useEasing={true}
                                /></h1>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <hr />
                <Row>
                  <Col xs="12">
                    <FontAwesomeIcon icon="info-circle" /> Informazioni utili
                  </Col>
                  <Card className="w-100 mt-3">
                    <CardBody>
                      <h5>{amministrazione.denominazione}</h5>
                      <p className="mb-1">Telefono: {amministrazione.contattoTelefonico}</p>
                      <p className="mb-1">Indirizzo: {amministrazione.indirizzo}</p>
                      <p className="mb-1">Email: {amministrazione.contattoEmail}</p>
                    </CardBody>
                  </Card>
                </Row>
                <Row className="mt-3">
                  <Col xs="12">
                    <FontAwesomeIcon icon="info-circle" /> Links
                  </Col>
                  <Col xs="12" className="mt-2">
                    - Clicca qui per accedere alla pagina di inserimento richiesta per i buoni sconto
                  </Col>
                  <Col xs="12" className="mt-1">
                    <Button tag={Link} to={`/registrazione/cittadino/`+this.props.match.params.comuneStringId} size="sm" color="primary">Richiedi buoni</Button>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col xs="12">
                    - Visualizza l'elenco delle attività commerciali aderenti all'iniziativa
                  </Col>
                  <Col xs="12" className="mt-1">
                    <Button tag={Link} to={`/elenco-esercenti/`+this.props.match.params.comuneStringId} size="sm" color="primary">Elenco esercizi</Button>
                  </Col>
                </Row>
              </Jumbotron>
              {amministrazione.htmlContent && amministrazione.htmlContent.length >0 &&
                <Jumbotron>
                  <Row>
                    <Col xs="12">
                      <Card className="w-100">
                        <CardBody>
                          {parse(amministrazione.htmlContent)}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Jumbotron>
              }
              {/* CASARANO_CUSTOM - comuneStringId = casarano */}
              {this.props.match.params.comuneStringId === 'casarano' &&
                <Jumbotron>
                  <Row>
                    <Col xs="12">
                      <Card className="w-100">
                        <CardBody>
                          <div className="mb-5 text-center">
                            <h1>
                              AVVISO PER RICHIESTA NUOVA ASSEGNAZIONE IMPORTI:
                            </h1>
                          </div>
                          <Alert color="warning" className="mt-2 text-center">
                            <p>
                              <FontAwesomeIcon icon="info-circle"/> Se sei già registrato ed hai già usufruito dei buoni messi a disposizione dal comune allora puoi
                              richiedere una <b>nuova riassegnazione</b> degli importi cliccando su <b>RI-CHIEDI ASSEGNAZIONE BUONI</b>.
                            </p>
                            <Button onClick={this.openNuovaRichiestaModal} color="primary" className="ml-2">
                              <b>RI-CHIEDI ASSEGNAZIONE BUONI</b>
                            </Button>
                            <hr />
                            <p>
                              <FontAwesomeIcon icon="info-circle"/> Se invece è la <b>prima volta</b> che richiedi di beneficiare dei buoni
                              spesa/ristoro erogati dal comune di {amministrazione.denominazione} allora
                              effettua la <b>registrazione completa</b> cliccando su <b>REGISTRATI E RICHIEDI BUONI</b>.
                            </p>
                            <Button tag={Link} to={"/registrazione/cittadino/" + this.props.match.params.comuneStringId} color="primary"
                                    className="ml-2">
                              REGISTRATI E RICHIEDI BUONI
                            </Button>
                          </Alert>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Jumbotron>
              }
            </>
          }
        </div>
        {amministrazione &&
          <NuovaRichiestaModal amministrazioneComunale={amministrazione}
                               comuneStringId={this.props.match.params.comuneStringId}
                               showModal={nuovaRichiestaModalShow}
                               handleCloseModal={this.closeNuovaRichiestaModal.bind(this)} />
        }
      </>
    )
  }
}

const mapStateToProps = ({ esercente, amministrazioneComunale }: IRootState) => ({
  amministrazione: amministrazioneComunale.entity,
  amministrazioneLoading: amministrazioneComunale.loading
});

const mapDispatchToProps = {
  getPublicEntityForGuest
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComunePage);
