import React from 'react';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import appConfig from 'app/config/constants';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/wellfood-logo.svg" alt="Logo" />
  </div>
);

export const WeaveBrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/wellfood-logo-white.svg" alt="Logo" />
  </div>
);

export const BrandIconWhite = props => (
  <div {...props} className={"brand-icon" + (isMobile ? "-mobile" : "")}>
    <img src="content/images/wellfood-logo-white.svg" alt="Logo" />
  </div>
);

export const WeaveBrandIconWhite = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/wellfood-logo-white.svg" alt="Logo" />
  </div>
);

export const Brand = props => (
  <NavbarBrand tag={Link} to="/" className="brand-logo">
    <BrandIconWhite />
    <span className="brand-title pl-2">{props.title}</span>
    {props.isAuthenticated &&
      <span className="navbar-version">{appConfig.VERSION}</span>
    }
  </NavbarBrand>
);

export const Home = props => (
  <NavItem>
    <NavLink tag={Link} to="/" className="d-flex align-items-center">
      <FontAwesomeIcon icon="home" />
      <span>Home</span>
    </NavLink>
  </NavItem>
);
