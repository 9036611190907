import { IEsercente } from 'app/shared/model/esercente.model';
import { ITransazione } from 'app/shared/model/transazione.model';
import { IAmministrazioneComunale } from 'app/shared/model/amministrazione-comunale.model';

export interface ICategoriaEsercente {
  id?: number;
  denominazione?: string;
  esercentis?: IEsercente[];
  transazionis?: ITransazione[];
  comuneRiferimento?: IAmministrazioneComunale;
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
}

export const defaultValue: Readonly<ICategoriaEsercente> = {};
