export const enum TipologiaTransazione {
  TT_01_DOTAZIONE = 'TT_01_DOTAZIONE',
  TT_02_RESTITUZIONE = 'TT_02_RESTITUZIONE',
  TT_03_TRASFERIMENTO = 'TT_03_TRASFERIMENTO',
  TT_04_LIQUIDAZIONE = 'TT_04_LIQUIDAZIONE',
  TT_05_ACCREDITO_DIRETTO = 'TT_05_ACCREDITO_DIRETTO',
  TT_06_ANTICIPO_SPESA = 'TT_06_ANTICIPO_SPESA',
  TT_07_BUONO_SPESA = 'TT_07_BUONO_SPESA',
  TT_08_ASSEGNAZIONE = 'TT_08_ASSEGNAZIONE',
  TT_09_VOUCHER_SPESA = 'TT_09_VOUCHER_SPESA'
}
