import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ICittadino, defaultValue } from 'app/shared/model/cittadino.model';
import pick from 'lodash/pick';

export const ACTION_TYPES = {
  FETCH_CITTADINO_LIST: 'cittadino/FETCH_CITTADINO_LIST',
  FETCH_CITTADINO: 'cittadino/FETCH_CITTADINO',
  CREATE_CITTADINO: 'cittadino/CREATE_CITTADINO',
  UPDATE_CITTADINO: 'cittadino/UPDATE_CITTADINO',
  DELETE_CITTADINO: 'cittadino/DELETE_CITTADINO',
  APPROVE_CITTADINO: 'cittadino/APPROVE_CITTADINO',
  DENY_CITTADINO: 'cittadino/DENY_CITTADINO',
  REOPEN_CITTADINO: 'cittadino/REOPEN_CITTADINO',
  FETCH_SALDO: 'cittadino/FETCH_SALDO',
  FETCH_SALDO_VOUCHER: 'cittadino/FETCH_SALDO_VOUCHER',
  SET_BLOB: 'cittadino/SET_BLOB',
  SET_ENTITY_PROPERTY: 'cittadino/SET_ENTITY_PROPERTY',
  RESET: 'cittadino/RESET'
};

const initialState = {
  loading: false,
  loadingSaldo: false,
  loadingSaldoVoucher: false,
  errorMessage: null,
  errorSaldoMessage: null,
  errorSaldoVoucherMessage: null,
  entities: [] as ReadonlyArray<ICittadino>,
  entity: defaultValue,
  saldo: null,
  saldoVoucher: null,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type CittadinoState = Readonly<typeof initialState>;

// Reducer

export default (state: CittadinoState = initialState, action): CittadinoState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_CITTADINO_LIST):
    case REQUEST(ACTION_TYPES.FETCH_CITTADINO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.FETCH_SALDO):
      return {
        ...state,
        errorSaldoMessage: null,
        loadingSaldo: true
      };
    case REQUEST(ACTION_TYPES.FETCH_SALDO_VOUCHER):
      return {
        ...state,
        errorSaldoVoucherMessage: null,
        loadingSaldoVoucher: true
      };
    case REQUEST(ACTION_TYPES.CREATE_CITTADINO):
    case REQUEST(ACTION_TYPES.UPDATE_CITTADINO):
    case REQUEST(ACTION_TYPES.DELETE_CITTADINO):
    case REQUEST(ACTION_TYPES.APPROVE_CITTADINO):
    case REQUEST(ACTION_TYPES.REOPEN_CITTADINO):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_CITTADINO_LIST):
    case FAILURE(ACTION_TYPES.FETCH_CITTADINO):
    case FAILURE(ACTION_TYPES.CREATE_CITTADINO):
    case FAILURE(ACTION_TYPES.UPDATE_CITTADINO):
    case FAILURE(ACTION_TYPES.DELETE_CITTADINO):
    case FAILURE(ACTION_TYPES.APPROVE_CITTADINO):
    case FAILURE(ACTION_TYPES.REOPEN_CITTADINO):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.FETCH_SALDO):
      return {
        ...state,
        loadingSaldo: false,
        errorSaldoMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.FETCH_SALDO_VOUCHER):
      return {
        ...state,
        loadingSaldoVoucher: false,
        errorSaldoVoucherMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_CITTADINO_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    case SUCCESS(ACTION_TYPES.FETCH_CITTADINO):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_SALDO):
      return {
        ...state,
        loadingSaldo: false,
        saldo: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.FETCH_SALDO_VOUCHER):
      return {
        ...state,
        loadingSaldoVoucher: false,
        saldoVoucher: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_CITTADINO):
    case SUCCESS(ACTION_TYPES.UPDATE_CITTADINO):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_CITTADINO):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case SUCCESS(ACTION_TYPES.APPROVE_CITTADINO):
    case SUCCESS(ACTION_TYPES.REOPEN_CITTADINO):
      return {
        ...state,
        updating: false,
        updateSuccess: true
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType, filename, size } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType,
          [name + 'FileName']: filename,
          [name + 'Size']: size
        }
      };
    }
    case ACTION_TYPES.SET_ENTITY_PROPERTY: {
      const { propertyName, value } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [propertyName]: value
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/cittadinos';
const apiUrlPublic = 'api/public/cittadinos';

// Actions

export const getEntities: ICrudGetAllAction<ICittadino> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_CITTADINO_LIST,
    payload: axios.get<ICittadino>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<ICittadino> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CITTADINO,
    payload: axios.get<ICittadino>(requestUrl, { responseType: 'blob' })
  };
};

export const createEntity: ICrudPutAction<ICittadino> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CITTADINO,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const updateEntity: ICrudPutAction<ICittadino> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CITTADINO,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  dispatch(getEntities());
  return result;
};

export const deleteEntity: ICrudDeleteAction<ICittadino> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CITTADINO,
    payload: axios.delete(requestUrl)
  });
  dispatch(getEntities());
  return result;
};

export const setBlob = (name, data, contentType?, filename?, size?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType,
    filename,
    size
  }
});

export const setEntityProperty = (propertyName, value) => ({
  type: ACTION_TYPES.SET_ENTITY_PROPERTY,
  payload: {
    propertyName,
    value
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});

export const reset2 = () => ({
  type: ACTION_TYPES.RESET
});

export const getPublicEntities = (page, size, sort, searchFilterParams) => {
  let params = '';
  if (sort) {
    params += `?page=${page}&size=${size}&sort=${sort}`;
  }
  if (searchFilterParams) {
    params += `${sort ? '&' : '?'}${searchFilterParams}`;
  }
  params += `${sort || searchFilterParams ? '&' : '?'}cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrlPublic}${params}`;
  return {
    type: ACTION_TYPES.FETCH_CITTADINO_LIST,
    payload: axios.get<ICittadino>(`${requestUrl}`)
  };
};

export const getPublicCittadini = (page, size, sort, searchFilterParams) => {
  let params = '?stato=approvata';
  if (sort) {
    params += `&page=${page}&size=${size}&sort=${sort}`;
  }
  if (searchFilterParams) {
    params += `&${searchFilterParams}`;
  }
  params += `&cacheBuster=${new Date().getTime()}`;
  const requestUrl = `${apiUrlPublic}${params}`;
  return {
    type: ACTION_TYPES.FETCH_CITTADINO_LIST,
    payload: axios.get<ICittadino>(`${requestUrl}`)
  };
};

export const getPublicEntity: ICrudGetAction<ICittadino> = id => {
  const requestUrl = `${apiUrlPublic}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_CITTADINO,
    payload: axios.get<ICittadino>(requestUrl)
  };
};

export const getPublicSaldo: ICrudGetAction<any> = id => {
  const requestUrl = `${apiUrlPublic}/${id}/saldo`;
  return {
    type: ACTION_TYPES.FETCH_SALDO,
    payload: axios.get<any>(requestUrl)
  };
};

export const getCittadinoPublicSaldo: any = () => {
  const requestUrl = `${apiUrlPublic}/saldo`;
  return {
    type: ACTION_TYPES.FETCH_SALDO,
    payload: axios.get<any>(requestUrl)
  };
};

export const getPublicSaldoVoucher: ICrudGetAction<any> = id => {
  const requestUrl = `${apiUrlPublic}/${id}/saldo-voucher`;
  return {
    type: ACTION_TYPES.FETCH_SALDO_VOUCHER,
    payload: axios.get<any>(requestUrl)
  };
};

export const getCittadinoPublicSaldoVoucher: any = id => {
  const requestUrl = `${apiUrlPublic}/saldo-voucher`;
  return {
    type: ACTION_TYPES.FETCH_SALDO_VOUCHER,
    payload: axios.get<any>(requestUrl)
  };
};

export const createPublicEntity: ICrudPutAction<ICittadino> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CITTADINO,
    payload: axios.post(apiUrlPublic, cleanEntity(entity))
  });
  return result;
};

export const createPublicEntityForCittadino = (entity, comuneStringId) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_CITTADINO,
    payload: axios.post('api/public/registrazione-cittadino/' + comuneStringId, cleanEntity(entity))
  });
  return result;
};

export const updatePublicEntity: ICrudPutAction<ICittadino> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_CITTADINO,
    payload: axios.put(apiUrlPublic, cleanEntity(entity))
  });
  return result;
};

export const deletePublicEntity: ICrudDeleteAction<ICittadino> = id => async dispatch => {
  const requestUrl = `${apiUrlPublic}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_CITTADINO,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const approveCittadino: any = id => async dispatch => {
  const requestUrl = `api/public/amministrazione-comunale/approve/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.APPROVE_CITTADINO,
    payload: axios.get(requestUrl)
  });
  return result;
};

export const denyCittadino: any = id => async dispatch => {
  const requestUrl = `api/public/amministrazione-comunale/deny/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DENY_CITTADINO,
    payload: axios.get(requestUrl)
  });
  return result;
};

export const reopenCittadino: any = id => async dispatch => {
  const requestUrl = `api/public/amministrazione-comunale/reopen/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.REOPEN_CITTADINO,
    payload: axios.get(requestUrl)
  });
  return result;
};

export const setEmailToNullIdEmpty = entity => {
  if (typeof entity.email === 'string' && entity.email.trim() === '') entity.email = null;

  return entity;
};
