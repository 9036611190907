import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Cittadino from './cittadino';
import Richiesta from './cittadino';
import CategoriaEsercente from './categoria-esercente';
import Esercente from './esercente';
import Transazione from './transazione';
import Voucher from './voucher';
import {connect} from "react-redux";
import {EntitiesMenuComponent} from "app/shared/layout/menus";
import {AUTHORITIES} from "app/config/constants";
import PrivateRoute from "app/shared/auth/private-route";

export interface IRouteProps extends StateProps {}

const Routes = ({ match }, {account} : IRouteProps) => {
  return(
    <div>
      <Switch>
        {/* prettier-ignore */}
        <ErrorBoundaryRoute path={`${match.url}/cittadino`} component={Cittadino} />
        <PrivateRoute path={`${match.url}/categoria-esercente`} component={CategoriaEsercente} isCategorieEsercenteEnabled />
        <ErrorBoundaryRoute path={`${match.url}/esercente`} component={Esercente} />
        <ErrorBoundaryRoute path={`${match.url}/transazione`} component={Transazione} />
        <ErrorBoundaryRoute path={`${match.url}/richiesta`} component={Richiesta} />
      </Switch>
    </div>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Routes);
