import pick from 'lodash/pick';
import axios from 'axios';
import _debounce from 'lodash.debounce';
import fileDownload from 'js-file-download';

/**
 * Removes fields with an 'id' field that equals ''.
 * This function was created to prevent entities to be sent to
 * the server with relationship fields with empty an empty id and thus
 * resulting in a 500.
 *
 * @param entity Object to clean.
 */
export const cleanEntity = entity => {
  const keysToKeep = Object.keys(entity).filter(
    k =>
      !(entity[k] instanceof Object) ||
      (entity[k]['id'] !== '' && entity[k]['id'] !== -1) ||
      (typeof entity[k] === 'string' && entity[k].trim() !== '')
  );

  return pick(entity, keysToKeep);
};

/**
 * Simply map a list of element to a list a object with the element as id.
 *
 * @param idList Elements to map.
 * @returns The list of objects with mapped ids.
 */
export const mapIdList = (idList: ReadonlyArray<any>) =>
  idList.filter((entityId: any) => entityId !== '').map((entityId: any) => ({ id: entityId }));

/*export const download = (url, name, contentType)  => event => {
  axios.get(url, {
    responseType: 'arraybuffer'
  }).then( response => {
    const blob = new Blob([response.data], { type: contentType, encoding: 'UTF-8' })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click()
  });
}*/

export const download = (url, name, contentType) => event => {
  const urlSplitted = url.split('.');
  const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
  axios
    .get(url, {
      responseType: 'arraybuffer'
    })
    .then(response => {
      fileDownload(response.data, filename, contentType);
    });
};

export const checkUserEmail = originalValue =>
  _debounce((email, ctx, input, cb) => {
    if (email && email.length > 5) {
      if (originalValue && originalValue.length > 0 && originalValue === email) {
        cb(true);
      } else {
        axios.get('api/check-user-email?email=' + email).then(result => {
          if (result.status === 200 && result.data) {
            cb(!result.data.result ? !result.data.result : 'Email già presente. Inserisci un email diversa.');
          }
        });
      }
    } else {
      cb(true);
    }
  }, 300);

export const checkCittadinoCodicefiscale = originalValue =>
  _debounce((codicefiscale, ctx, input, cb) => {
    if (codicefiscale && codicefiscale.length === 16) {
      if (originalValue && originalValue.length === 16 && originalValue === codicefiscale) {
        cb(true);
      } else {
        axios.get('api/check-cittadino-codicefiscale?codicefiscale=' + codicefiscale).then(result => {
          if (result.status === 200 && result.data) {
            cb(!result.data.result ? !result.data.result : 'Codice fiscale già presente.');
          }
        });
      }
    } else {
      cb(true);
    }
  }, 300);

export const checkIfExistCittadinoCodicefiscale = originalValue =>
  _debounce((codicefiscale, ctx, input, cb) => {
    if (codicefiscale && codicefiscale.length === 16) {
      if (originalValue && originalValue.length === 16 && originalValue === codicefiscale) {
        cb(true);
      } else {
        axios.get('api/check-cittadino-codicefiscale?codicefiscale=' + codicefiscale).then(result => {
          if (result.status === 200 && result.data) {
            cb(
              result.data.result
                ? result.data.result
                : 'Questo codice fiscale non risulta ancora registrato. Effettua registrazione completa.'
            );
          }
        });
      }
    } else {
      cb(true);
    }
  }, 300);
