import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IAmministrazioneComunale } from 'app/shared/model/amministrazione-comunale.model';
import { getEntities as getAmministrazioneComunales } from 'app/entities/amministrazione-comunale/amministrazione-comunale.reducer';
import { getEntity, updateEntity, createEntity, reset } from 'app/entities/esercente/esercente.reducer';
import { IEsercente } from 'app/shared/model/esercente.model';
import { convertDateTimeFromServer, convertDateTimeToServer } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IEsercenteUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export interface IEsercenteUpdateState {
  isNew: boolean;
  comuneRiferimentoId: string;
}

export class EsercenteUpdate extends React.Component<IEsercenteUpdateProps, IEsercenteUpdateState> {
  constructor(props) {
    super(props);
    this.state = {
      comuneRiferimentoId: '0',
      isNew: !this.props.match.params || !this.props.match.params.id
    };
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextProps.updateSuccess !== this.props.updateSuccess && nextProps.updateSuccess) {
      this.handleClose();
    }
  }

  componentDidMount() {
    if (this.state.isNew) {
      this.props.reset();
    } else {
      this.props.getEntity(this.props.match.params.id);
    }

    this.props.getAmministrazioneComunales();
  }

  saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const { esercenteEntity } = this.props;
      const entity = {
        ...esercenteEntity,
        ...values
      };

      if (this.state.isNew) {
        this.props.createEntity(entity);
      } else {
        this.props.updateEntity(entity);
      }
    }
  };

  handleClose = () => {
    this.props.history.push('/esercente');
  };

  render() {
    const { esercenteEntity, amministrazioneComunales, loading, updating } = this.props;
    const { isNew } = this.state;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="8">
            <h2 id="buoniscontoApp.esercente.home.createOrEditLabel">{isNew ? 'Registra nuovo esercente' : 'Modifica esercente'}</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={isNew ? {} : esercenteEntity} onSubmit={this.saveEntity}>
                {!isNew ? (
                  <AvGroup>
                    <Label for="esercente-id">ID</Label>
                    <AvInput id="esercente-id" type="text" className="form-control" name="id" required readOnly />
                  </AvGroup>
                ) : null}
                <AvGroup>
                  <Label id="partitaIvaLabel" for="esercente-partitaIva">
                    Partita Iva
                  </Label>
                  <AvField
                    id="esercente-partitaIva"
                    type="text"
                    name="partitaIva"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="ragioneSocialeLabel" for="esercente-ragioneSociale">
                    Ragione Sociale
                  </Label>
                  <AvField
                    id="esercente-ragioneSociale"
                    type="text"
                    name="ragioneSociale"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="codiceAtecoPrincipaleLabel" for="esercente-codiceAtecoPrincipale">
                    Codice Ateco Principale
                  </Label>
                  <AvField id="esercente-codiceAtecoPrincipale" type="text" name="codiceAtecoPrincipale" />
                </AvGroup>
                <AvGroup>
                  <Label id="referenteLabel" for="esercente-referente">
                    Referente
                  </Label>
                  <AvField
                    id="esercente-referente"
                    type="text"
                    name="referente"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="emailLabel" for="esercente-email">
                    Email
                  </Label>
                  <AvField id="esercente-email" type="text" name="email" />
                </AvGroup>
                <AvGroup>
                  <Label id="telefonoLabel" for="esercente-telefono">
                    Telefono
                  </Label>
                  <AvField
                    id="esercente-telefono"
                    type="text"
                    name="telefono"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="cellulareLabel" for="esercente-cellulare">
                    Cellulare
                  </Label>
                  <AvField id="esercente-cellulare" type="text" name="cellulare" />
                </AvGroup>
                <AvGroup>
                  <Label id="indirizzoLabel" for="esercente-indirizzo">
                    Indirizzo
                  </Label>
                  <AvField id="esercente-indirizzo" type="text" name="indirizzo" />
                </AvGroup>
                <AvGroup>
                  <Label id="codicePostaleLabel" for="esercente-codicePostale">
                    Codice Postale
                  </Label>
                  <AvField id="esercente-codicePostale" type="text" name="codicePostale" />
                </AvGroup>
                <AvGroup>
                  <Label id="comuneLabel" for="esercente-comune">
                    Comune
                  </Label>
                  <AvField id="esercente-comune" type="text" name="comune" />
                </AvGroup>
                <AvGroup>
                  <Label id="provinciaLabel" for="esercente-provincia">
                    Provincia
                  </Label>
                  <AvField id="esercente-provincia" type="text" name="provincia" />
                </AvGroup>
                <AvGroup>
                  <Label id="ibanLabel" for="esercente-iban">
                    Iban
                  </Label>
                  <AvField
                    id="esercente-iban"
                    type="text"
                    name="iban"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="bancaLabel" for="esercente-banca">
                    Banca
                  </Label>
                  <AvField
                    id="esercente-banca"
                    type="text"
                    name="banca"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="saldoLabel" for="esercente-saldo">
                    Saldo
                  </Label>
                  <AvField
                    id="esercente-saldo"
                    type="string"
                    className="form-control"
                    name="saldo"
                    validate={{
                      required: { value: true, errorMessage: 'This field is required.' },
                      number: { value: true, errorMessage: 'This field should be a number.' }
                    }}
                  />
                </AvGroup>
                <AvGroup>
                  <Label id="consegnaDomicilioLabel" check>
                    <AvInput id="esercente-consegnaDomicilio" type="checkbox" className="form-control" name="consegnaDomicilio" />
                    Consegna Domicilio
                  </Label>
                </AvGroup>
                <AvGroup>
                  <Label for="esercente-comuneRiferimento">Comune Riferimento</Label>
                  <AvInput id="esercente-comuneRiferimento" type="select" className="form-control" name="comuneRiferimento.id">
                    <option value="" key="0" />
                    {amministrazioneComunales
                      ? amministrazioneComunales.map(otherEntity => (
                          <option value={otherEntity.id} key={otherEntity.id}>
                            {otherEntity.id}
                          </option>
                        ))
                      : null}
                  </AvInput>
                </AvGroup>
                <Button tag={Link} id="cancel-save" to="/esercente" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Back</span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Save
                </Button>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (storeState: IRootState) => ({
  amministrazioneComunales: storeState.amministrazioneComunale.entities,
  esercenteEntity: storeState.esercente.entity,
  loading: storeState.esercente.loading,
  updating: storeState.esercente.updating,
  updateSuccess: storeState.esercente.updateSuccess
});

const mapDispatchToProps = {
  getAmministrazioneComunales,
  getEntity,
  updateEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EsercenteUpdate);
