import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button,
  Row,
  Col,
  Label,
  Card,
  CardHeader,
  CardBody,
  Collapse
} from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { setFileData, openFile, byteSize } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, setBlob, reset, setEntityProperty } from './amministrazione-comunale.reducer';
import { download } from 'app/entities/document.reducer';
import {StatoRochiedente} from "app/shared/model/enumerations/stato-richiedente";
import fileDownload from "js-file-download";

export interface IAmministrazioneComunaleDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export class AmministrazioneComunaleDetail extends React.Component<IAmministrazioneComunaleDetailProps> {
  componentDidMount() {
    this.props.getEntity(this.props.match.params.id);
  }

  onDownloadClick(url, name, contentType) {
    const urlSplitted = url.split('.');
    const filename = name + '.' + urlSplitted[urlSplitted.length - 1];
    this.props.download(url).then(respone => {
      fileDownload(respone.value.data, filename, contentType);
    });
  }

  render() {
    const { amministrazioneComunaleEntity, loading } = this.props;

    return (
      <div>
        <Row className="justify-content-center">
          <Col md="12">
            <h2>{'Amministrazione comunale'}</h2>
          </Col>
        </Row>
        <hr />
        <Row className="justify-content-center">
          <Col md="12">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <AvForm model={amministrazioneComunaleEntity}>
                <Row>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Informazioni generali
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="codiceMeccanograficoLabel" for="amministrazione-comunale-codiceMeccanografico">
                              Codice Meccanografico
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-codiceMeccanografico"
                                type="text"
                                name="codiceMeccanografico"
                                readOnly
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="denominazioneLabel" for="amministrazione-comunale-denominazione">
                              Denominazione
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-denominazione" type="text" name="denominazione" readOnly/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="indirizzoLabel" for="amministrazione-comunale-indirizzo">
                              Indirizzo
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-indirizzo" type="text" name="indirizzo" readOnly/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="codicePostaleLabel" for="amministrazione-comunale-codicePostale">
                              Codice Postale
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-codicePostale" type="text" name="codicePostale" readOnly/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="comuneLabel" for="amministrazione-comunale-comune">
                              Comune
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-comune" type="text" name="comune" readOnly/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="provinciaLabel" for="amministrazione-comunale-provincia">
                              Provincia
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-provincia" type="text" name="provincia" readOnly/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="regioneLabel" for="amministrazione-comunale-regione">
                              Regione
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-regione" type="text" name="regione" readOnly/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="popolazioneLabel" for="amministrazione-comunale-popolazione">
                              Popolazione
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvInput
                                id="amministrazione-comunale-popolazione"
                                type="text"
                                className="form-control"
                                name="popolazione"
                                readOnly
                              >
                              </AvInput>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6" className="text-right">
                            <Label id="formRegistrazioneCittadinoTemplateLabel" for="amministrazione-comunale-form-registrazione-cittadino-template">
                              Template per il form pubblico di registrazione del cittadino
                            </Label>
                          </Col>
                          <Col md="6">
                            <AvGroup>
                              <AvInput
                                id="amministrazione-comunale-form-registrazione-cittadino-template"
                                type="text"
                                className="form-control"
                                name="formRegistrazioneCittadinoTemplate"
                                readOnly
                              >
                              </AvInput>
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="logoLabel" for="logo">
                              Logo
                            </Label>
                          </Col>
                          <Col md="9">
                            {amministrazioneComunaleEntity.logoUrl ? (
                              <>
                                <span>Logo.{amministrazioneComunaleEntity.logoUrl.split('.')[amministrazioneComunaleEntity.logoUrl.split('.').length - 1]}</span>
                                <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${amministrazioneComunaleEntity.logoUrl}`, 'logo', amministrazioneComunaleEntity.logoContentType)}>(Download)</a>
                              </>
                            ) : null}
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="templateModuloRichiestaLabel" for="templateModuloRichiesta">
                              Modulo richiesta
                            </Label>
                          </Col>
                          <Col md="9">
                            {amministrazioneComunaleEntity.templateModuloRichiestaUrl ? (
                              <>
                                <span>ModuloRichiesta.{amministrazioneComunaleEntity.templateModuloRichiestaUrl.split('.')[amministrazioneComunaleEntity.templateModuloRichiestaUrl.split('.').length - 1]}</span>
                                <a className="ml-1" onClick={this.onDownloadClick.bind(this,`/api/${amministrazioneComunaleEntity.templateModuloRichiestaUrl}`, 'templateModuloRichiesta', amministrazioneComunaleEntity.templateModuloRichiestaContentType)}>(Download)</a>
                              </>
                            ) : null}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card>
                      <CardHeader>
                        Coefficenti
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="7" className="text-right">
                            <Label id="coefficienteAnzianoLabel" for="amministrazione-comunale-coefficienteAnziano">
                              Coefficiente Anziano
                            </Label>
                          </Col>
                          <Col md="5">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteAnziano"
                                type="number"
                                className="form-control"
                                name="coefficienteAnziano"
                                readOnly
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="7" className="text-right">
                            <Label id="coefficienteDisabileLabel" for="amministrazione-comunale-coefficienteDisabile">
                              Coefficiente Disabile
                            </Label>
                          </Col>
                          <Col md="5">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteDisabile"
                                type="number"
                                className="form-control"
                                name="coefficienteDisabile"
                                readOnly
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="7" className="text-right">
                            <Label id="coefficienteMinoreLabel" for="amministrazione-comunale-coefficienteMinore">
                              Coefficiente Minore
                            </Label>
                          </Col>
                          <Col md="5">
                            <AvGroup>
                              <AvField
                                id="amministrazione-comunale-coefficienteMinore"
                                type="number"
                                className="form-control"
                                name="coefficienteMinore"
                                readOnly
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni di contatto per i cittadini
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label id="contattoTelefonicoLabel" for="amministrazione-comunale-contatto-telefonico">
                              Contatto telefonico
                            </Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField id="amministrazione-comunale-contatto-telefonico" type="text" name="contattoTelefonico" readOnly/>
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioEmail">Contatto email</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                name="contattoEmail"
                                type="email"
                                id="contattoEmail"
                                readOnly
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="mt-3">
                      <CardHeader>
                        Informazioni Accesso Utente
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioFirstName">Accesso Utente (Nome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="responsabileServizioFirstName"
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].firstName : ''}
                                readOnly
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioLastName">Accesso Utente (Cognome)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                type="text"
                                className="form-control"
                                name="responsabileServizioLastName"
                                readOnly
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].lastName : ''}
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3" className="text-right">
                            <Label for="responsabileServizioEmail">Accesso Utente (Email)</Label>
                          </Col>
                          <Col md="9">
                            <AvGroup>
                              <AvField
                                name="responsabileServizioEmail"
                                type="email"
                                id="responsabileServizioEmail"
                                value={amministrazioneComunaleEntity.responsabiliServizios ? amministrazioneComunaleEntity.responsabiliServizios[0].email : ''}
                                readOnly
                              />
                            </AvGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <hr/>
                <Row className="text-right">
                  <Col>
                    <Button tag={Link} id="cancel-save" to="/amministrazione-comunale" replace color="info">
                      <FontAwesomeIcon icon="arrow-left"/>
                      &nbsp;
                      <span className="d-none d-md-inline">Elenco amministrazioni comunali</span>
                    </Button>
                    &nbsp;
                    <Button tag={Link} to={`/amministrazione-comunale/${amministrazioneComunaleEntity.id}/edit`} replace color="primary">
                      <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Modifica amministrazione comunale</span>
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ amministrazioneComunale, document }: IRootState) => ({
  amministrazioneComunaleEntity: amministrazioneComunale.entity,
  loading: amministrazioneComunale.loading,
  document: document
});

const mapDispatchToProps = {
  getEntity,
  download
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AmministrazioneComunaleDetail);
