import { IEsercente } from 'app/shared/model/esercente.model';
import { ITransazione } from 'app/shared/model/transazione.model';
import { ICittadino } from 'app/shared/model/cittadino.model';
import { IUser } from 'app/shared/model/user.model';
import { ICategoriaEsercente } from 'app/shared/model/categoria-esercente.model';
import { IVoucher } from 'app/shared/model/voucher.model';
import { Popolazione } from 'app/shared/model/enumerations/popolazione.model';
import { FormRegistrazioneCittadinoTemplate } from 'app/shared/model/enumerations/form-registrazione-cittadino-template.model';

export interface IAmministrazioneComunale {
  id?: number;
  stringId?: string;
  codiceMeccanografico?: string;
  denominazione?: string;
  saldo?: number;
  indirizzo?: string;
  codicePostale?: string;
  comune?: string;
  provincia?: string;
  regione?: string;
  popolazione?: Popolazione;
  contattoTelefonico?: string;
  contattoEmail?: string;
  formRegistrazioneCittadinoTemplate?: FormRegistrazioneCittadinoTemplate;
  templateModuloRichiestaContentType?: string;
  templateModuloRichiesta?: any;
  templateModuloRichiestaUrl?: string;
  templateModuloRichiestaFileName?: string;
  coefficienteAnziano?: number;
  coefficienteDisabile?: number;
  coefficienteMinore?: number;
  logoContentType?: string;
  logo?: any;
  logoUrl?: string;
  logoFileName?: string;
  saldoVoucher?: number;
  categorieAbilitate?: boolean;
  voucherAbilitati?: boolean;
  documentazioneIse?: boolean;
  esercentes?: IEsercente[];
  transaziones?: ITransazione[];
  cittadinos?: ICittadino[];
  responsabiliServizios?: IUser[];
  categorieEsercentis?: ICategoriaEsercente[];
  vouchers?: IVoucher[];
  createdBy?: string;
  createdDate?: Date;
  updatedBy?: string;
  updatedDate?: Date;
}

export const defaultValue: Readonly<IAmministrazioneComunale> = {
  categorieAbilitate: false,
  voucherAbilitati: false
};
