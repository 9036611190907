import React from 'react';
import {RouteProps, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';

import Login from 'app/modules/login/login';
// import Register from 'app/modules/account/register/register';
import Activate from 'app/modules/account/activate/activate';
import PasswordResetInit from 'app/modules/account/password-reset/init/password-reset-init';
import PasswordResetFinish from 'app/modules/account/password-reset/finish/password-reset-finish';
import Logout from 'app/modules/login/logout';
import Home from 'app/modules/home/home';
import HomeAmministrazione from 'app/modules/home/home-amministrazione';
import HomeCittadino from 'app/modules/home/home-cittadino';
import Entities from 'app/entities';
import AmministrazioneEntities from 'app/amministrazione-entities';
import CittadinoEntities from 'app/cittadino-entities';
import Amministrazione from 'app/modules/amministrazione';
import PrivateRoute, {IPrivateRouteProps} from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import {IRootState} from "app/shared/reducers";
import {connect} from "react-redux";
import {EntitiesMenu} from "app/shared/layout/menus";
import EsercentiPage from "app/modules/pages/esercenti-page";
import ComunePage from "app/modules/pages/comune-page";
import CittadinoUpdate from "app/cittadino-entities/cittadino/cittadino-update";

interface IOwnProps extends RouteProps {
  hasAnyAuthorities?: string[];
}

const Account = Loadable({
  loader: () => import(/* webpackChunkName: "account" */ 'app/modules/account'),
  loading: () => <div>loading ...</div>
});

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => <div>loading ...</div>
});

export interface IRouteProps extends StateProps {}

export const Routes = ( {account} : IRouteProps) => {
  const homeRouteSelector = () => {
    if (account && account.authorities) {
      const authorities = account.authorities;
      if (authorities.find(auth => auth === AUTHORITIES.AMMINISTRAZIONE)) {
        return(
          <PrivateRoute path="/" exact component={HomeAmministrazione} />
        );
      } else if (authorities.find(auth => auth === AUTHORITIES.CITTADINO)) {
        return(
          <PrivateRoute path="/" exact component={HomeCittadino} />
        );
      } else {
        return(
          <ErrorBoundaryRoute path="/" exact component={Home} />
        );
      }
    } else {
      return(
        <ErrorBoundaryRoute path="/" exact component={Home} />
      );
    }
  };

  return (
    <div className="view-routes">
      <Switch>
        <ErrorBoundaryRoute path="/login" component={Login} />
        <ErrorBoundaryRoute path="/logout" component={Logout} />
        <ErrorBoundaryRoute path="/account/activate/:key?" component={Activate} />
        <ErrorBoundaryRoute path="/account/reset/request" component={PasswordResetInit} />
        <ErrorBoundaryRoute path="/account/reset/finish/:key?" component={PasswordResetFinish} />
        <PrivateRoute path="/admin" component={Admin} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <PrivateRoute path="/account" component={Account} hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.USER]} />

        <ErrorBoundaryRoute exact path="/comune/:comuneStringId" component={ComunePage} />
        <ErrorBoundaryRoute exact path="/registrazione/cittadino/:comuneStringId" component={CittadinoUpdate} />
        <ErrorBoundaryRoute exact path="/elenco-esercenti/:comuneStringId" component={EsercentiPage} />
        <PrivateRoute path="/amministrazione-mgmt" component={Amministrazione} hasAnyAuthorities={[AUTHORITIES.AMMINISTRAZIONE]} />

        {homeRouteSelector()}

        <PrivateRoute path="/amministrazione" component={AmministrazioneEntities} hasAnyAuthorities={[AUTHORITIES.AMMINISTRAZIONE]} />
        <PrivateRoute path="/cittadino" component={CittadinoEntities} hasAnyAuthorities={[AUTHORITIES.CITTADINO]}/>
        <PrivateRoute path="/" component={Entities} hasAnyAuthorities={[AUTHORITIES.ADMIN]} />
        <ErrorBoundaryRoute component={PageNotFound} />
      </Switch>
    </div>
  );
}

const mapStateToProps = storeState => ({
  account: storeState.authentication.account
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Routes);

